import TextStore from './textStore';
import BasketStore from './basketStore';

/**
 Inject Inital State into Stores
 For now only text store is implemented because it is the only one we need in SSR
 If you need another store implement it like text store
 */
export default (stores) => ({
    textStore: new TextStore(stores),
    basketStore: new BasketStore(stores)
});