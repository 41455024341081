import React, {Component} from 'react';
import PropTypes from 'prop-types';
import history from "../helper/browserHistory";
import EditTextComponent from "../moap-components/EditTextComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import {Link} from "react-router-dom";
import {isAdmin} from "../helper/util";
import {withTranslation} from "react-i18next";
import productsStore from "../stores/productsStore";

@withTranslation()
export default class ProductTypeComponent extends Component {

    state = {
        hasNewProducts: false
    };


    hasNewProducts(categoryId) {
        if (categoryId.length === 0) {
            return false;
        }

        for (let i = 0; i < productsStore.products.length; i++) {
            if (productsStore.products[i].productTypeId === categoryId && productsStore.products[i].new && productsStore.products[i].published) {
                return true
            }
        }

        return false;
    }


    componentDidMount() {
        const {categoryId} = this.props;
        if (productsStore.products.length > 0) {
            if (this.hasNewProducts(categoryId)) {
                this.setState({hasNewProducts: true});
            }
        } else {
            productsStore.fetchProducts().then(() => {
                if (this.hasNewProducts(categoryId)) {
                    this.setState({hasNewProducts: true});
                }
            })
        }
    }


    render() {
        const {imageId, title, link, text, t} = this.props;
        if (!imageId || !title || !link || !text) {
            console.log('Src is not acceptable');
            return null;
        }
        let displayType = isAdmin() ? "admin" : "user";

        return (
            <Grid padded>
                <Grid.Row
                    columns={2}
                    onClick={() => {
                        if (displayType !== "admin") {
                            history.push(link);
                            this.setState({});
                        }
                    }}
                    style={{"paddingTop": "50px", "paddingBottom": "50px"}}>
                    <Grid.Column width={5}>
                        <Image
                            src={imageId}
                            alt={""}
                        />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <h1>
                            <Link to={link} className={"header-link"}>
                                {title}
                            </Link>
                        </h1>
                        <p>
                            <EditTextComponent id={text}/>
                        </p>
                        {this.state.hasNewProducts ?
                            <p className={"new-products"}>
                                <b><i>{t("products.new_long")}</i></b>
                            </p>
                            : null}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

ProductTypeComponent.propTypes = {
    imageId: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    categoryId: PropTypes.string,
};

ProductTypeComponent.defaultProps = {
    imageId: null,
    title: null,
    link: null,
    text: null,
    categoryId: ""
};