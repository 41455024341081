import React from "react";
import {inject, observer} from "mobx-react";
import i18n from "../i18n";
import {withTranslation} from "react-i18next";
import productsStore from "../stores/productsStore";
import _ from "lodash";
import {observable} from "mobx";
import {isInFAQ} from "../helper/util";
import history from "../helper/browserHistory";
import {Link} from "react-router-dom";

import Search from "semantic-ui-react/dist/commonjs/modules/Search";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import NewsletterModal from "./NewsletterModal";

const initialState = {isLoading: false, results: [], value: ''};

@inject("stores")
@withTranslation()
@observer
export default class FooterComponent extends React.Component {

    @observable
    search = initialState;

    handleResultSelect = (e, {result}) => {
        //Show in detail page
        this.search.value = "";
        if (result.id === "faq") {
            history.push("/faq");

        } else {
            history.push("/product-details/" + encodeURIComponent(result.title.replace("/", "||")));
        }
    };

    handleSearchChange = (e, {value}) => {

        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        let options = [];
        for (let i = 0; i < productsStore.products.length; i++) {
            if (productsStore.products[i].published) {
                options.push({
                    title: language === "en-UK" ? productsStore.products[i].nameEn : productsStore.products[i].name,
                    description: productsStore.products[i].itemNumber,
                    id: productsStore.products[i].id,
                    image: productsStore.products[i].link
                })
            }
        }

        this.search.isLoading = true;
        this.search.value = value;

        if (this.search.value.length < 1)
            return this.search = initialState;

        const re = new RegExp(_.escapeRegExp(this.search.value), 'i');
        const isMatchTitle = result => re.test(result.title) || re.test(result.description);

        this.search.isLoading = false;
        this.search.results = _.filter(options, isMatchTitle);
        if (isInFAQ(this.search.value, language, this.props.stores.textStore)) {
            this.search.results.push({
                title: i18n.t('titles.faq'),
                description: i18n.t('titles.faq_long'),
                id: "faq",
                image: "https://www.glw.de/images/Unternehmen_Stellenangebote_passiv.png"
            })
        }
    };


    render() {

        return (
            <Grid padded>
                <Grid.Row width={16} className={"footer-container"}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={12} tablet={16} mobile={16}
                                 className={"footer-top"}>
                        <h1>{i18n.t('search.footer_title')}</h1>
                        <Search
                            loading={this.search.isLoading}
                            onResultSelect={this.handleResultSelect}
                            onSearchChange={this.handleSearchChange}
                            className={"search-bar"}
                            results={this.search.results}
                            value={this.search.value}
                            noResultsMessage={i18n.t('search.no_results')}
                            placeholder={i18n.t('search.title')}
                        />
                    </Grid.Column>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"footer-divider"}>
                        <div/>
                    </Grid.Column>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"footer-top"}>
                        <b>{i18n.t('footer.stay_informed')}</b><br/>
                        <Icon
                            size={"big"}
                            name={"linkedin"}
                            color={"blue"}
                            onClick={
                                () => window.open('https://www.linkedin.com/company/glwgmbh/about/', "_blank")}/>

                        <NewsletterModal/>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={4} mobile={8} className={"footer-top"}>
                        <p><b>{i18n.t('footer.contact_us')}</b></p>
                        <p>
                            <Link to={"/contact"}
                                  className={"footer-link"}>
                                {i18n.t('titles.contact_lower')}
                            </Link>
                        </p>
                        <p>
                            <Link to={"/service_request"}
                                  className={"footer-link"}>
                                {i18n.t('titles.service_request')}
                            </Link>
                        </p>
                        <p>
                            <Link to={"/downloads"}
                                  className={"footer-link"}>
                                {i18n.t('titles.downloads')}
                            </Link>
                        </p>
                        <p>
                        <Link to={"/faq"}
                              className={"footer-link"}>
                            {i18n.t('titles.faq')}
                        </Link>
                        </p>
                        <Link to={"/return_product_info"}
                              className={"footer-link"}>
                            {i18n.t('titles.return_product')}
                        </Link>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={4} mobile={8} className={"footer-top"}>
                        <p>
                            <Link
                                to={"/legal"}
                                className={"footer-link"}>
                                {i18n.t('titles.data_protection')}
                            </Link>
                        </p>
                        <p>
                            <Link to={"/impressum"}
                                  className={"footer-link"}>
                                {i18n.t('titles.impressum')}
                            </Link>
                        </p>
                        <p>
                            <Link to={"/agb"}
                                  className={"footer-link"}>
                                {i18n.t('titles.agb')}
                            </Link>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"footer-divider"}>
                        <div/>
                    </Grid.Column>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={4} tablet={4} mobile={8}>
                        <p>
                            ©{new Date().getFullYear()} GLW GmbH
                        </p>
                    </Grid.Column>
                    <Grid.Column computer={8} only={'computer'}>
                        <p>
                            Steinbeisstr. 2 · 88353 Kisslegg · <a href="tel:+49756391230" className={"normal-text"}>+49
                            7563 9123-0</a> ·
                            info@glw.de
                        </p>
                    </Grid.Column>
                    <Grid.Column width={4} only={"tablet"}/>
                    <Grid.Column tablet={6} only={'tablet'}>
                        <p>
                            Steinbeisstr. 2 · 88353 Kisslegg
                        </p>

                        <p>
                            <a href="tel:+49756391230" className={"normal-text"}>+49 7563 9123-0</a>
                        </p>

                        <p>
                            info@glw.de
                        </p>
                    </Grid.Column>
                    <Grid.Column mobile={8} only={'mobile'}>
                        <p>
                            Steinbeisstr. 2
                        </p>
                        <p>
                            88353 Kisslegg
                        </p>
                        <p>
                            <a href="tel:+49756391230" className={"normal-text"}>+49 7563 9123-0</a>
                        </p>
                        <p>
                            info@glw.de
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}