import React from "react";
import {inject, observer} from "mobx-react";
import ProductTypeComponent from "../components/ProductTypeComponent";
import {withTranslation} from "react-i18next";
import {isAdmin, updatePageView} from "../helper/util";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import ImageSlider from "../moap-components/ImageSlider";

@inject("stores")
@withTranslation()
@observer
export default class FrontPage extends React.Component {

    componentDidMount() {
        updatePageView(this.props.location.pathname);
        window.scrollTo(0, 0);
    }

    render() {
        const {i18n} = this.props;


        return (
            <Grid>
                <EditHeaderComponent id={"front-page"}/>
                <Grid.Row>
                    <Grid.Column width={16} style={isAdmin() ? {height: '320px' , marginBottom: '4rem'} : {height:'320px'}}>
                        <ImageSlider id={"front-page-image-slider-1"}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Aderendhuelse.png"}
                            title={i18n.t('products.wire_end_sleeves')}
                            link={"/products/a7bf7a85-b595-44a9-a492-4d48d1c395dc/0"}
                            text={"front-wire-end-sleeves-text"}
                            categoryId={"a7bf7a85-b595-44a9-a492-4d48d1c395dc"}
                        />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Ablaengen.png"}
                            title={i18n.t('products.cutting')}
                            link={"/products/63362656-e5af-4860-a9ec-e1898f82b42b/0"}
                            text={"front-cutting-text"}
                            categoryId={"63362656-e5af-4860-a9ec-e1898f82b42b"}
                        />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Crimpen.png"}
                            title={i18n.t('products.crimping')}
                            link={"/products/0f6866f7-e3a9-4b6b-af8c-bfabb9b088cb/0"}
                            text={"front-crimping-text"}
                            categoryId={"0f6866f7-e3a9-4b6b-af8c-bfabb9b088cb"}
                        />
                    </Grid.Column>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Abisolieren.png"}
                            title={i18n.t('products.wire_stripper')}
                            link={"/products/1bbf1dfb-74df-4927-b3bd-f746535db5f8/0"}
                            text={"front-stripper-text"}
                            categoryId={"1bbf1dfb-74df-4927-b3bd-f746535db5f8"}
                        />
                    </Grid.Column>
                    {/*<Grid.Column computer={4} tablet={8} mobile={16}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Gesenke.png"}
                            title={i18n.t('products.crimping_dies')}
                            link={"/products/d209fd49-0402-4b9e-bcc1-c7622b04b1a5/0"}
                            text={"front-crimping-dies-text"}
                        />
                    </Grid.Column> */}
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Zubehoer.png"}
                            title={i18n.t('products.accessories')}
                            link={"/products/0ea1c24c-c667-4340-8873-c65bf5d0e77d/0"}
                            text={"front-accessories-text"}
                            categoryId={"0ea1c24c-c667-4340-8873-c65bf5d0e77d"}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}