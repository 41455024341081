import React from "react";
import i18n from "../i18n";
import {observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import history from "../helper/browserHistory";
import EditEditorComponent from "../moap-components/EditHTMLComponent";
import {updatePageView} from "../helper/util";
import {observable, toJS} from "mobx";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";

@withTranslation()
@observer
export default class FAQPage extends React.Component {

    @observable
    searchValue = "";

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        updatePageView(this.props.location.pathname);
        this.setFaqSearch();
        history.listen(location => {
            this.setFaqSearch();
        });
    }

    setFaqSearch() {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        let faqSearch = params.get('search');
        if (typeof faqSearch === "string") {
            this.searchValue = decodeURIComponent(faqSearch);
            this.setState({})
        }
    }


    render() {
        return (
            <Grid>
                <EditHeaderComponent id={"faq-page"}/>
                <Grid.Row className={"no-top-padding primary-background-container"} style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider><Icon name={'left chevron'} inverted/></Breadcrumb.Divider>
                            <Breadcrumb.Section>{i18n.t('titles.faq').toUpperCase()}</Breadcrumb.Section>
                        </Responsive>
                        <h1 className={"top-row-text"}>{i18n.t('titles.faq_long').toUpperCase()}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"products-container"}>
                        <Grid padded>
                            <Grid.Row>
                                <Grid.Column style={{"paddingTop": "15px"}}>
                                    <EditEditorComponent
                                        search={toJS(this.searchValue)}
                                        id={"faq-page-text-id"}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}