import config from "../../config/main.config"
import userStore from "../stores/userStore"

export function isDevelop() {
    return process.env.NODE_ENV === 'development' || typeof process.env.NODE_ENV === 'undefined';
}

export function getTokenFromLocalStorage() {
    if (typeof window !== "undefined") {
        let object = JSON.parse(localStorage.getItem(config.STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            return object.token;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }
}


export function getUserFromLocalStorage() {
    if (typeof window !== "undefined") {
        let object = JSON.parse(localStorage.getItem(config.USER_STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            return object.user;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }
}


export function getBasketFromLocalStorage() {
    let object = JSON.parse(localStorage.getItem(config.BASKET_STORAGE_KEY));

    if (object === null) {
        return [];
    }

    return object;
}


export function removeTokenFromStorage() {
    if (typeof window !== "undefined") {
        localStorage.removeItem(config.STORAGE_KEY);
        localStorage.removeItem(config.USER_STORAGE_KEY);
    }
}


export function setTokenLocalStorage(token) {
    if (typeof window !== "undefined") {
        let object = {
            "token": token,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.STORAGE_KEY, JSON.stringify(object));
    }
}

export function setUserToLocalStorage(user) {
    if (typeof window !== "undefined") {
        let object = {
            "user": user,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.USER_STORAGE_KEY, JSON.stringify(object));
    }

}

export function setBasketToLocalStorage(basket) {
    if (typeof window !== "undefined") {
        localStorage.setItem(config.BASKET_STORAGE_KEY, JSON.stringify(basket));
    }
}

export function updatePageView(link) {
    //ReactGA.pageview(link);
}

export function isInFAQ(search, language, textStore) {
    let textToCheck = {content: ""};
    if (typeof textStore.texts["faq-page-text-id"] !== "undefined") {
        if (typeof textStore.texts["faq-page-text-id"][language] !== "undefined") {
            textToCheck = textStore.texts["faq-page-text-id"][language];
            return textToCheck.content.toLowerCase().includes(search.toLowerCase())
        } else {
            return false;
        }
    }
    return false;
}


export function showBlobFile(blob, filename) {

    if (typeof window !== "undefined") {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        let newBlob = new Blob([blob], {type: "application/pdf"});

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data;

        link.download = filename + ".pdf";

        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }
}

export function isAdmin() {
    let user = getUserFromLocalStorage();
    if (user !== null && typeof user !== "undefined") {
        return (user.hasOwnProperty("roles") && user.roles.includes("Admin"));
    } else if (userStore.user != null && userStore.user.hasOwnProperty("roles") && userStore.user.roles.includes("Admin")) {
        return true;
    }
    return false;
}

export function isServiceAdmin() {
    let user = getUserFromLocalStorage();
    if (user !== null && typeof user !== "undefined") {
        return (user.hasOwnProperty("roles") && user.roles.includes("ServiceAdmin"));
    } else if (userStore.user != null && userStore.user.hasOwnProperty("roles") && userStore.user.roles.includes("Admin")) {
        return true;
    }
    return false;
}

export function getMachineState(approved, t) {
    switch (approved) {
        case 0:
            return t("my_glw.waiting_for_approval");
        case 1:
            return t("my_glw.approved");
        case 2:
            return t("my_glw.not_accepted");
        default:
            return t("my_glw.waiting_for_approval");
    }
}

export function getServiceState(state, t) {
    switch (state) {
        case 0:
            return t("my_glw.service_state.0");
        case 1:
            return t("my_glw.service_state.1");
        case 2:
            return t("my_glw.service_state.2");
        case 3:
            return t("my_glw.service_state.3");
        case 4:
            return t("my_glw.service_state.4");
        default:
            return t("my_glw.service_state.0");
    }
}

export function getServiceDate(date) {
    let pDate = new Date(date);
    return (pDate.getMonth() + 1) + "." + pDate.getFullYear()
}

export const regexpMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regexpPhone = /^\+?\d*$/;

export const diesValues = [
    {
        title: "Aderendhülsen",
        aliases: ["Aderendhülsen",
            "Aderendhülse"],
        startsWith: "E"
    }, {
        title: "Isolierte Kabelschuhe",
        aliases: [
            "Isolierte Kabelschuhe",
            "isolierte Verbinder",
            "isolierte Winkel-Kabelschuhe",
            "isolierte Winkel-Flachsteckhülsen",
            "isolierte Endverbinder",
        ],
        startsWith: "I"
    },
    {
        title: "Unisolierte Kabelschuhe",
        aliases: [
            "Unisolierte Kabelschuhe",
            "Rollcrimpkontakte",
            "unisolierte Winkel-Flachsteckhülsen"],
        startsWith: "R"
    }, {
        title: "Gedrehte Kontakte",
        aliases: ["Gedrehte Kontakte"],
        startsWith: "D"
    },
    {
        title: "Rohr- und Quetsch Kabelschuhe",
        aliases: [
            "Rohr- und Quetsch Kabelschuhe",
            "Quetschkabelschuhe",
            "Rohrkabelschuhe",
            "Presskabelschuhe"
        ],
        startsWith: "T"
    }
];

export const diesValuesEn = [
    {
        title: "Wire end sleeves (Ferrules)",
        aliases: [
            "Wire end sleeves",
            "Wire end sleeve",
            "Ferrules"
        ],
        startsWith: "E"
    }, {
        title: "Insulated cable lugs",
        aliases: ["Insulated cable lugs"],
        startsWith: "I"
    },
    {
        title: "Uninsulated cable lugs",
        aliases: ["Uninsulated cable lugs"],
        startsWith: "R"
    }, {
        title: "Turned contacts",
        aliases: ["Turned contacts"],
        startsWith: "D"
    },
    {
        title: "Pipe and crimp lugs",
        aliases: [
            "Pipe and crimp lugs",
            "Pipe lugs",
            "crimp lugs"
        ],
        startsWith: "T"
    }
];
