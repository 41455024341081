import {action, observable, toJS} from 'mobx';
import {getBasketFromLocalStorage, setBasketToLocalStorage} from "../helper/util";
import TextStore from "./textStore";

class BasketStore {
    @observable basket = [];

    constructor(stores) {
        if(typeof stores !== "undefined" && typeof stores.basketStore !== "undefined"
            && typeof stores.basketStore.basket !== "undefined") {
          this.basket = stores.basketStore.basket;
        }
    }

    @action getBasketFromStorage() {
        if (this.basket.length === 0) {
            if (getBasketFromLocalStorage().length === 0) {
                return [];
            }

            this.basket = getBasketFromLocalStorage();
        }

        return this.basket;
    }

    @action addItemToBasket(item) {
        if (this.basket.length === 0) {
            this.basket = getBasketFromLocalStorage();
        }
        if (!toJS(this.basket).some(bItem => bItem.itemNumber === item.itemNumber)) {
            this.basket.push(item);
            setBasketToLocalStorage(this.basket);
        }
    }

    @action removeItemToBasket(item) {
        if (this.basket.length === 0) {
            this.basket = getBasketFromLocalStorage();
        }
        if (toJS(this.basket).some(bItem => bItem.itemNumber === item.itemNumber)) {
            let index = this.basket.indexOf(item);
            this.basket.splice(index, 1);
            setBasketToLocalStorage(this.basket);
        }
    }

}


export default BasketStore;