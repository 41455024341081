import {observer} from "mobx-react";
import React from "react";
import {withTranslation} from "react-i18next";
import EditEditorComponent from "../moap-components/EditHTMLComponent";

@withTranslation()
@observer
export default class SponsoringSubPage extends React.Component {

    render() {
        return (
            <EditEditorComponent id={"sponsoring-sub-page-text-id"}/>
        );
    }
}