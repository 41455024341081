import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import React from "react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import PropTypes from "prop-types";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {ButtonBack, ButtonNext, CarouselProvider, Dot, Slide, Slider} from "pure-react-carousel";
import {observable} from "mobx";
import config from "../../config/main.config";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

@withTranslation()
@inject("stores")
@observer
export default class ServiceRequestPage extends React.Component {

    @observable
    steps = [
        {
            title: "my_glw.overlay.step1.title",
            text: "my_glw.overlay.step1.text",
            image: "images/overlay/overlay_1.png"
        },
        {
            title: "my_glw.overlay.step2.title",
            text: "my_glw.overlay.step2.text",
            image: "images/overlay/overlay_2.png"
        },
        {
            title: "my_glw.overlay.step3.title",
            text: "my_glw.overlay.step3.text",
            image: "images/overlay/overlay_3.png"
        },
        {
            title: "my_glw.overlay.step4.title",
            text: "my_glw.overlay.step4.text",
            image: "images/overlay/overlay_4.png"
        },
        {
            title: "my_glw.overlay.step5.title",
            text: "my_glw.overlay.step5.text",
            image: "images/overlay/overlay_5.png"
        }
    ];

    @observable
    currentPosition = 0;

    showSteps(t) {
        return this.steps.map((step, index) =>
            <Slide index={index}>
                <div>
                    <h2>
                        {t(step.title)}
                    </h2>
                    <p>
                        {t(step.text)}
                    </p>
                    <Image
                        alt={"overlay step"}
                        className={"job-image"}
                        src={config.BASE_IMAGE_URL + step.image}
                    />
                </div>
            </Slide>
        )
    }

    showDots() {
        return this.steps.map((step, index) =>
            <Dot index={index} slide={index}
                 className={index === this.currentPosition ? "overlay-dot-active" : "overlay-dot-inactive"}
                 onClick={() => this.currentPosition = index}/>
        );
    }

    setGalleryPosition(type) {
        let allPositions = this.steps.length;

        if (type === "next") {
            if (this.currentPosition !== allPositions) {
                this.currentPosition = this.currentPosition + 1;
            } else {
                this.currentPosition = 0;
            }
        } else {
            if (this.currentPosition > 0) {
                this.currentPosition = this.currentPosition - 1;
            } else {
                this.currentPosition = allPositions;
            }
        }
    }

    render() {
        let {open, onClose, checked, onChange, onClick, t} = this.props;

        return (
            <Modal
                open={open}
                onClose={onClose}
                dimmer={"blurring"}
                style={{"margin": "auto"}}>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <CarouselProvider
                                    naturalSlideWidth={1}
                                    naturalSlideHeight={0.7}
                                    currentSlide={this.currentPosition}
                                    totalSlides={this.steps.length}>
                                    <Slider style={{marginLeft: "20px", marginRight: "20px"}}>
                                        {this.showSteps(t)}
                                    </Slider>
                                    <ButtonBack
                                        onClick={() => this.setGalleryPosition("back")}
                                        className={"overlay-back-button"}>
                                        <Icon name={'angle left'} size={"large"}/>
                                    </ButtonBack>
                                    <ButtonNext
                                        onClick={() => this.setGalleryPosition("next")}
                                        className={"overlay-next-button"}>
                                        <Icon name={'angle right'} size={"large"}/>
                                    </ButtonNext>
                                    <div style={{"width": "100%", "textAlign": "center", "marginTop": "5px"}}>
                                        {this.showDots()}
                                    </div>
                                </CarouselProvider>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form>
                                    <Form.Field>
                                        <Checkbox
                                            style={{"marginTop": "10px", "marginRight": "5px"}}
                                            onChange={onChange}
                                            checked={checked}
                                        />
                                        {t('my_glw.not_show_again')}
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green'
                            onClick={onClick}>
                        <Icon name='checkmark'/> ok
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

ServiceRequestPage.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
};

ServiceRequestPage.defaultProps = {
    open: false,
    onClose: () => {
    },
    onChange: () => {
    },
    checked: false,
    onClick: () => {
    },
};
