import React from "react";
import {observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import {getTokenFromLocalStorage, isAdmin, updatePageView} from "../helper/util";
import {observable} from "mobx";
import config from "../../config/main.config";
import history from "../helper/browserHistory";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";
import CardDownloadsComponent from "../components/CardDownloadsComponent";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";

const DEFAULT_CATEGORY = {
    name: "",
    nameEn: "",
    id: -1,
    allocation: 0
};

const DEFAULT_SUB_CATEGORY = {
    name: "",
    nameEn: "",
    id: -1,
    categoryId: 0
};

const DEFAULT_DOWNLOAD = {
    id: "",
    title: "",
    titleEn: "",
    subTitle: "",
    subTitleEn: "",
    link: "",
    categoryId: 0,
    subCategoryId: 0
};

@withTranslation()
@observer
export default class DownloadsPage extends React.Component {

    @observable
    downloads = [];

    @observable
    addEditCategory = DEFAULT_CATEGORY;
    @observable
    categoryModalOpen = false;

    @observable
    addEditSubCategory = DEFAULT_CATEGORY;
    @observable
    subCategoryModalOpen = false;

    @observable
    addEditDownload = DEFAULT_DOWNLOAD;
    @observable
    downloadModalOpen = false;

    @observable
    activeIndex = -1;

    @observable
    activeSubIndex = -1;

    componentDidMount() {
        updatePageView(this.props.location.pathname);

        this.getDownloads();
        window.scrollTo(0, 0);
    }

    getDownloads() {
        fetch(config.BASE_URL + 'products/download/download-page', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(json => {

                if (json.hasOwnProperty("categories")) {
                    this.downloads = json.categories;
                }
            })
            .catch(function (error) {
            });
    }

    addEditDownloadCategory(downloadCategory) {
        let method = "POST";
        if (downloadCategory.id !== -1) {
            method = "PUT";
        }

        return fetch(config.BASE_URL + 'products/download-category', {
            method: method,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(downloadCategory)
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    this.getDownloads();
                    this.categoryModalOpen = false;
                } else {
                    this.errorMessage = "Network error";
                    this.errorModalOpen = true;
                }
            })
            .catch(
                error => {
                    this.errorMessage = error;
                    this.errorModalOpen = true;
                }
            );
    }

    addEditDownloadSubCategory(downloadSubCategory) {
        let method = "POST";
        if (downloadSubCategory.id !== -1) {
            method = "PUT";
        }

        return fetch(config.BASE_URL + 'products/download-subcategory', {
            method: method,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(downloadSubCategory)
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    this.getDownloads();
                    this.subCategoryModalOpen = false;
                } else {
                    this.errorMessage = "Network error";
                    this.errorModalOpen = true;
                }
            })
            .catch(
                error => {
                    this.errorMessage = error;
                    this.errorModalOpen = true;
                }
            );
    }

    deleteDownloadCategory(id) {
        fetch(config.BASE_URL + 'products/download-category', {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: id})
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    this.getDownloads();
                } else {
                    this.errorMessage = "Network error";
                    this.errorModalOpen = true;
                }
            })
            .catch(
                error => {
                    this.errorMessage = error;
                    this.errorModalOpen = true;
                }
            );
    }

    deleteDownloadSubCategory(id) {
        fetch(config.BASE_URL + 'products/download-subcategory', {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: id})
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    this.getDownloads();
                } else {
                    this.errorMessage = "Network error";
                    this.errorModalOpen = true;
                }
            })
            .catch(
                error => {
                    this.errorMessage = error;
                    this.errorModalOpen = true;
                }
            );
    }

    uploadLink(event) {
        let self = this;
        const data = new FormData();
        data.append('pdf', event.target.files[0]);
        fetch(config.BASE_URL + 'products/pdf', {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    console.log("response json: " + json.toString());
                    self.addEditDownload.link = json.link;
                });
            }
        }).catch(() => {
        });
    }

    deleteDownload(download) {
        if (download.id !== "") {
            let self = this;
            fetch(config.BASE_URL + 'products/download', {
                method: "DELETE",
                body: JSON.stringify({id: download.id}),
                headers: {
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    self.getDownloads();
                    self.downloadModalOpen = false;
                }
            }).catch(() => {
            });
        }
    }

    addDownload(downloadToSend) {
        let self = this;
        let method = "POST";
        if (downloadToSend.id !== "")
            method = "PUT";

        downloadToSend.ArticleId = "download-page";
        fetch(config.BASE_URL + 'products/download', {
            method: method,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({download: downloadToSend})
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.getDownloads();
                    self.downloadModalOpen = false;
                });
            }
        }).catch(() => {
        });
    }

    addDownloadCategory() {
        this.addEditCategory = DEFAULT_CATEGORY;
        this.categoryModalOpen = true;
    }

    addDownloadSubCategory(catId) {
        this.addEditSubCategory = DEFAULT_SUB_CATEGORY;
        this.addEditSubCategory.categoryId = catId;
        this.subCategoryModalOpen = true;
    }

    openAddDownloadModal(categoryId, subCategoryId = 0) {
        this.addEditDownload = DEFAULT_DOWNLOAD;
        this.addEditDownload.categoryId = categoryId;
        this.addEditDownload.subCategoryId = subCategoryId;
        this.downloadModalOpen = true;
    }

    editDownload(download) {
        this.addEditDownload = download;
        this.downloadModalOpen = true;
    }

    editDownloadCategory(category) {
        this.addEditCategory = category;
        this.categoryModalOpen = true;
    }

    editDownloadSubCategory(category) {
        this.addEditSubCategory = category;
        this.subCategoryModalOpen = true;
    }

    getSubCategoryAccordion(downloadCategories, admin, language) {
        let accordion = [];

        for (let i = 0; i < downloadCategories.length; i++) {
            accordion.push(
                <Accordion.Title
                    active={this.activeSubIndex === i}
                    index={i}
                    style={{border: "none"}}
                    key={"SubAcc" + i}
                    onClick={() => this.activeSubIndex === i ? this.activeSubIndex = -1 : this.activeSubIndex = i}>
                    <h2>
                        <Icon name='dropdown'/>
                        {language === "de-DE" ? downloadCategories[i].name : downloadCategories[i].nameEn}
                    </h2>
                    {admin ?
                        <Button.Group>
                            <Button positive
                                    onClick={() => this.editDownloadSubCategory(downloadCategories[i])}>
                                Bearbeiten
                            </Button>
                            <Button negative
                                    onClick={() => this.deleteDownloadSubCategory(downloadCategories[i].id)}>
                                Löschen
                            </Button>
                        </Button.Group>
                        : null}
                </Accordion.Title>
            );

            let categoryDownloads = [];

            for (let j = 0; j < downloadCategories[i].downloads.length; j++) {
                if (admin) {
                    categoryDownloads.push(
                        <Grid.Row
                            key={"SubAccD" + i + "" + j}>
                            <Grid.Column>
                                <Segment>
                                    <h3>{downloadCategories[i].downloads[j].title}</h3>
                                    <p>{downloadCategories[i].downloads[j].link}</p>
                                    <Button.Group>
                                        <Button positive
                                                onClick={() => this.editDownload(downloadCategories[i].downloads[j])}>
                                            Bearbeiten
                                        </Button>
                                        <Button negative
                                                onClick={() => this.deleteDownload(downloadCategories[i].downloads[j])}>
                                            Löschen
                                        </Button>
                                    </Button.Group>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    )
                } else {
                    categoryDownloads.push(
                        <CardDownloadsComponent
                            key={"SubAccD" + i + "" + j}
                            title={language === "de-DE" ? downloadCategories[i].downloads[j].title : downloadCategories[i].downloads[j].titleEn}
                            subTitle={language === "de-DE" ? downloadCategories[i].downloads[j].subTitle : downloadCategories[i].downloads[j].subTitleEn}
                            link={downloadCategories[i].downloads[j].link}
                        />
                    )
                }
            }

            accordion.push(
                <Accordion.Content active={this.activeSubIndex === i} key={"AccCon" + i}>
                    <Grid>
                        {categoryDownloads}
                        {admin ?
                            <Grid.Row>
                                <Grid.Column style={{"paddingTop": "15px"}}>
                                    <Button color='blue' icon='add' floated={"left"}
                                            onClick={() => this.openAddDownloadModal(downloadCategories[i].categoryId, downloadCategories[i].id)}>
                                        Download Hinzufügen
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                            :
                            null
                        }
                    </Grid>
                </Accordion.Content>
            )
        }
        return accordion;
    }

    getDownloadAccordion(downloadCategories, admin, language) {
        let accordion = [];

        for (let i = 0; i < downloadCategories.length; i++) {
            if (downloadCategories[i].allocation === 0 || admin) {
                accordion.push(
                    <Accordion.Title
                        active={this.activeIndex === i}
                        index={i}
                        key={"Acc" + i}
                        onClick={() => this.activeIndex === i ? this.activeIndex = -1 : this.activeIndex = i}>
                        <h2>
                            <Icon name='dropdown'/>
                            {language === "de-DE" ? downloadCategories[i].name : downloadCategories[i].nameEn}
                        </h2>
                        {admin ?
                            <Button.Group>
                                <Button positive
                                        onClick={() => this.editDownloadCategory(downloadCategories[i])}>
                                    Bearbeiten
                                </Button>
                                <Button negative
                                        onClick={() => this.deleteDownloadCategory(downloadCategories[i].id)}>
                                    Löschen
                                </Button>
                            </Button.Group>
                            : null}
                    </Accordion.Title>
                );

                let categoryDownloads = [];
                if (downloadCategories[i].subCategories.length > 0) {
                    categoryDownloads.push(<Grid.Row
                        key={"SubAccD" + i}>
                        <Grid.Column>
                            <Segment>
                                {this.getSubCategoryAccordion(downloadCategories[i].subCategories, admin, language)}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>);
                }

                for (let j = 0; j < downloadCategories[i].downloads.length; j++) {
                    if (admin) {
                        categoryDownloads.push(
                            <Grid.Row
                                key={"AccD" + i + "" + j}>
                                <Grid.Column>
                                    <Segment>
                                        <h3>{downloadCategories[i].downloads[j].title}</h3>
                                        <p>{downloadCategories[i].downloads[j].link}</p>
                                        <Button.Group>
                                            <Button positive
                                                    onClick={() => this.editDownload(downloadCategories[i].downloads[j])}>
                                                Bearbeiten
                                            </Button>
                                            <Button negative
                                                    onClick={() => this.deleteDownload(downloadCategories[i].downloads[j])}>
                                                Löschen
                                            </Button>
                                        </Button.Group>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    } else {

                        categoryDownloads.push(
                            <CardDownloadsComponent
                                key={"AccD" + i + "" + j}
                                title={language === "de-DE" ? downloadCategories[i].downloads[j].title : downloadCategories[i].downloads[j].titleEn}
                                subTitle={language === "de-DE" ? downloadCategories[i].downloads[j].subTitle : downloadCategories[i].downloads[j].subTitleEn}
                                link={downloadCategories[i].downloads[j].link}
                            />
                        )
                    }
                }

                accordion.push(
                    <Accordion.Content active={this.activeIndex === i} key={"AccCon" + i}>
                        <Grid>
                            {categoryDownloads}
                            {admin ?
                                <Grid.Row>
                                    <Grid.Column style={{"paddingTop": "15px"}}>
                                        <Button color='blue' floated={"left"}
                                                onClick={() => this.openAddDownloadModal(downloadCategories[i].id)}>
                                            Download Hinzufügen
                                        </Button>
                                        <Button color='blue' floated={"left"}
                                                onClick={() => this.addDownloadSubCategory(downloadCategories[i].id)}>
                                            Unterkategorie Hinzufügen
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                :
                                null
                            }
                        </Grid>
                    </Accordion.Content>
                )
            }
        }

        return accordion;
    }

    render() {
        let language = "de-DE";
        let {i18n} = this.props;

        if (i18n) {
            language = this.props.i18n.language;
        }

        //if admin show edit stuff
        let displayType = isAdmin() ? "admin" : "user";

        let showDownloads = this.getDownloadAccordion(this.downloads, displayType === "admin", language);


        return (
            <Grid>
                <EditHeaderComponent id={"downloads-page"}/>
                <Grid.Row className={"no-top-padding primary-background-container"} style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider
                                onClick={() => history.push("/")}>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>{i18n.t('titles.downloads').toUpperCase()}</Breadcrumb.Section>
                        </Responsive>
                        <h1 className={"top-row-text"}>{i18n.t('titles.downloads').toUpperCase()}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"products-container"}>
                        <Grid>
                            {displayType === "admin" ?
                                <Grid.Row>
                                    <Grid.Column style={{"paddingTop": "15px"}}>
                                        <Button circular color='blue' icon='add' floated={"left"}
                                                onClick={() => this.addDownloadCategory()}/>
                                    </Grid.Column>
                                </Grid.Row>
                                :
                                null}
                            <Grid.Row style={{"paddingBottom": "30px"}}>
                                <Grid.Column style={{"paddingTop": "15px"}}>
                                    <Accordion styled fluid>
                                        {showDownloads}
                                    </Accordion>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Modal open={this.categoryModalOpen} onClose={() => this.categoryModalOpen = false}
                               style={{"margin": "auto"}}>
                            <Modal.Header>{this.addEditCategory.id === -1 ? "Kategorie hinzufügen" : "Kategorie bearbeiten"}</Modal.Header>
                            <Modal.Content scrolling>
                                <Form>
                                    <Form.Input fluid label='Name' placeholder='Name'
                                                type="text"
                                                value={this.addEditCategory.name}
                                                onChange={(e) => this.addEditCategory.name = e.target.value}/>
                                    <Form.Input fluid label='Name (EN)' placeholder='Name (EN)'
                                                type="text"
                                                value={this.addEditCategory.nameEn}
                                                onChange={(e) => this.addEditCategory.nameEn = e.target.value}/>
                                    <Form.Checkbox
                                        label='Händer Kategorie'
                                        checked={this.addEditCategory.allocation === 1}
                                        onChange={() => this.addEditCategory.allocation = this.addEditCategory.allocation === 1 ? 0 : 1}/>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right'
                                        content={this.addEditCategory.id === -1 ? "hinzufügen" : "bearbeiten"}
                                        onClick={() => this.addEditDownloadCategory(this.addEditCategory)}/>
                            </Modal.Actions>
                        </Modal>
                        <Modal open={this.subCategoryModalOpen} onClose={() => this.subCategoryModalOpen = false}
                               style={{"margin": "auto"}}>
                            <Modal.Header>{this.addEditSubCategory.id === -1 ? "Unterkategorie hinzufügen" : "Unterkategorie bearbeiten"}</Modal.Header>
                            <Modal.Content scrolling>
                                <Form>
                                    <Form.Input fluid label='Name' placeholder='Name'
                                                type="text"
                                                value={this.addEditSubCategory.name}
                                                onChange={(e) => this.addEditSubCategory.name = e.target.value}/>
                                    <Form.Input fluid label='Name (EN)' placeholder='Name (EN)'
                                                type="text"
                                                value={this.addEditSubCategory.nameEn}
                                                onChange={(e) => this.addEditSubCategory.nameEn = e.target.value}/>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right'
                                        content={this.addEditSubCategory.id === -1 ? "hinzufügen" : "bearbeiten"}
                                        onClick={() => this.addEditDownloadSubCategory(this.addEditSubCategory)}/>
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.downloadModalOpen} onClose={() => this.downloadModalOpen = false}
                               style={{"margin": "auto"}}>
                            <Modal.Header>{this.addEditDownload.id === "" ? "Download hinzufügen" : "Download bearbeiten"}</Modal.Header>
                            <Modal.Content scrolling>
                                <Form>
                                    <Form.Field width={16}>
                                        <label>Title</label>
                                        <input
                                            value={this.addEditDownload.title}
                                            onChange={(e) => this.addEditDownload.title = e.target.value}/>
                                    </Form.Field>
                                    <Form.Field width={16}>
                                        <label>Title (En)</label>
                                        <input
                                            value={this.addEditDownload.titleEn}
                                            onChange={(e) => this.addEditDownload.titleEn = e.target.value}/>
                                    </Form.Field>
                                    <Form.Field width={16}>
                                        <label>Untertitel</label>
                                        <input
                                            value={this.addEditDownload.subTitle}
                                            onChange={(e) => this.addEditDownload.subTitle = e.target.value}/>
                                    </Form.Field>
                                    <Form.Field width={16}>
                                        <label>Untertitel (En)</label>
                                        <input
                                            value={this.addEditDownload.subTitleEn}
                                            onChange={(e) => this.addEditDownload.subTitleEn = e.target.value}/>
                                    </Form.Field>
                                    <Form.Group>
                                        <Form.Field width={16}>
                                            <label>Link</label>
                                            <input className={
                                                "input-flied thumbnail-form"}
                                                   value={this.addEditDownload.link}
                                                   type="text"
                                                   id="inputHeader"
                                                   placeholder="URL"
                                                   onChange={(e) => this.addEditDownload.link = e.target.value}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label className={"transparent-text"}>. </label>
                                            <div className="no-padding thumbnail-form">
                                                <input type="file" id="pdffile" className="inputfile thumbnail-form"
                                                       onChange={this.uploadLink.bind(this)}
                                                       accept=".pdf, .PDF, .zip, .ZIP"/>
                                                <b>
                                                    <label htmlFor="pdffile"
                                                           className="call-to-action big-call-to-action thumbnail-form login-container">
                                                        HOCHLADEN
                                                    </label>
                                                </b>
                                            </div>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button positive icon='checkmark' labelPosition='right'
                                        content={this.addEditDownload.id === "" ? "hinzufügen" : "bearbeiten"}
                                        onClick={() => this.addDownload(this.addEditDownload)}/>
                            </Modal.Actions>
                        </Modal>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}