import React, {useEffect, useState} from "react"
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import {getTokenFromLocalStorage, isAdmin} from "../helper/util";
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from "pure-react-carousel";
import {Button, ButtonGroup, Form, Icon, Modal, ModalActions} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import config from "../../config/main.config";
import sharedConfig from "../../../shared/sharedConfig";
import {inject, observer} from "mobx-react";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";
import draftToHtml from "draftjs-to-html";
import history from "../helper/browserHistory";
import {stateFromHTML} from "draft-js-import-html";
import renderHTML from "react-render-html";


let ContentState = null;
let convertToRaw = null;
let EditorState = null;
let Editor = null;

if (typeof document !== "undefined") {
    // Require scss files
    require('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
    ContentState = require('draft-js').ContentState;
    convertToRaw = require('draft-js').convertToRaw;
    EditorState = require('draft-js').EditorState;

    Editor = require('react-draft-wysiwyg').Editor;
}

async function getAllMedia(type) {
    try {
        return (await fetch(config.BASE_URL + 'misc/' + type, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
        })).json();
    } catch (e) {
        console.error(e)
        return []
    }
}

async function deleteMedia(type, name) {
    try {
        return (await fetch(config.BASE_URL + 'misc/' + type, {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({name, type})
        })).json();
    } catch (e) {
        console.error(e)
        return []
    }
}

async function getSliderItems(id, language) {
    try {
        return (await fetch(config.BASE_URL + 'misc/sliderItems/' + language + '/' + id, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
        })).json();
    } catch (e) {
        console.error(e)
        return []
    }
}

async function addSliderItem(id, data) {
    const body = {...data, id}
    try {
        return (await fetch(config.BASE_URL + 'misc/sliderItems/', {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(body)
        })).json();
    } catch (e) {
        console.error(e)
        return [];
    }
}

async function editSliderItem(id, frontendId, data) {
    try {
        return (await fetch(config.BASE_URL + 'misc/sliderItems/' + id, {
            method: 'PUT',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({...data, id, frontendId})
        })).json();
    } catch (e) {
        console.error(e)
        return []
    }
}

async function deleteSliderItem(id) {
    try {
        return (await fetch(config.BASE_URL + 'misc/sliderItems/' + id, {
            method: 'DELETE',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        })).json();
    } catch (e) {
        console.error(e)
        return []
    }
}

async function uploadMedia(file, type) {
    try {
        const fileReader = new FileReader()
        return new Promise(resolve => {
            fileReader.onload = async () => {
                let uploadedMedia = await fetch(config.BASE_URL + 'misc/sliderItems/upload', {
                    method: 'POST',
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + getTokenFromLocalStorage()
                    },
                    body: JSON.stringify({data: fileReader.result, name: file.name, type})
                })
                return resolve(uploadedMedia);
            }
            if (file.size <= 50 * 1024 * 1024) {
                fileReader.readAsDataURL(file);
            } else {
                console.error("Filesize too big");
            }
        })

    } catch (e) {
        console.error(e)
    }
}

const ImageSlider = inject("stores")(observer((props) => {
    const {id, i18n} = props;
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [addEditModalOpen, setAddEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isUpload, setIsUpload] = useState(undefined);
    const [sliderItemId, setSliderItemId] = useState("");
    const [activeItem, setActiveItem] = useState(-1);
    const [sliderItems, setSliderItems] = useState([]);
    const [allImages, setAllImages] = useState([]);
    const [allVideos, setAllVideos] = useState([]);
    const [editorState, setEditorState] = useState();
    const [searchString, setSearchString] = useState("");
    const [mediaType, setMediaType] = useState("video");
    const [newSliderItem, setNewSlideritem] = useState({
        title: "",
        content: "",
        description: {type: "", name: ""},
        link: null,
        lang: selectedLanguage
    })

    useEffect(() => {
        setEditorState(EditorState.createEmpty())
        getAllMedia('image').then(all => {
            setAllImages(all);
        })
        getAllMedia('video').then(all => {
            setAllVideos(all);
        })
    }, [])

    useEffect(() => {
        getSliderItems(id, selectedLanguage).then(all => {
            setSliderItems(all);
        })
        setNewSlideritem({...newSliderItem, lang: selectedLanguage});
    }, [selectedLanguage])

    useEffect(() => {
        getSliderItems(id, i18n.language).then(all => {
            setSliderItems(all);
        })
        setNewSlideritem({...newSliderItem, lang: i18n.language});
    }, [i18n.language])


    const deleteModal = () => {
        return (
            <Modal open={deleteModalOpen}>
                <Modal.Header>
                    {i18n.t('my_glw.delete_slider_item')}
                </Modal.Header>
                <Modal.Content>
                    <p>Möchten Sie dieses Slider Item wirklich löschen?</p>
                </Modal.Content>
                <ModalActions>
                    <Button color={'green'} onClick={async () => {
                        await deleteSliderItem(sliderItemId);
                        setSliderItems(await getSliderItems(id, selectedLanguage));
                        setSliderItemId("");
                        setDeleteModalOpen(false);
                    }}>{i18n.t('my_glw.confirm')}</Button>
                    <Button color={'red'} onClick={() => {
                        setDeleteModalOpen(false)
                    }}>{i18n.t('my_glw.abort')}</Button>
                </ModalActions>
            </Modal>
        )
    }

    const addEditModal = () => {
        return (
            <Modal open={addEditModalOpen}>
                <Modal.Header>
                    {i18n.t('my_glw.add_slider_item')}
                </Modal.Header>
                <Modal.Content style={{overflow: "scroll"}}>
                    <Form style={{maxHeight: "80vh"}}>
                        <label htmlFor={"lang"}><b>Sprache</b><br/></label>
                        <div id={"lang"} style={{padding: ".5rem 0rem"}}>
                            {selectedLanguage}
                        </div>
                        <Form.Input label={'Titel'} type={'text'}
                                    value={newSliderItem.title}
                                    onChange={e => {
                                        setNewSlideritem({...newSliderItem, title: e.currentTarget.value})
                                    }}/>
                        <label><b>Text</b></label>
                        <Form.Field style={{border: "1px solid rgba(34, 36, 38, .15)", borderRadius: ".28571429rem"}}>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="default-editor"
                                onEditorStateChange={(state) => {
                                    setEditorState(state);
                                    const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                                    setNewSlideritem({
                                        ...newSliderItem,
                                        content: text
                                    })
                                }}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                }}
                                localization={{
                                    locale: "de"
                                }}
                            />
                        </Form.Field>
                        <Form.Field label={'Link'}/>
                        <Form.Input label={'Name'} type={'text'}
                                    value={newSliderItem.link ? newSliderItem.link.name : ""}
                                    onChange={e => {
                                        setNewSlideritem({
                                            ...newSliderItem,
                                            link: {
                                                ...newSliderItem.link,
                                                name: e.currentTarget.value
                                            }
                                        })
                                    }}/>
                        <Form.Input label={'Link'} type={'text'}
                                    value={newSliderItem.link ? newSliderItem.link.link : ""}
                                    onChange={e => {
                                        setNewSlideritem({
                                            ...newSliderItem,
                                            link: {...newSliderItem.link, link: e.currentTarget.value}
                                        })
                                    }}/>
                        <Form.Field label={'Media'}/>
                        <Form.Group>
                            <Button color={'olive'} onClick={() => {
                                setIsUpload(false)
                            }}>Auswählen</Button>
                            <Button color={'blue'} onClick={() => {
                                setIsUpload(true)
                            }}>Hochladen</Button>
                            <Form.Field label={"Datentyp"} style={{margin: "auto initial"}}/>
                            <Form.Radio style={{margin: "auto initial"}} checked={mediaType === "image"} label={'Bild'}
                                        name={'mediaGroup'}
                                        onChange={() => setMediaType('image')}/>
                            <Form.Radio style={{margin: "auto initial"}} checked={mediaType === "video"} label={'Video'}
                                        name={'mediaGroup'}
                                        onChange={() => setMediaType('video')}/>
                        </Form.Group>
                        <Form.Field>
                            <label>Suche</label>
                            <input placeholder={"Nach Name suchen..."} type="text" value={searchString}
                                   onChange={(e) => setSearchString(e.currentTarget.value)}/>
                        </Form.Field>
                        {isUpload ?
                            <Form.Input disabled={mediaType === ""} label={'Media'} type={'file'}
                                        accept={mediaType + '/*'}
                                        onChange={async (e) => {
                                            const uploadedFileName = e.target.files[0].name;
                                            await uploadMedia(e.target.files[0], mediaType);
                                            const res = await getAllMedia(mediaType);
                                            if (mediaType === "image") {
                                                await setAllImages(res);
                                                await setNewSlideritem({
                                                    ...newSliderItem,
                                                    description: {type: "image", name: uploadedFileName}
                                                })
                                            } else if (mediaType === "video") {
                                                await setAllVideos(res);
                                                await setNewSlideritem({
                                                    ...newSliderItem,
                                                    description: {type: "video", name: uploadedFileName}
                                                })
                                            }
                                            await setIsUpload(false)
                                        }}/> :
                            <Form.Field style={{display: 'grid', gridTemplateColumns: "1fr 1fr 1fr"}}>
                                {mediaType === "image" ? allImages.filter((image) => image.toLowerCase().includes(searchString.toLowerCase())).map((image, index) => {
                                        return (
                                            <div>
                                                <Button color={'red'} icon={'trash'} style={{width: '100%'}}
                                                        onClick={async () => {
                                                            const res = await deleteMedia('image', image);
                                                            setAllImages(res)
                                                        }}/>
                                                <img key={"image_" + index}
                                                     loading={index > 10 ? "lazy" : "eager"}
                                                     src={config.BASE_IMAGE_URL + "blog-images/" + image}
                                                     style={activeItem === index || image === newSliderItem.description.name ? {
                                                         cursor: 'pointer',
                                                         border: 'solid green'
                                                     } : {cursor: 'pointer'}}
                                                     alt={'image ' + index}
                                                     onClick={() => {
                                                         setNewSlideritem({
                                                             ...newSliderItem,
                                                             description: {type: "image", name: image}
                                                         });
                                                         setActiveItem(index);
                                                     }}
                                                />
                                            </div>
                                        )
                                    }) :
                                    allVideos.filter((video) => video.toLowerCase().includes(searchString.toLowerCase())).map((video, index) => {
                                        return (
                                            <div>
                                                <Button color={'red'} icon={'trash'} style={{width: '100%'}}
                                                        onClick={async () => {
                                                            const res = await deleteMedia('video', video);
                                                            setAllVideos(res)
                                                        }}/>
                                                <video key={"video_" + index} width={"100%"}
                                                       controls
                                                       onClick={() => {
                                                           setNewSlideritem({
                                                               ...newSliderItem,
                                                               description: {type: "video", name: video}
                                                           })
                                                           setActiveItem(index)
                                                       }}
                                                       style={activeItem === index || newSliderItem.description.name === video ? {
                                                           border: 'solid green',
                                                           cursor: "Pointer",
                                                           background: "black",
                                                           objectFit: "cover"
                                                       } : {
                                                           cursor: "Pointer",
                                                           background: "black",
                                                           objectFit: "cover"
                                                       }}>
                                                    <source
                                                        src={config.BASE_VIDEO_URL + video}
                                                        type="video/mp4"/>
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        )
                                    })
                                }
                            </Form.Field>
                        }
                    </Form>
                </Modal.Content>
                <ModalActions>
                    <Button color={'green'} onClick={async () => {
                        let res;
                        if (sliderItemId === "") {
                            await addSliderItem(id, newSliderItem);
                            const slides = await getSliderItems(id, selectedLanguage);
                            setSliderItems(slides);
                        } else {
                            res = await editSliderItem(sliderItemId, id, newSliderItem);
                            setSelectedLanguage(i18n.language);
                            setSliderItemId("");
                            setSliderItems(res);
                        }
                        setNewSlideritem({
                            title: "",
                            content: "",
                            description: {type: "", name: ""},
                            link: {link: "", name: "", id: ""},
                            lang: selectedLanguage
                        });
                        setAddEditModalOpen(false);
                    }}>{i18n.t('consent.modal_accept')}</Button>
                    <Button color={'red'} onClick={() => {
                        setAddEditModalOpen(false);
                    }}>{i18n.t('my_glw.abort')}</Button>
                </ModalActions>
            </Modal>
        )
    }


    const slides = () => {
        let items = []
        for (let i = 0; i < sliderItems.length; i++) {
            items.push(
                <Slide index={i} key={i} className={'slider-container'}>
                    {isAdmin() ?
                        <ButtonGroup style={{position: 'absolute', top: 0, zIndex: 999}}>
                            <Button color={'orange'}
                                    onClick={() => {
                                        setSliderItemId(sliderItems[i].id);
                                        setNewSlideritem({
                                            ...newSliderItem,
                                            title: sliderItems[i].title,
                                            content: sliderItems[i].content,
                                            link: {...sliderItems[i].link},
                                            description: {
                                                name: sliderItems[i].description.slice(sliderItems[i].description.indexOf(":") + 1, sliderItems[i].description.length),
                                                type: sliderItems[i].description.slice(0, sliderItems[i].description.indexOf(":"))
                                            }
                                        })
                                        setMediaType(sliderItems[i].description.slice(0, sliderItems[i].description.indexOf(":")))
                                        let contentState = stateFromHTML(sliderItems[i].content);
                                        setEditorState(EditorState.createWithContent(contentState));
                                        setAddEditModalOpen(true);
                                    }}
                                    icon={'edit'}/>
                            <Button color={'red'}
                                    onClick={() => {
                                        setSliderItemId(sliderItems[i].id);
                                        setDeleteModalOpen(true);
                                    }}
                                    icon={'trash'}/>
                        </ButtonGroup>
                        : null}
                    <div className={'slide-wrapper'}>
                        <div className={'glw-background slider-container'} style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundImage: "url(https://www.glw.de/images/GLW_LOGO_FRONT.png)"
                        }}>
                            <div className={'red-background-text'} style={{maxHeight: '320px'}}>
                                <h1>{sliderItems[i].title}</h1>
                                <p>{renderHTML(sliderItems[i].content)}</p>
                                {(sliderItems[i].link && sliderItems[i].link.name) ?
                                    <Button className="glw-button ferrule-col"
                                            style={{boxShadow: "white 1px 1px 5px 1px"}}
                                            onClick={() => {
                                                console.log("slider: ", sliderItems[i])
                                                if (sliderItems[i].link.link.startsWith("https")) {
                                                    if (typeof window !== "undefined") {
                                                        window.open(sliderItems[i].link.link);
                                                    }
                                                } else {
                                                    history.push("/" + sliderItems[i].link.link);
                                                }
                                            }}>{sliderItems[i].link.name || ""}</Button> : null}
                            </div>
                        </div>
                        <Responsive as={Grid.Column} minWidth={788}
                                    style={{"paddingLeft": "0"}}
                                    className={"no-top-padding"} stretched computer={8} tablet={16}>
                            {sliderItems[i].description.slice(0, sliderItems[i].description.lastIndexOf(":")) === "video" ?
                                <video key={"video_" + i} width={"100%"}
                                       controls
                                       autoPlay={true}
                                       loop={true}
                                       onClick={() => {
                                           setNewSlideritem({
                                               ...newSliderItem,
                                               description: {
                                                   type: "video",
                                                   name: sliderItems[i].description.slice(sliderItems[i].description.lastIndexOf(":") + 1, sliderItems[i].description.length)
                                               }
                                           })
                                       }}
                                       style={{
                                           background: "black",
                                           objectFit: "cover",
                                           maxHeight: '320px'
                                       }}>
                                    <source
                                        src={config.BASE_VIDEO_URL + sliderItems[i].description.slice(sliderItems[i].description.lastIndexOf(":") + 1, sliderItems[i].description.length)}
                                        type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                                : <img style={{
                                    width: "100%",
                                    objectFit: "cover",
                                    maxHeight: "320px"
                                }}
                                       src={config.BASE_IMAGE_URL + "blog-images/" + sliderItems[i].description.slice(sliderItems[i].description.lastIndexOf(":") + 1, sliderItems[i].description.length)}
                                       alt={"slider_image_" + i}/>}

                        </Responsive>
                    </div>
                </Slide>
            )
        }
        return items
    }

    return (
        [
            <div>
                {isAdmin() && <div style={{margin: '1rem auto', display: 'flex'}}>
                    <Button
                        style={{position: 'relative', zIndex: 999}}
                        color={'blue'} icon={"plus"} circular
                        onClick={() => {
                            setEditorState(EditorState.createEmpty())
                            setAddEditModalOpen(true);
                        }}/>
                    <div style={{margin: 'auto 0'}}>
                        {sharedConfig.LANGUAGES.map(language => (
                            <Radio key={language.language}
                                   label={language.label}
                                   style={{marginLeft: '1rem'}}
                                   name='langGroup'
                                   value={language.language}
                                   checked={selectedLanguage === language.language}
                                   onChange={(e, {value}) => {
                                       setSelectedLanguage(value);
                                   }}/>))
                        }
                    </div>
                </div>
                }
            </div>,
            <CarouselProvider
                className={'slider-container max-width'}
                naturalSlideWidth={1}
                naturalSlideHeight={1}
                isIntrinsicHeight={true}
                infinite={true}
                isPlaying={!isAdmin()}
                interval={8000}
                totalSlides={sliderItems.length}>

                <Slider className={'slider-container'}>
                    {slides()}
                </Slider>
                <ButtonBack
                    className={"gallery-back-button"}>
                    <Icon name={'angle left'} inverted size={"large"} bordered circular/>
                </ButtonBack>
                <ButtonNext
                    className={"gallery-next-button"}>
                    <Icon name={'angle right'} inverted size={"large"} bordered circular/>
                </ButtonNext>
            </CarouselProvider>,
            <div>
                {addEditModal()}
                {deleteModal(sliderItemId)}
            </div>
        ]

    )
}))


export default withTranslation()(ImageSlider);