import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Button, Modal} from "semantic-ui-react";
import history from "../helper/browserHistory";
import Cookies from "js-cookie";
import {isAdmin} from "../helper/util";

const ContactAction = (props) => {
    const {i18n} = props;
    const [showContactAction, setShowContactAction] = useState(false);

    useEffect(() => {

        setTimeout(() => {
            if (!isAdmin()) {
                if (typeof window !== "undefined" && Cookies.get("CookieConsent").includes("preferences:true") && typeof Cookies.get("contactBanner") === "undefined") {
                    Cookies.set("contactBanner", "false");
                    setShowContactAction(true);
                }
                if (typeof window !== "undefined" && !window.location.pathname.endsWith("contact")) {
                    if ((!Cookies.get("CookieConsent").includes("preferences:true") && Cookies.get("contactBanner") !== "true")) {
                        setShowContactAction(true);
                        Cookies.remove("contactBanner");
                    }
                }
            }
        }, 30000)
    }, [])

    return (
        <Modal open={showContactAction} onClose={() => {
            if (typeof window !== "undefined" && Cookies.get("CookieConsent").includes("preferences:true") && Cookies.get("contactBanner") === "false") {
                Cookies.set("contactBanner", "true");
            }
            setShowContactAction(false);
        }}>
            <Modal.Header>
                Kontakt
            </Modal.Header>
            <Modal.Content style={{textAlign: 'center'}}>
                <p>{i18n.t("products.contact_banner_text")}</p>
                <Button className={'glw-button ferrule-col'} onClick={() => {
                    setShowContactAction(false);
                    if (typeof window !== "undefined" && Cookies.get("CookieConsent").includes("preferences:true") && Cookies.get("contactBanner") === "false") {
                        Cookies.set("contactBanner", "true");
                    }
                    history.push("/contact");
                }}>Kontakt</Button>
            </Modal.Content>
        </Modal>
    )


}

export default withTranslation()(ContactAction);
