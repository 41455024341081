import React from "react";
import i18n from "../i18n";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {withTranslation} from "react-i18next";
import history from "../helper/browserHistory";
import PrincipleSubPage from "../components/PrincipleSubPage"
import ContactPersonSubPage from "../components/ContactPersonSubPage";
import SponsoringSubPage from "../components/SponsoringSubPage";
import {updatePageView} from "../helper/util";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import ValuesSubPage from "../components/ValuesSubPage";

const companyTypeId = "9cd71820-7b59-44c5-9a71-27daa4d60570";
const companyTypeId1 = "76e14037-643c-4521-b61e-58267344bd0c";
const companyType2Id = "9e9e8a8e-93cf-4897-b91d-b41a2c8f45ab";
const companyType3Id = "b9f2b13c-e660-4704-bee8-c04135afe8bd";
const companyType4Id = "7d458f37-7c7d-41c2-a860-e9185b87f4f5";

const companyIds = [
    companyTypeId,
    companyTypeId1,
    companyType2Id,
    companyType3Id,
    companyType4Id
];

@withTranslation()
@observer
export default class CompanyPage extends React.Component {

    @observable
    activeCompanyType = companyTypeId;

    @observable
    crossSectionOptions = [];

    @observable
    chosenCrossSectionValue = "";

    @observable
    lengthOptions = [];

    @observable
    chosenLengthValue = "";

    @observable
    colorOptions = [];

    @observable
    chosenColorValue = "";

    @observable
    sleeveType = -1;

    @observable
    showSubMenu = true;

    getActiveCompanyTypeName() {
        switch (this.activeCompanyType) {
            case companyTypeId:
                return i18n.t('company_page.principle').toUpperCase();
            case companyTypeId1:
                return i18n.t('company_page.values').toUpperCase();
            case companyType2Id:
                return i18n.t('company_page.contact_persons').toUpperCase();
            case companyType4Id:
                return i18n.t('company_page.sponsoring').toUpperCase();
        }
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        updatePageView(this.props.location.pathname);
        this.setCompanyTypeId(id);
        window.scrollTo(0, 0);

        history.listen(location => {
            let id = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
            //let locationArray = location.pathname.split("/");
            this.setCompanyTypeId(id);
            updatePageView(location.pathname);
        });
    }

    setCompanyTypeId(id) {
        if (companyIds.includes(id)) {
            this.activeCompanyType = id;
        } else {
            this.activeCompanyType = companyTypeId;
        }
    }

    switchCompanySubPage(activeCompanyType) {
        switch (activeCompanyType) {
            case companyTypeId:
                return <PrincipleSubPage/>;
            case companyTypeId1:
                return <ValuesSubPage/>
            case companyType2Id:
                return <ContactPersonSubPage/>;
            case companyType4Id:
                return <SponsoringSubPage/>;

        }
    }

    render() {

        return (
            <Grid>
                <Grid.Row className={"no-top-padding primary-background-container"}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider
                                onClick={() => history.push("/")}>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>{i18n.t('titles.company')}</Breadcrumb.Section>
                        </Responsive>
                        <h1 className={"top-row-text"}>{this.getActiveCompanyTypeName()}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Responsive as={Grid.Row} maxWidth={767}
                            className={!this.showSubMenu ? "hide-div" : "no-top-padding sub-menu-container"}
                            style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/company/" + companyTypeId);
                            this.showSubMenu = false;
                        }}
                        textAlign={"center"}
                        className={this.activeCompanyType === companyTypeId ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.activeCompanyType === companyTypeId ?
                                "https://www.glw.de/images/Unternehmen_Gruendsaetze_aktiv.png"
                                :
                                "https://www.glw.de/images/Unternehmen_Gruendsaetze_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('company_page.principle').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/company/" + companyTypeId1);
                            this.showSubMenu = false;
                        }}
                        textAlign={"center"}
                        className={this.activeCompanyType === companyTypeId1 ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.activeCompanyType === companyTypeId1 ?
                                "https://www.glw.de/images/Legal_aktiv.png"
                                :
                                "https://www.glw.de/images/Legal_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('company_page.values').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/company/" + companyType2Id);
                            this.showSubMenu = false;
                        }}
                        textAlign={"center"}
                        className={this.activeCompanyType === companyType2Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.activeCompanyType === companyType2Id ?
                                "https://www.glw.de/images/Unternehmen_Ansprechpartner_aktiv.png"
                                :
                                "https://www.glw.de/images/Unternehmen_Ansprechpartner_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('company_page.contact_persons').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/company/" + companyType4Id);
                            this.showSubMenu = false;
                        }}
                        textAlign={"center"}
                        className={this.activeCompanyType === companyType4Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.activeCompanyType === companyType4Id ?
                                "https://www.glw.de/images/Unternehmen_Sponsoring_aktiv.png"
                                :
                                "https://www.glw.de/images/Unternehmen_Sponsoring_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('company_page.sponsoring').toUpperCase()}
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} minWidth={768}
                            className={"no-top-padding sub-menu-container"}
                            style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => history.push("/company/" + companyTypeId)}
                        textAlign={"center"}
                        className={this.activeCompanyType === companyTypeId ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.activeCompanyType === companyTypeId ?
                                "https://www.glw.de/images/Unternehmen_Gruendsaetze_aktiv.png"
                                :
                                "https://www.glw.de/images/Unternehmen_Gruendsaetze_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('company_page.principle').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => history.push("/company/" + companyTypeId1)}
                        textAlign={"center"}
                        className={this.activeCompanyType === companyTypeId1 ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.activeCompanyType === companyTypeId1 ?
                                "https://www.glw.de/images/Legal_aktiv.png"
                                :
                                "https://www.glw.de/images/Legal_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('company_page.values').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => history.push("/company/" + companyType2Id)}
                        textAlign={"center"}
                        className={this.activeCompanyType === companyType2Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.activeCompanyType === companyType2Id ?
                                "https://www.glw.de/images/Unternehmen_Ansprechpartner_aktiv.png"
                                :
                                "https://www.glw.de/images/Unternehmen_Ansprechpartner_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('company_page.contact_persons').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => history.push("/company/" + companyType4Id)}
                        textAlign={"center"}
                        className={this.activeCompanyType === companyType4Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.activeCompanyType === companyType4Id ?
                                "https://www.glw.de/images/Unternehmen_Sponsoring_aktiv.png"
                                :
                                "https://www.glw.de/images/Unternehmen_Sponsoring_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('company_page.sponsoring').toUpperCase()}
                    </Grid.Column>
                </Responsive>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"products-container"}
                                 style={{
                                     paddingLeft: "20px",
                                     paddingRight: "20px",
                                     paddingBottom: "20px",
                                     paddingTop: "13px"
                                 }}>
                        {this.switchCompanySubPage(this.activeCompanyType)}
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        );
    }
}