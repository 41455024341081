import React from "react";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {observable} from "mobx";
import config from "../../config/main.config";

import {regexpMail, updatePageView} from "../helper/util";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import history from "../helper/browserHistory";

import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import i18n from "../i18n";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";

@withTranslation()
@observer
export default class RegisterPage extends React.Component {

    @observable
    firm = "";
    @observable
    street = "";
    @observable
    contactPerson = "";
    @observable
    zipCode = "";
    @observable
    phone = "";
    @observable
    email = "";
    @observable
    country = "";
    @observable
    firmError = false;
    @observable
    streetError = false;
    @observable
    contactPersonError = false;
    @observable
    zipCodeError = false;
    @observable
    phoneError = false;
    @observable
    emailError = false;
    @observable
    countryError = false;

    @observable
    conditionsChecked = false;
    @observable
    conditionsError = false;
    @observable
    newsletter = false;

    @observable
    modalOpen = false;
    @observable
    modalMessage = "";

    componentDidMount() {
        window.scrollTo(0, 0);
        updatePageView(this.props.location.pathname);
    }

    handleSearchChange(type, event, data) {
        switch (type) {
            case "firm":
                this.firm = data.value;
                break;
            case "street":
                this.street = data.value;
                break;
            case "contactPerson":
                this.contactPerson = data.value;
                break;
            case "zipCode":
                this.zipCode = data.value;
                break;
            case "phone":
                this.phone = data.value;
                break;
            case "email":
                this.email = data.value;
                break;
            case "country":
                this.country = data.value;
                break;
        }
    }

    sendRegistration() {
        let canSend = true;
        //first validate data
        if (this.firm.length > 0) {
            this.firmError = false;
        } else {
            this.firmError = true;
            canSend = false;
        }

        if (!this.conditionsChecked) {
            this.conditionsError = true;
            canSend = false;
        } else {
            this.conditionsError = false;
        }

        if (this.contactPerson.length > 0) {
            this.contactPersonError = false;
        } else {
            this.contactPersonError = true;
            canSend = false;
        }

        if (this.phone.length > 0) {
            this.phoneError = false;
        } else {
            this.phoneError = true;
            canSend = false;
        }

        if (this.email.match(regexpMail)) {
            this.emailError = false;
        } else {
            this.emailError = true;
            canSend = false;
        }

        if (canSend) {
            let language = "de-DE";

            if (this.props.i18n) {
                language = this.props.i18n.language;
            }

            //Send to server
            let data = {
                firm: this.firm,
                street: this.street,
                contactPerson: this.contactPerson,
                zipCode: this.zipCode,
                phone: this.phone,
                email: this.email,
                country: this.country,
                language: language,
                newsletter: this.newsletter ? 1 : 0
            };

            let self = this;

            fetch(config.BASE_URL + 'customers/create', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        if (json.hasOwnProperty("success") && json) {
                            self.modalMessage = i18n.t('register.response_success');
                        } else {
                            self.modalMessage = i18n.t('register.response_error');
                        }
                        self.modalOpen = true;
                    });
                } else {
                    self.modalMessage = i18n.t('register.response_error');
                    self.modalOpen = true;
                }
            }).catch(() => {
                self.modalMessage = i18n.t('register.response_error');
                self.modalOpen = true;
            });
        }

    }


    render() {
        const {i18n, t} = this.props;

        return (
            <Grid>
                <EditHeaderComponent id={"register-page"}/>
                <Modal
                    style={{"margin": "auto"}}
                    open={this.modalOpen}
                    onClose={() => this.modalOpen = false}
                    size='small'>
                    <Modal.Content>
                        <h3>{this.modalMessage}</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={() => history.push("/")}>
                            <Icon name='checkmark'/> OK
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Grid.Row className={"no-top-padding primary-background-container"} style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider
                                onClick={() => history.push("/")}>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>{t('contact_form.register').toUpperCase()}</Breadcrumb.Section>
                        </Responsive>
                        <h1 className={"top-row-text"}>{t('contact_form.register').toUpperCase()}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"products-container"}>
                        <Grid padded>
                            <Grid.Row centered>
                                <Grid.Column style={{"paddingTop": "15px"}} computer={8} tablet={10} mobile={14}>
                                    <Form className={"contact-form"}>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.firm')}
                                                placeholder={i18n.t('contact_form.firm') + "*"}
                                                value={this.firm}
                                                error={this.firmError}
                                                onChange={this.handleSearchChange.bind(this, "firm")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.contact_person')}
                                                placeholder={i18n.t('contact_form.contact_person') + "*"}
                                                value={this.contactPerson}
                                                error={this.contactPersonError}
                                                onChange={this.handleSearchChange.bind(this, "contactPerson")}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.street')}
                                                placeholder={i18n.t('contact_form.street')}
                                                value={this.street}
                                                error={this.streetError}
                                                onChange={this.handleSearchChange.bind(this, "street")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.postal_code')}
                                                placeholder={i18n.t('contact_form.postal_code')}
                                                value={this.zipCode}
                                                error={this.zipCodeError}
                                                onChange={this.handleSearchChange.bind(this, "zipCode")}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.phone')}
                                                placeholder={i18n.t('contact_form.phone') + "*"}
                                                value={this.phone}
                                                error={this.phoneError}
                                                onChange={this.handleSearchChange.bind(this, "phone")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.email')}
                                                placeholder={i18n.t('contact_form.email') + "*"}
                                                value={this.email}
                                                error={this.emailError}
                                                onChange={this.handleSearchChange.bind(this, "email")}
                                            />
                                        </Form.Group>
                                        <Form.Field width={8}>
                                            <Form.Input
                                                label={t('contact_form.country')}
                                                placeholder={t('contact_form.country')}
                                                value={this.country}
                                                error={this.countryError}
                                                onChange={this.handleSearchChange.bind(this, "country")}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={this.conditionsError}>
                                            <Checkbox
                                                style={{"marginTop": "10px", "marginRight": "5px"}}
                                                onChange={() => this.conditionsChecked = !this.conditionsChecked}
                                                checked={this.conditionsChecked}
                                            />
                                            {i18n.t('contact_form.terms_conditions')}
                                            <a onClick={() => history.push("/legal")}
                                               className={"clicky-thingy"}>
                                                {i18n.t('contact_form.privacy_link_text')}
                                            </a>
                                        </Form.Field>
                                        <Form.Field>
                                            <Checkbox
                                                style={{"marginTop": "10px", "marginRight": "5px"}}
                                                onChange={() => this.newsletter = !this.newsletter}
                                                checked={this.newsletter}
                                            />
                                            {i18n.t('contact_form.newsletter')}
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered>
                                <Grid.Column computer={8} tablet={10} mobile={14}>
                                    <Button type='submit'
                                            className={"glw-button"}
                                            onClick={() => this.sendRegistration()}>
                                        {t('contact_form.register')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}