import 'babel-polyfill';
import 'url-search-params-polyfill';
import './i18n';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import 'whatwg-fetch';
import React from "react"
import ReactDOM from "react-dom"
import history from "./helper/browserHistory"
import {Router} from "react-router-dom";
import {Provider} from "mobx-react";
import {HelmetProvider} from 'react-helmet-async';
import initStores from "./stores/initStores";
import Layout from "./pages/Layout"
import CookieBot from "react-cookiebot";
import TagManager from 'react-gtm-module'

const stores = initStores(window.STORES);

// Fetch texts
if (stores.textStore) {
    stores.textStore.fetchTexts();
}
if (stores.basketStore) {
    stores.basketStore.getBasketFromStorage();
}

const domainGroupId = '7e8a5361-18d1-4f61-ac41-5822266815e2';

TagManager.initialize({gtmId: 'GTM-TVG58ZZ'})


const app = document.getElementById('app');
ReactDOM.render(
    <HelmetProvider>
        <CookieBot domainGroupId={domainGroupId}/>
        <Provider stores={stores}>
            <Router history={history}>
                <Layout/>
            </Router>
        </Provider>
    </HelmetProvider>,
    app);