import { observable, action } from 'mobx';
import config from "../../config/main.config";
import 'whatwg-fetch';

class ProductsStore {
    @observable products = [];
    @observable loading = false;
    @observable error = false;

    @action fetchPublishedProducts() {
        this.loading = true;
        return fetch(config.BASE_URL + "products/published", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.products = json;
                    });

                }
                else if(response.status === 401) {
                    alert("Bitte melden Sie sich an");
                }
                else {
                  //  alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.error = "Error on fetching";
                    return false;
                }
            })
            .catch(
                error => {
                   // alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    @action fetchProducts() {
        this.loading = true;
        return fetch(config.BASE_URL + "products", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.products = json;
                    });

                }
                else if(response.status === 401) {
                    alert("Bitte melden Sie sich an");
                }
                else {
                    //  alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.error = "Error on fetching";
                    return false;
                }
            })
            .catch(
                error => {
                    // alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }
}

const store = new ProductsStore();

export default store;