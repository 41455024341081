export default {
    LANGUAGES: [
        {
            language:'de-DE',
            label:'Deutsch',
            regexToSearch: '/'
        },
        {
            language:'en-UK',
            label:'English',
            regexToSearch: /^\/en-UK*/
        }
    ]
}