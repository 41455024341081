import React from "react";
import PropTypes from "prop-types";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

export default class CardDownloadsComponent extends React.Component {

    render() {
        const {title, link} = this.props;
        if (!title || !link) {
            console.log('Src is not acceptable');
            return null;
        }

        return (
            <Grid.Row centered>
                <Grid.Column centered>
                    <Segment fluid className={"card-shadow stretched-card no-border"}>
                        <Grid>
                            <Grid.Row className={"no-padding"}>
                                <Grid.Column width={13} streched verticalAlign={"middle"}>
                                    <h3>{title}</h3>
                                    <p className={"text-block"}>
                                        {
                                            this.props.subTitle.length === 0 ?
                                                (link.toLowerCase().endsWith("pdf") ? "PDF" : "ZIP")
                                                :
                                                this.props.subTitle
                                        }
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={3} onClick={() => window.open(link)}
                                             className={"download-right"}>
                                    <Icon name={"download"} size={"huge"}/>
                                    <p>DOWNLOAD</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        )
    }
}

CardDownloadsComponent.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    link: PropTypes.string,
};

CardDownloadsComponent.defaultProps = {
    title: null,
    link: null,
    subTitle: ""
};