import React from "react";
import {inject, observer} from "mobx-react";
import {isAdmin, isInFAQ, isServiceAdmin} from "../helper/util";
import userStore from "../stores/userStore";
import productsStore from "../stores/productsStore";
import {observable} from "mobx";
import _ from 'lodash'
import numeral from "numeral";
import config from "../../config/main.config"

import {withTranslation} from "react-i18next";
import history from "../helper/browserHistory";
import {Link} from "react-router-dom";
import Search from "semantic-ui-react/dist/commonjs/modules/Search";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import List from "semantic-ui-react/dist/commonjs/elements/List";

const initialState = {isLoading: false, results: [], value: ''};


@inject("stores")
@withTranslation()
@observer
export default class NavigationBar extends React.Component {

    @observable
    search = initialState;

    @observable
    showBasket = false;

    @observable
    showMenu = false;

    @observable
    activeElement = "none";

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        productsStore.fetchProducts();
        history.listen(this.setActive.bind(this));
        if (typeof window !== "undefined") {
            this.activeElement = window.location.pathname;
        }
    }

    setActive() {
        if (typeof window !== "undefined") {
            this.activeElement = window.location.pathname;
        }
        this.setState({});
    }

    handleResultSelect = (e, {result}) => {
        //Show in detail page
        if (typeof result === "undefined") {
            return
        }
        this.search.results = [];
        this.setState({});
        if (result.id === "faq") {
            history.push({
                pathname: "/faq",
                search: "?search=" + encodeURIComponent(this.search.value)
            });

        } else if (this.isSortibox(result.id)) {
            history.push("/products/a7bf7a85-b595-44a9-a492-4d48d1c395dc/0?type=4");
        } else {
            history.push("/product-details/" + encodeURIComponent(result.title.replace("/", "||")));
        }
        this.search.value = "";
    };

    isSortibox(id) {
        for (let i = 0; i < productsStore.products.length; i++) {
            if (productsStore.products[i].id === id) {
                return productsStore.products[i].type === "Sortibox";
            }
        }
        return true;
    }

    handleSearchChange = (e, {value}) => {
        const {i18n} = this.props;
        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        let options = new Map();
        for (let i = 0; i < productsStore.products.length; i++) {

            let description = [];
            if (productsStore.products[i].itemNumber.length > 0) {
                description.push(productsStore.products[i].itemNumber)
            }
            if (productsStore.products[i].hasOwnProperty("miniBag") && productsStore.products[i].miniBag.length > 0) {
                description.push(productsStore.products[i].miniBag)
            }
            if (productsStore.products[i].hasOwnProperty("singleBag") && productsStore.products[i].singleBag.length > 0) {
                description.push(productsStore.products[i].singleBag)
            }
            if (productsStore.products[i].published) {
                options.set(productsStore.products[i].id, {
                    title: this.getOptionTitle(productsStore.products[i], language),
                    description: description.join(", "),
                    id: productsStore.products[i].id,
                    image: productsStore.products[i].link
                })
            }

        }
        this.search.isLoading = true;
        this.search.value = value;

        if (this.search.value.length < 1)
            return this.search = initialState;

        const re = new RegExp(_.escapeRegExp(this.search.value), 'i');
        const isMatchTitle = result => re.test(result.title) || re.test(result.description);
        this.search.isLoading = false;
        this.search.results = _.filter(Array.from(options, ([key, value]) => ({key, ...value})), isMatchTitle);
        if (isInFAQ(this.search.value, language, this.props.stores.textStore)) {
            this.search.results.push({
                title: i18n.t('titles.faq'),
                description: i18n.t('titles.faq_long'),
                id: "faq",
                image: "https://www.glw.de/images/Unternehmen_Stellenangebote_passiv.png"
            })
        }
    };

    getOptionTitle(product, language) {
        let title = "";

        if (product.productTypeId === "a7bf7a85-b595-44a9-a492-4d48d1c395dc"
            && product.type === "Sortibox") {
            title = this.getVersionNames(product, language)
        } else {
            title = language === "en-UK" ? product.nameEn : product.name
        }

        return title;
    }

    getVersionNames(product, language) {
        let title = [];
        if (product.hasOwnProperty("versions")) {
            for (let i = 0; i < product.versions.length; i++) {
                title.push(language === "en-UK" ? product.versions[i].nameEn : product.versions[i].name);
            }
        }

        if (title.length === 0) {
            title.push(language === "en-UK" ? product.typeEn : product.type)
        }
        return title.join();
    }


    render() {
        const {i18n, t} = this.props;
        let {basketStore} = this.props.stores;

        let language = "de-DE";

        if (i18n) {
            language = this.props.i18n.language;
        }

        let loggedIn = userStore.userFromServer !== null;


        const showItems = basketStore.basket.map((item, index) =>
            <List.Item key={index}>
                <List.Content
                    onClick={() => {
                        item.id === undefined ?
                            history.push("/products/a7bf7a85-b595-44a9-a492-4d48d1c395dc/0?type=4")
                            :
                            history.push("/product-details/" + encodeURIComponent(item.name.replace("/", "||")))
                    }}
                    className={"clicky-thingy"}
                    style={{
                        paddingRight: "10px"
                    }}>
                    {language === "en-UK" ? item.nameEn : item.name}
                    <Icon name={"delete"}
                          color={"red"}
                          style={{
                              position: "absolute",
                              right: "8px"
                          }}
                          onClick={() => basketStore.removeItemToBasket(item)}/>
                </List.Content>
            </List.Item>
        );

        return (
            <Grid>
                {
                    userStore.userFromServer != null && isAdmin() ?
                        <Grid.Row className={"primary-background-container admin-nav-row"}>
                            <Grid.Column comuter={1} only={'computer'}/>
                            <Grid.Column computer={4} tablet={4} mobile={10}
                                         className={"admin-nav"}>
                                <p>{t("titles.logged_in_as")} {userStore.userFromServer.username}</p>
                            </Grid.Column>
                            <Grid.Column floated='right' computer={9} tablet={13} mobile={13}
                                         textAlign={"right"} verticalAlign={"middle"}
                                         className={"admin-nav"}>
                                <Link to={"/customer-machine-management"}>
                                    {i18n.t('titles.customer_machine_management')}
                                </Link>
                                <Link to={"/customer-management"}>
                                    {i18n.t('titles.customer_management')}
                                </Link>
                                <Link to={"/sales-partner-management"}>
                                    {i18n.t('titles.sales_partner_management')}
                                </Link>
                                <Link to={"/product-service-management"}>
                                    {i18n.t('titles.product_service_management')}
                                </Link>
                                <Link to={"/product-management"}>
                                    {i18n.t('titles.product_management')}
                                </Link>
                                <Link to={"/machine-market-management"}>
                                    {i18n.t('titles.machine_market_management')}
                                </Link>
                            </Grid.Column>
                            <Grid.Column comuter={1} only={'computer'}/>
                        </Grid.Row>
                        :
                        null
                }
                {
                    userStore.userFromServer != null && isServiceAdmin() ?
                        <Grid.Row className={"primary-background-container admin-nav-row"}>
                            <Grid.Column comuter={1} only={'computer'}/>
                            <Grid.Column computer={4} tablet={4} mobile={10}
                                         className={"admin-nav"}>
                                <p>{t("titles.logged_in_as")} {userStore.userFromServer.username}</p>
                            </Grid.Column>
                            <Grid.Column floated='right' computer={9} tablet={13} mobile={13}
                                         textAlign={"right"} verticalAlign={"middle"}
                                         className={"admin-nav"}>
                                <Link to={"/customer-machine-management"}>
                                    {i18n.t('titles.customer_machine_management')}
                                </Link>
                                <Link to={"/customer-management"}>
                                    {i18n.t('titles.customer_management')}
                                </Link>
                                <Link to={"/sales-partner-management"}>
                                    {i18n.t('titles.sales_partner_management')}
                                </Link>
                            </Grid.Column>
                            <Grid.Column comuter={1} only={'computer'}/>
                        </Grid.Row>
                        :
                        null
                }
                <Grid.Row className={"language-search"}>
                    <Grid.Column computer={13} tablet={11} mobile={9} textAlign={"right"}>
                        <Search
                            loading={this.search.isLoading}
                            onResultSelect={this.handleResultSelect}
                            onSearchChange={this.handleSearchChange}
                            className={"search-bar"}
                            results={this.search.results}
                            value={this.search.value}
                            aligned='right'
                            noResultsMessage={i18n.t('search.no_results')}
                            placeholder={i18n.t('search.title')}
                        />
                    </Grid.Column>
                    <Grid.Column computer={1} tablet={2} mobile={2} textAlign={"center"}>
                        <Popup
                            trigger={
                                <div className={" trigger-button"}
                                     onClick={() => this.showBasket = !this.showBasket}>
                                    <Icon name={"star"} style={{paddingLeft: "0.5px"}}/>
                                    {basketStore.basket.length > 0 ?
                                        <Label color='red' circular className={"basket-label"}>
                                            {basketStore.basket.length}
                                        </Label>
                                        :
                                        null
                                    }
                                </div>
                            }
                            on='click'
                            pinned
                            position='bottom right'>
                            <Popup.Header> {i18n.t('products.basket_items')}</Popup.Header>
                            <Popup.Content>
                                {basketStore.basket.length > 0 ?
                                    <List divided>
                                        {showItems}
                                    </List>
                                    :
                                    <i
                                        style={{
                                            "paddingTop": "10px",
                                            "paddingBottom": "10px"
                                        }}>
                                        {i18n.t('products.no_basket')}
                                    </i>
                                }
                                {basketStore.basket.length > 0 ?
                                    <Button className={"glw-button"}
                                            onClick={() => history.push("/contact")}>
                                        {i18n.t('call_to_action.contact')}
                                    </Button>
                                    :
                                    <Button className={"glw-button"}
                                            onClick={() => history.push("/product-overview")}>
                                        {i18n.t('call_to_action.products')}
                                    </Button>
                                }

                            </Popup.Content>
                        </Popup>
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={3} mobile={4} textAlign={"left"}
                                 style={{"paddingTop": "5px"}}>

                        <p onClick={() => {
                            i18n.changeLanguage("de-DE");
                            numeral.locale("de");
                        }}
                           className={i18n.language === "de-DE" ? "active-language" : "inactive-language"}>
                            DE
                        </p>
                        <p className={"active-language"}> / </p>
                        <p onClick={() => {
                            i18n.changeLanguage("en-UK");
                            numeral.locale("en-gb");
                        }}
                           className={i18n.language === "en-UK" ? "active-language" : "inactive-language"}>
                            EN
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Responsive as={Grid.Row} maxWidth={787} className={"mobile-burger-row"}>
                    <Grid.Column width={16}>
                        <div key="hamburger" className="hamburger-mobile-navigation">
                            <div className="hamburger" onClick={() => this.showMenu = !this.showMenu}>
                                <div className={"bar1 " + (this.showMenu ? "change" : "")}/>
                                <div className={"bar2 " + (this.showMenu ? "change" : "")}/>
                                <div className={"bar3 " + (this.showMenu ? "change" : "")}/>
                            </div>
                        </div>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} maxWidth={787} className={this.showMenu ? "no-top-padding" : "hide-div"}>
                    <Grid.Column comuter={1} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Segment className={"navigation-menu-segment"}>
                            <Menu key="menu" pointing secondary stackable className={"navigation-menu"}
                                  widths={4}>
                                <Menu.Item
                                    onClick={() => {
                                        this.showMenu = false;
                                    }}
                                    key="home">
                                    <Link to={"/"}>
                                        HOME
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    active={this.activeElement.startsWith("/product")}
                                    onClick={() => {
                                        this.showMenu = false;
                                    }}
                                    key="products">
                                    <Link to={"/product-overview"}>
                                        {i18n.t('titles.products')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    active={this.activeElement.startsWith("/company")}
                                    onClick={() => {
                                        this.showMenu = false;
                                    }}
                                    key="company">
                                    <Link to={"/company/0"}>
                                        {i18n.t('titles.company')}
                                    </Link>
                                </Menu.Item>
                                {i18n.language === "de-DE" ? <Menu.Item
                                    active={this.activeElement.startsWith("/career")}
                                    onClick={() => {
                                        this.showMenu = false;
                                    }}
                                    key="career">
                                    <Link to={"/career"}>
                                        {i18n.t('titles.career')}
                                    </Link>
                                </Menu.Item> : null}
                                <Menu.Item
                                    active={this.activeElement === "/contact"}
                                    onClick={() => {
                                        this.showMenu = false;
                                    }}
                                    key="contact">
                                    <Link to={"/contact"}>
                                        {i18n.t('titles.contact')}
                                    </Link>
                                </Menu.Item>
                                {loggedIn ?
                                    <Menu.Item
                                        active={
                                            this.activeElement === "/my-glw" ||
                                            this.activeElement === "/my-profile" ||
                                            this.activeElement === "/machine-market"
                                        }
                                        onClick={() => {
                                            this.showMenu = false;
                                        }}
                                        key="login">
                                        <Link to={"/my-glw"}>
                                            {i18n.t('titles.my_glw')}
                                        </Link>
                                    </Menu.Item>
                                    :
                                    <Menu.Item
                                        active={this.activeElement === "/login"}
                                        onClick={() => {
                                            this.showMenu = false;
                                        }}
                                        key="login">
                                        <Link to={"/login"}>
                                            {i18n.t('titles.my_glw')}
                                        </Link>
                                    </Menu.Item>
                                }
                            </Menu>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column comuter={1} only={'computer'}/>
                </Responsive>
                <Responsive as={Grid.Row} minWidth={788} className={"no-top-padding"}>
                    <Grid.Column comuter={1} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Segment className={"navigation-menu-segment"}>
                            <Menu key="menu" pointing secondary stackable className={"navigation-menu"}
                                  widths={6}>
                                <div className={"logo-image"}>
                                    <Image src={config.BASE_IMAGE_URL + "Logo.svg"}
                                           onClick={() => {
                                               history.push("/");
                                           }}
                                           alt={"GLW Logo"}
                                           size={"small"}
                                           className={"image-center clicky-thingy"}
                                    />
                                </div>
                                <Menu.Item
                                    active={this.activeElement.startsWith("/product")}
                                    key="products">
                                    <Link to={"/product-overview"}>
                                        {i18n.t('titles.products')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    active={this.activeElement.startsWith("/company")}
                                    key="company">
                                    <Link to={"/company-overview"}>
                                        {i18n.t('titles.company')}
                                    </Link>
                                </Menu.Item>
                                {i18n.language === "de-DE" ? <Menu.Item
                                    active={this.activeElement.startsWith("/career")}
                                    onClick={() => {
                                        this.showMenu = false;
                                    }}
                                    key="career">
                                    <Link to={"/career"}>
                                        {i18n.t('titles.career')}
                                    </Link>
                                </Menu.Item> : null}
                                <Menu.Item
                                    active={this.activeElement === "/contact"}
                                    key="contact">
                                    <Link to={"/contact"}>
                                        {i18n.t('titles.contact')}
                                    </Link>
                                </Menu.Item>
                                {loggedIn ?
                                    <Menu.Item
                                        active={
                                            this.activeElement === "/my-glw" ||
                                            this.activeElement === "/my-profile" ||
                                            this.activeElement === "/machine-market" ||
                                            this.activeElement === "/service-overview"
                                        }
                                        onClick={() => {
                                            this.showMenu = false;
                                        }}
                                        key="login">
                                        <Link to={"/my-glw"}>
                                            {i18n.t('titles.my_glw')}
                                        </Link>
                                    </Menu.Item>
                                    :
                                    <Menu.Item
                                        active={this.activeElement === "/login"}
                                        onClick={() => {
                                            this.showMenu = false;
                                        }}
                                        key="login">
                                        <Link to={"/login"}>
                                            {i18n.t('titles.my_glw')}
                                        </Link>
                                    </Menu.Item>
                                }
                            </Menu>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column comuter={1} only={'computer'}/>
                </Responsive>
            </Grid>
        );
    }
}