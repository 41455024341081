import React from "react";
import {observer} from "mobx-react";
import ProductTypeComponent from "../components/ProductTypeComponent";
import {withTranslation} from "react-i18next";
import history from "../helper/browserHistory";
import {updatePageView} from "../helper/util";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";


@withTranslation()
@observer
export default class ProductOverviewPage extends React.Component {

    componentDidMount() {
        updatePageView(this.props.location.pathname);
        window.scrollTo(0, 0);
    }

    render() {

        const {i18n} = this.props;

        return (
            <Grid>
                <EditHeaderComponent id={"product-overview-page"}/>
                <Grid.Row className={"no-top-padding primary-background-container"}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider
                                onClick={() => history.push("/")}>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>{i18n.t('titles.product_overview')}</Breadcrumb.Section>
                        </Responsive>
                        <h1 className={"top-row-text"}>{i18n.t('titles.product_overview')}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Aderendhuelse.png"}
                            title={i18n.t('products.wire_end_sleeves')}
                            link={"/products/a7bf7a85-b595-44a9-a492-4d48d1c395dc/0"}
                            text={"front-wire-end-sleeves-text"}
                            categoryId={"a7bf7a85-b595-44a9-a492-4d48d1c395dc"}
                        />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Ablaengen.png"}
                            title={i18n.t('products.cutting')}
                            link={"/products/63362656-e5af-4860-a9ec-e1898f82b42b/0"}
                            text={"front-cutting-text"}
                            categoryId={"63362656-e5af-4860-a9ec-e1898f82b42b"}
                        />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Crimpen.png"}
                            title={i18n.t('products.crimping')}
                            link={"/products/0f6866f7-e3a9-4b6b-af8c-bfabb9b088cb/0"}
                            text={"front-crimping-text"}
                            categoryId={"0f6866f7-e3a9-4b6b-af8c-bfabb9b088cb"}
                        />
                    </Grid.Column>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Abisolieren.png"}
                            title={i18n.t('products.wire_stripper')}
                            link={"/products/1bbf1dfb-74df-4927-b3bd-f746535db5f8/0"}
                            text={"front-stripper-text"}
                            categoryId={"1bbf1dfb-74df-4927-b3bd-f746535db5f8"}
                        />
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={16} className={"clicky-thingy"}>
                        <ProductTypeComponent
                            imageId={"https://www.glw.de/images/Start_Zubehoer.png"}
                            title={i18n.t('products.accessories')}
                            link={"/products/0ea1c24c-c667-4340-8873-c65bf5d0e77d/0"}
                            text={"front-accessories-text"}
                            categoryId={"0ea1c24c-c667-4340-8873-c65bf5d0e77d"}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}