import {observer} from "mobx-react";
import React from "react";
import {withTranslation} from "react-i18next";
import {observable, toJS} from "mobx";
import {getTokenFromLocalStorage, isAdmin} from "../helper/util";
import renderHTML from 'react-render-html';
import config from "../../config/main.config";
import {stateFromHTML} from 'draft-js-import-html';

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import i18n from "../i18n";
import {Link} from "react-router-dom";


let ContentState = null;
let convertToRaw = null;
let EditorState = null;
let Editor = null;

if (typeof document !== "undefined") {
    // Require scss files
    require('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
    ContentState = require('draft-js').ContentState;
    convertToRaw = require('draft-js').convertToRaw;
    EditorState = require('draft-js').EditorState;

    Editor = require('react-draft-wysiwyg').Editor;
}

const EMPTY_ITEM = {
    id: "",
    title: {},
    teaser: {},
    text: {},
    active: 0
};

const LANGUAGES = [
    {
        language: 'de-DE',
        label: 'Deutsch',
    },
    {
        language: 'en-UK',
        label: 'English',
    }
];

const preFillDe = " <p style=\"text-align: left;\"> " +
    "<span><strong>Aufgaben:</strong></span></p>" +
    " <ul> <li><span>...</span>&nbsp;</li> " +
    "</ul> <p style=\"text-align: left;\"><span><strong>Profil:</strong></span>&nbsp;</p> <ul>" +
    "   <li><span>...</span>&nbsp;</li>" +
    "  </ul>" +
    " <p style=\"text-align: left;\"><span><strong>Wir bieten:</strong></span>&nbsp;</p>" +
    "  <ul>" +
    "      <li><span>...</span>&nbsp;</li>" +
    "  </ul>" +
    "</p>";

@withTranslation()
@observer
export default class JobOffersSubPage extends React.Component {

    @observable
    language = 'de-DE';

    @observable
    articles = [];

    @observable articleModalOpen = false;
    @observable createArticleItem = EMPTY_ITEM;

    @observable deleteModalOpen = false;
    @observable deleteID = "";

    @observable
    activeItems = [];

    toggleAccordionItem(item) {
        if (this.activeItems.includes(item)) {
            this.activeItems.splice(this.activeItems.indexOf(item), 1);
        } else {
            this.activeItems.push(item);
        }
        this.setState({});
    }

    componentDidMount() {
        this.fetchAllArticles();

        const standardFill = {};
        const editorFill = {};
        for (let i = 0; i < LANGUAGES.length; i++) {
            standardFill[LANGUAGES[i].language] = "";
        }
        for (let i = 0; i < LANGUAGES.length; i++) {
            editorFill[LANGUAGES[i].language] = EditorState.createEmpty();
        }

        this.createArticleItem = {
            id: "",
            title: standardFill,
            teaser: standardFill,
            text: editorFill,
            active: 0,
        };
    }

    fetchAllArticles() {
        let self = this;
        //Get all products
        fetch(config.BASE_URL + "job/all", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasOwnProperty("articles")) {
                        self.articles = json.articles;
                        self.setState({});
                    }
                });
            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/");
                }
            }
        );
    }

    deleteArticle(id) {
        let self = this;
        fetch(config.BASE_URL + 'job', {
            method: 'DELETE',
            headers: {
                "id": id,
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((resp) => resp.json())
            .then(data => {
                self.fetchAllArticles();
                self.deleteModalOpen = false;
                self.deleteID = "";
                self.setState({});
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    handleInputChange(type, event) {
        switch (type) {
            case 'title':
                this.createArticleItem.title[this.language] = event.target.value;
                break;
            case 'teaser':
                this.createArticleItem.teaser[this.language] = event.target.value;
                break;
        }
        this.setState({});
    }

    handleLanguageChange(language) {
        this.language = language;
        this.setState({});
    }

    openEditArticleModal(item) {

        let articleText = {};

        for (let i = 0; i < LANGUAGES.length; i++) {
            let language = LANGUAGES[i].language;
            let contentState = stateFromHTML(item.text[language]);
            articleText[language] = EditorState.createWithContent(contentState);
        }

        this.createArticleItem = {
            id: item.id,
            title: item.title,
            teaser: item.teaser,
            text: articleText,
            active: item.active
        };
        this.articleModalOpen = true;
        this.setState({});
    }

    addArticle(item) {
        let httpType = 'POST';

        if (item.id !== "") {
            httpType = 'PUT';
        }

        let articleText = {};

        const draftToHtml = require("draftjs-to-html");

        for (let i = 0; i < LANGUAGES.length; i++) {
            let language = LANGUAGES[i].language;
            articleText[language] = draftToHtml(convertToRaw(item.text[language].getCurrentContent()));
        }

        let newArticle = {
            id: item.id,
            title: item.title,
            teaser: item.teaser,
            text: articleText,
            active: item.active
        };

        fetch(config.BASE_URL + 'job', {
            method: httpType,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newArticle)
        }).then((resp) => resp.json())
            .then(article => {
                this.fetchAllArticles();
                this.articleModalOpen = false;
                this.createArticleItem = EMPTY_ITEM;
                this.setState({});
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    openCreateArticleModal() {
        const standardFill = {};
        const editorFill = {};

        for (let i = 0; i < LANGUAGES.length; i++) {
            standardFill[LANGUAGES[i].language] = "";
        }
        for (let i = 0; i < LANGUAGES.length; i++) {
            if (LANGUAGES[i].language === "de-DE") {
                let contentState = stateFromHTML(preFillDe);
                editorFill[LANGUAGES[i].language] = EditorState.createWithContent(contentState);
            } else {
                editorFill[LANGUAGES[i].language] = EditorState.createEmpty();
            }
        }

        this.createArticleItem = {
            id: "",
            title: standardFill,
            teaser: standardFill,
            text: editorFill,
        };
        this.articleModalOpen = true;
        this.setState({});
    }

    onEditorStateChange(editorState) {
        this.createArticleItem.text[this.language] = editorState;
    };

    generatePDF(id) {
        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }
        const windowReference = window;

        fetch(config.BASE_URL + 'job/pdf/' + id + "/" + language, {
            method: 'GET',
            headers: {
                'Accept': 'application/pdf',
                'Content-Type': 'application/pdf'
            }
        }).then(resp => {
            windowReference.location.href = resp.url;
        });
    }

    getJobsAccordion(articles, language, isApprenticeship = false) {

        let accordions = [];

        for (let i = 0; i < articles.length; i++) {
            let article = articles[i];
            if (article.title[language].length > 0 && article.active === 1) {
                const isApprenticeshipArticle = article.title[language].includes("Auszubildende");
                if ((isApprenticeship && isApprenticeshipArticle) || (!isApprenticeship && !isApprenticeshipArticle)) {
                    accordions.push(
                        <Accordion.Title
                            key={"t" + i}
                            className={this.activeItems.includes(i) ? "opened-accordion-title" : "closed-accordion-title"}
                            onClick={() => this.toggleAccordionItem(i)}
                        >
                            <Grid>
                                <Grid.Row style={{paddingBottom: "0px"}}>
                                    <Grid.Column computer={12} tablet={10} mobile={16}>
                                        <h1>{article.title[language]}</h1>
                                        <p className={"card-text"}>{article.teaser[language]}</p>
                                    </Grid.Column>
                                    <Grid.Column
                                        style={{margin: "auto"}}
                                        computer={4} tablet={6} mobile={16}
                                        verticalAlign={"top"}
                                        textAlign={"right"}
                                    >
                                        <Button className={"glw-button"} style={{marginBottom: "10px"}}>
                                            <Link to={"/apply/" + article.id} className={"glw-button"}>
                                                {i18n.t('call_to_action.apply_60').toUpperCase()}
                                            </Link>
                                        </Button>
                                        <Button className={"glw-button"} onClick={() => this.generatePDF(article.id)}>
                                            {"DOWNLOAD"}
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row centered style={{paddingTop: "0px"}}>
                                    <Grid.Column textAlign={"center"}>
                                        <Icon
                                            size={"big"}
                                            className={"accordion-icon"}
                                            name={this.activeItems.includes(i) ? 'angle up' : 'angle down'}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Accordion.Title>
                    );

                    accordions.push(
                        <Accordion.Content
                            key={"c" + i}
                            className={this.activeItems.includes(i) ? "accordion-content" : ""}
                            active={this.activeItems.includes(i)}
                        >
                            <p className={this.activeItems.includes(i) ? "faq-answer video-text" : "hide-div"}>
                                {articles[i].text[language] != null ? renderHTML(articles[i].text[language]) : ""}
                            </p>
                        </Accordion.Content>
                    );
                }
            }
        }

        return <Grid.Column width={16}>
            <Accordion
                defaultActiveIndex={toJS(this.activeItems)}
                fluid
                exclusive={false}>
            </Accordion>
            {accordions}
        </Grid.Column>
    }

    render() {
        let language = "de-DE";
        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        let showArticles = [];
        let showApprenticeArticles = [];
        //if admin show edit stuff
        let displayType = isAdmin() ? "admin" : "user";

        if (displayType === "admin") {
            showArticles = this.articles.map((article, index) =>
                <Grid.Row centered>
                    <Grid.Column width={16} stretched key={index}>
                        <Card fluid className={"card-shadow"}>
                            <Card.Content>
                                <Card.Header>
                                    <h1>
                                        {article.title[language]}
                                    </h1>
                                </Card.Header>
                                <Card.Description>
                                    <p className={"card-text"}>
                                        {article.teaser[language]}
                                    </p>
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <div className='ui two buttons'>
                                    <Button basic color='green'
                                            onClick={this.openEditArticleModal.bind(this, article)}>
                                        <strong> Bearbeiten </strong>
                                    </Button>

                                    <Button basic color='red'
                                            onClick={() => {
                                                this.deleteModalOpen = true;
                                                this.deleteID = article.id;
                                                this.setState({});
                                            }}>
                                        <strong> Löschen </strong>
                                    </Button>
                                </div>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            )
        } else {
            showArticles = this.getJobsAccordion(this.articles, language);
            showApprenticeArticles = this.getJobsAccordion(this.articles, language, true);
        }

        const languageRadioButtons = LANGUAGES.map(language => (
            <Form.Field key={language.language}>
                <Radio
                    label={language.label}
                    name='radioGroup'
                    value={language.language}
                    checked={this.language === language.language}
                    onChange={() => this.handleLanguageChange(language.language)}
                />
            </Form.Field>));

        return (
            <Grid>
                <Modal
                    dimmer={"blurring"}
                    style={{"margin": "auto"}}
                    open={this.deleteModalOpen}
                    onClose={() => this.deleteModalOpen = false}>
                    <Modal.Header>
                        Jobangebot Löschen
                    </Modal.Header>
                    <Modal.Content>
                        Möchten Sie dieses Jobangebot wirklich löschen?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => {
                            this.deleteModalOpen = false;
                            this.deleteID = "";
                            this.setState({})
                        }}>
                            <Icon name='remove'/> Nein
                        </Button>
                        <Button color='green'
                                onClick={() => this.deleteArticle(this.deleteID)}>
                            <Icon name='checkmark'/> Ja
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    dimmer={"blurring"}
                    style={{"margin": "auto"}}
                    open={this.articleModalOpen}
                    onClose={() => this.articleModalOpen = false}>
                    <Modal.Header>
                        {this.createArticleItem.id === "" ?
                            "Jobangebot erstellen"
                            :
                            "Jobangebot brearbeiten"
                        }
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <Form>
                            <Form.Group>
                                {languageRadioButtons}
                            </Form.Group>
                            <Form.Input
                                label='Titel'
                                value={this.createArticleItem.title[this.language]}
                                onChange={this.handleInputChange.bind(this, 'title')}
                            />
                            <Form.Input
                                label='Untertitel'
                                value={this.createArticleItem.teaser[this.language]}
                                onChange={this.handleInputChange.bind(this, 'teaser')}
                            />
                            <Form.Field>
                                <Checkbox
                                    onChange={() => this.createArticleItem.active = this.createArticleItem.active === 1 ? 0 : 1}
                                    checked={this.createArticleItem.active === 1}
                                    label={"Aktiv"}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Text</label>
                                <div className="edit-wrapper">
                                    <Editor
                                        editorState={this.createArticleItem.text[this.language]}
                                        onEditorStateChange={this.onEditorStateChange.bind(this)}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'],
                                        }}
                                    />
                                </div>
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => {
                            this.articleModalOpen = false;
                            this.setState({});
                        }}>
                            <Icon name='remove'/> Abrechen
                        </Button>
                        <Button color='green'
                                onClick={this.addArticle.bind(this, this.createArticleItem)}>
                            <Icon name='checkmark'/> OK
                        </Button>
                    </Modal.Actions>
                </Modal>
                {displayType === "admin" ?
                    <Grid.Row centered>
                        <Button onClick={this.openCreateArticleModal.bind(this)}
                                floated='right'
                                icon labelPosition='right'
                                color='green'>
                            <strong> Artikel hinzufügen </strong>
                            <Icon name='plus'/>
                        </Button>
                    </Grid.Row>
                    :
                    null
                }
                <Grid.Row centered>
                    <Grid.Column width={16}>
                        <h1>{i18n.t("company_page.apprentice_offers")}</h1>
                        <Grid>
                            {showApprenticeArticles}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={16}>
                        <h1>{i18n.t("company_page.job_offers")}</h1>
                        <Grid>
                            {showArticles}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}