import React from "react";
import i18n from "../i18n";
import {inject, observer} from "mobx-react";
import {observable, toJS} from "mobx";
import history from "../helper/browserHistory";
import {withTranslation} from "react-i18next";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {diesValues, diesValuesEn, updatePageView} from "../helper/util";
import {Link} from "react-router-dom";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import config from "../../config/main.config";
import {Checkbox} from "semantic-ui-react";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

if (typeof document !== "undefined") {
    // Require scss files
    require('react-lazy-load-image-component/src/effects/opacity.css');
}


let productTypeId = "0f6866f7-e3a9-4b6b-af8c-bfabb9b088cb";
let productType2Id = "63362656-e5af-4860-a9ec-e1898f82b42b";
let productType3Id = "a7bf7a85-b595-44a9-a492-4d48d1c395dc";
let productType4Id = "1bbf1dfb-74df-4927-b3bd-f746535db5f8";
let productType5Id = "91e84278-8002-428b-95f5-c4515fc744e2";
let productType6Id = "f39e81ae-1f6d-4d82-b135-1f24b3d18c15";
let productType7Id = "0ea1c24c-c667-4340-8873-c65bf5d0e77d";
let productType8Id = "d209fd49-0402-4b9e-bcc1-c7622b04b1a5";
let productType9Id = "571364ab-792a-4926-b206-b38944e281dd";

const SORTI_TYPE = 4;

const productIds = [
    productType3Id,
    productType2Id,
    productTypeId,
    productType4Id,
    productType5Id,
    productType6Id,
    productType7Id,
    productType8Id,
    productType9Id
];

const CROSS_SECTION_TYPE = "Querschnitt (mm2)";
const LENGTH_TYPE = "Länge (mm)";
const COLOR_TYPE = "Farbcode";

const CROSS_SECTION_TYPE_EN = "Cross section (mm2)";
const LENGTH_TYPE_EN = "Length (mm)";
const COLOR_TYPE_EN = "Color";

const sleeveValues = [
    {
        title: "Isoliert",
        aliases: ["Isoliert"]
    }, {
        title: "Twin",
        aliases: ["Isoliert Twin", "Twin"]
    },
    {
        title: "Unisoliert",
        aliases: ["Unisoliert"]
    }, {
        title: "Bandform",
        aliases: ["Bandform"]
    },
    {
        title: "Sortibox",
        aliases: [
            "Sortibox"
        ]
    }
];

const sleeveValuesEn = [
    {
        title: "Insulated",
        aliases: ["Insulated"]
    }, {
        title: "Twin",
        aliases: ["Insulated Twin", "Twin"]
    },
    {
        title: "Uninsulated",
        aliases: ["Uninsulated"]
    }, {
        title: "Coils",
        aliases: ["Coils"]
    },
    {
        title: "Assortment box",
        aliases: [
            "Assortment box"
        ]
    }
];


@withTranslation()
@inject("stores")
@observer
export default class ProductsPage extends React.Component {

    @observable
    crossSectionOptions = [];

    @observable
    chosenCrossSectionValue = "";

    @observable
    lengthOptions = [];

    @observable
    chosenLengthValue = "";

    @observable
    colorOptions = [];

    @observable
    chosenColorValue = "";

    @observable
    sleeveType = -1;

    @observable
    diesType = -1;

    @observable
    diesXL = false;

    @observable
    diesGB = false;

    @observable
    diesSearch = "";

    @observable
    showHandTools = false;

    @observable
    showSubMenu = false;

    @observable
    products = [];

    getActiveProductTypeName() {
        switch (this.state.activeProductType) {
            case productType3Id:
                return i18n.t('products.wire_end_sleeves').toUpperCase();
            case productType2Id:
                return i18n.t('products.cutting').toUpperCase();
            case productTypeId:
                return i18n.t('products.crimping').toUpperCase();
            case productType4Id:
                return i18n.t('products.wire_stripper').toUpperCase();
            case productType5Id:
                return i18n.t('products.contract_rail').toUpperCase();
            case productType6Id:
                return i18n.t('products.wiring_ducts').toUpperCase();
            case productType7Id:
                return i18n.t('products.accessories').toUpperCase();
            case productType8Id:
                return i18n.t('products.crimping_dies').toUpperCase();
            case productType9Id:
                return i18n.t('products.locator').toUpperCase();
        }
    }

    constructor(props) {
        super(props);
        if (props.staticContext && props.staticContext.length > 0) {
            this.state = {
                products: props.staticContext,
                activeProductType: props.match.params.id,
                loading: false
            }

        } else {
            this.state = {
                products: [],
                activeProductType: props.match.params.id,
                loading: false
            }
        }
    }

    componentDidMount() {
        updatePageView(this.props.location.pathname);
        window.scrollTo(0, 0);

        let id = this.props.match.params.id;
        let handTool = this.props.match.params.handTool;

        this.setProductTypeId(id, handTool, this.props.location.search);

        history.listen((location, action) => {
            // let id = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
            let locationArray = location.pathname.split("/");
            this.setProductTypeId(locationArray[2], locationArray[3], location.search);
        });
        this.fetchPublishedProducts();
    }

    fetchPublishedProducts() {
        return fetch(config.BASE_URL + "products/published", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        this.setState({
                            loading: false,
                            products: json
                        });
                    });
                }
            })
            .catch(
                error => {
                    this.loading = false;
                    this.error = true;
                    throw error;
                }
            );
    }

    setProductTypeId(id, handTool, search) {
        if (productIds.includes(id)) {
            this.setState({
                ...this.state,
                activeProductType: id
            });
        } else {
            this.setState({
                ...this.state,
                activeProductType: productType3Id
            });
        }

        this.showHandTools = handTool === "1";

        //check search for saved filter
        //  const search = this.props.location.search;
        const params = new URLSearchParams(search);
        if (this.state.activeProductType === productType8Id || this.state.activeProductType === productType9Id) {
            //saved gesenke filter
            let dies = parseInt(params.get('diestype'));
            if (typeof dies === "number" && !isNaN(dies)) {
                this.diesType = dies
            }

        } else if (this.state.activeProductType === productType3Id) {
            //saved ferrule filter
            let color = params.get('color');

            if (typeof color === "string") {
                this.chosenColorValue = color;
            } else {
                this.chosenColorValue = "";
            }

            let length = parseFloat(params.get('length'));
            if (typeof length === "number" && !isNaN(length)) {
                this.chosenLengthValue = length
            } else {
                this.chosenLengthValue = "";
            }

            let cross = parseFloat(params.get('cross'));
            if (typeof cross === "number" && !isNaN(cross)) {
                this.chosenCrossSectionValue = cross
            } else {
                this.chosenCrossSectionValue = "";
            }

            let type = parseInt(params.get('type'));
            if (typeof type === "number") {
                this.sleeveType = type
            } else {
                this.sleeveType = -1;
            }

            let diesXL = params.get('xl');
            this.diesXL = typeof diesXL === "string";
            let diesGB = params.get('gb');
            this.diesGB = typeof diesGB === "string";

        }

        this.setState({})
    }

    getAllFilteredProducts() {
        let showProducts = [];

        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        let hasHandTools = false;
        let handtoolsLink = "";

        let products = this.state.products;

        //To prevent double stuff
        let indexAdd = 0;
        if (this.state.activeProductType === productType7Id) {
            //  products = this.sortAccessories(products);

            showProducts.push(
                <Grid.Column
                    floated={"left"}
                    key={0}
                    computer={5} tablet={8} mobile={8}
                    className={"show-product"}
                    onClick={() => {
                        history.push("/products/" + productType8Id + "/0?diestype=0");
                        this.setState({})
                    }}>
                    <div className="ui fluid image">
                        <img src={"https://www.glw.de/images/Start_Gesenke.png"}/>
                        <div style={{position: 'absolute', bottom: 0, width: '100%', height: '100%'}}
                             className={"image-overlay"}/>
                    </div>
                    <p>
                        <Link
                            className={"products-link"}
                            to={"/products/" + productType8Id + "/0?diestype=0"}>
                            <b>{i18n.t('products.crimping_dies')}</b>
                        </Link>
                    </p>
                </Grid.Column>
            );
            indexAdd++;

            showProducts.push(
                <Grid.Column
                    floated={"left"}
                    key={0}
                    computer={5} tablet={8} mobile={8}
                    className={"show-product"}
                    onClick={() => {
                        history.push("/products/" + productType9Id + "/0")
                    }}>
                    <div className="ui fluid image">
                        <img src={"https://www.glw.de/images/Locator.png"}/>
                        <div style={{position: 'absolute', bottom: 0, width: '100%', height: '100%'}}
                             className={"image-overlay"}/>
                    </div>
                    <p style={{"marginBottom": "0"}}>
                        <Link
                            className={"products-link"}
                            to={"/products/" + productType9Id + "/0"}>
                            <b>{i18n.t('products.locator')}</b>
                        </Link>
                    </p>
                    <p>
                        {language === "en-UK" ? "" : "Positionierhilfen"}
                    </p>
                </Grid.Column>
            );
            indexAdd++;
        }

        //If locator: sort alphabetically
        if (this.state.activeProductType.toString() === productType9Id) {
            products = products.sort(function compare(a, b) {
                let aName = language === "en-UK" ? a.nameEn : a.name;
                let bName = language === "en-UK" ? b.nameEn : b.name;

                if (aName > bName) {
                    return 1;
                }
                if (bName > aName) {
                    return -1;
                }
                return 0;
            });
        }

        //filter products
        for (let i = 0; i < products.length; i++) {
            let product = products[i];
            if ((product.productTypeId === this.state.activeProductType &&
                this.state.activeProductType !== productType3Id &&
                this.state.activeProductType !== productType8Id &&
                this.state.activeProductType !== productType9Id &&
                !product.isHandTool) ||
                (product.productTypeId === this.state.activeProductType &&
                    (this.state.activeProductType === productType8Id ||
                        this.state.activeProductType === productType9Id) &&
                    this.isDiesOptionSelected(product) &&
                    !product.isHandTool) || product.productTypeId === "76aeab8a-857a-11ef-b160-00505611d5a9" && this.state.activeProductType === productTypeId
            ) {
                showProducts.push(
                    <Grid.Column
                        floated={"left"}
                        key={showProducts.length + indexAdd}
                        computer={5} tablet={8} mobile={8}
                        className={"show-product"}
                        onClick={() => history.push("/product-details/" + encodeURIComponent(product.name.replace("/", "||")))}>
                        {product.new ?
                            <div className={"new_product_label"}>{i18n.t("products.new")}</div>
                            : null}
                        <div className="ui fluid image">
                            <LazyLoadImage
                                alt={"https://www.glw.de/images/Logo.png"}
                                effect="opacity"
                                style={{aspectRatio:"1/1", objectFit:"cover"}}
                                src={product.link}/>
                            { /* <img src={product.link}/>*/}
                            <div style={{position: 'absolute', bottom: 0, width: '100%', height: '100%'}}
                                 className={"image-overlay"}/>
                        </div>
                        <p>
                            <Link
                                className={"products-link"}
                                to={"/product-details/" + encodeURIComponent(product.name.replace("/", "||"))}>
                                <b>{language === "en-UK" ? product.nameEn : product.name}</b>
                            </Link>
                        </p>
                        <p>{language === "en-UK" ? product.subtitleEn : product.subtitle}</p>
                    </Grid.Column>
                )
            }
            if (product.productTypeId === this.state.activeProductType && product.isHandTool) {
                hasHandTools = true;
                handtoolsLink = product.link;
            }
        }

        //if has handtools Add show handtools card
        if (hasHandTools) {
            if (this.state.activeProductType === productTypeId) {
                handtoolsLink = "https://www.glw.de/images/blog-images/CEB_0160S.jpg";
            }
            showProducts.push(
                <Grid.Column
                    floated={"left"}
                    key={showProducts.length + indexAdd}
                    computer={5} tablet={8} mobile={8}
                    className={"show-product"}
                    onClick={() => history.push("/products/" + this.state.activeProductType + "/1")}>
                    <div className="ui fluid image">
                        <LazyLoadImage
                            alt={"https://www.glw.de/images/Logo.png"}
                            effect="opacity"
                            style={{aspectRatio:"1/1", objectFit:"cover"}}
                            src={handtoolsLink}/>
                        <div style={{position: 'absolute', bottom: 0, width: '100%', height: '100%'}}
                             className={"image-overlay"}/>
                    </div>
                    <Link
                        className={"products-link"}
                        to={"/products/" + this.state.activeProductType + "/1"}>
                        <p style={{"marginBottom": "0"}}><b>{i18n.t('products.hand_tools')}</b></p>
                    </Link>
                </Grid.Column>
            );
            indexAdd++;
        }

        if (showProducts.length % 3 === 2) {
            showProducts.push(
                <Grid.Column
                    floated={"left"}
                    key={showProducts.length + indexAdd}
                    computer={5} tablet={8} mobile={8}
                    className={"show-product"}
                />
            )
        }
        if (showProducts.length % 3 === 1) {
            showProducts.push(
                <Grid.Column
                    floated={"left"}
                    key={showProducts.length + indexAdd}
                    computer={5} tablet={8} mobile={8}
                    className={"show-product"}
                />
            );
            showProducts.push(
                <Grid.Column
                    floated={"left"}
                    key={showProducts.length + indexAdd + 1}
                    computer={5} tablet={8} mobile={8}
                    className={"show-product"}
                />
            )
        }

        return showProducts;
    }

    getFilteredWireEndSleeves() {
        let showProducts = [];

        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }
        this.getSleevesOptions(language);

        if (this.sleeveType === 4) {
            return this.getAllSortiboxes(language);
        }

        let computer = false;
        if (typeof document !== "undefined") {
            computer = window.innerWidth > 991;
        }

        //filter products
        for (let i = 0; i < this.state.products.length; i++) {
            let product = this.state.products[i];
            if (product.productTypeId === this.state.activeProductType &&
                this.state.activeProductType === productType3Id &&
                this.state.activeProductType !== productType8Id &&
                this.state.activeProductType !== productType9Id &&
                this.isOptionSelected(product) &&
                !product.isHandTool &&
                (this.diesXL && this.diesGB ?
                        (product.subtitle.includes("(XL)") || product.subtitle.includes("(GB)"))
                        :
                        ((!this.diesXL || product.subtitle.includes("(XL)")) &&
                            (!this.diesGB || product.subtitle.includes("(GB)")))
                ) &&
                (this.diesSearch.length === 0 ||
                    (product.subtitle.toLowerCase().includes(this.diesSearch.toLowerCase()) || product.name.toLowerCase().includes(this.diesSearch.toLowerCase())))
            ) {
                if (computer) {
                    showProducts.push(
                        <Table.Row key={"wes" + i}
                                   onClick={() => {
                                       history.push("/product-details/" + encodeURIComponent(product.name)+ "?name="+ product.id)
                                   }}
                                   className={"clicky-thingy"}>
                            <Table.Cell>{language === "de-DE" ? this.toGermanFloat(product.crossSection) : product.crossSection}</Table.Cell>
                            <Table.Cell>{language === "de-DE" ? this.toGermanFloat(product.length) : product.length}</Table.Cell>
                            <Table.Cell>
                                {product.colorCode.length > 0 ? <Icon
                                    name={product.colorCode === "#ffffff" ? "square outline" : "square"}
                                    style={product.colorCode === "#ffffff" ? {} : {"color": product.colorCode}}/> : null}
                                {language === "en-UK" ? product.colorEn : product.color}
                            </Table.Cell>
                            <Table.Cell>
                                <Link
                                    className={"products-link"}
                                    to={"/product-details/" + encodeURIComponent(product.name)+ "?name="+ product.id}>
                                    {product.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>{language === "en-UK" ? product.subtitleEn : product.subtitle}</Table.Cell>
                        </Table.Row>
                    )
                } else {
                    showProducts.push(
                        <Table.Row key={"wes" + i}
                                   onClick={() => {
                                       history.push("/product-details/" + encodeURIComponent(product.name)+ "?name="+ product.id)
                                   }}
                                   className={"clicky-thingy"}>
                            <Table.Cell>{language === "de-DE" ? this.toGermanFloat(product.crossSection) : product.crossSection}</Table.Cell>
                            <Table.Cell>{language === "de-DE" ? this.toGermanFloat(product.length) : product.length}</Table.Cell>
                            <Table.Cell>
                                {product.colorCode.length > 0 ? <Icon
                                    name={product.colorCode === "#ffffff" ? "square outline" : "square"}
                                    style={product.colorCode === "#ffffff" ? {} : {"color": product.colorCode}}/> : null}
                                {language === "en-UK" ? product.colorEn : product.color}
                            </Table.Cell>
                            <Table.Cell>
                                <Link
                                    className={"products-link"}
                                    to={"/product-details/" + encodeURIComponent(product.name)+ "?name="+ product.id}>
                                    {product.name}
                                </Link>
                            </Table.Cell>
                        </Table.Row>
                    )
                }
            }
        }

        return computer ? <Table unstackable>
            <Table.Header>
                <Table.Row className={"glw-header-table-row"}>
                    <Table.HeaderCell>{i18n.t('wire_end.cross_section')}</Table.HeaderCell>
                    <Table.HeaderCell>{i18n.t('wire_end.length')}</Table.HeaderCell>
                    <Table.HeaderCell>{i18n.t('wire_end.color')}</Table.HeaderCell>
                    <Table.HeaderCell>{i18n.t('wire_end.type_number')}</Table.HeaderCell>
                    <Table.HeaderCell>{i18n.t('products.dies_description')}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {showProducts}
            </Table.Body>
        </Table> : <Table unstackable>
            <Table.Header>
                <Table.Row className={"glw-header-table-row"}>
                    <Table.HeaderCell>{i18n.t('wire_end.cross_section')}</Table.HeaderCell>
                    <Table.HeaderCell>{i18n.t('wire_end.length')}</Table.HeaderCell>
                    <Table.HeaderCell>{i18n.t('wire_end.color')}</Table.HeaderCell>
                    <Table.HeaderCell>{i18n.t('wire_end.type_number')}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {showProducts}
            </Table.Body>
        </Table>

    }

    addSortiToBasket(sortibox, variationIndex) {
        this.props.stores.basketStore.addItemToBasket({
            id: sortibox.id,
            name: sortibox.name,
            itemNumber: sortibox.variations[variationIndex].itemNumber
        })
    }

    isSortiInBasket(sortibox, variationIndex) {
        for (let i = 0; i < this.props.stores.basketStore.basket.length; i++) {
            if (this.props.stores.basketStore.basket[i].itemNumber === sortibox.variations[variationIndex].itemNumber) {
                return true;
            }
        }
        return false;
    }

    getAllSortiboxes(language) {
        let insulatedTableBoxes = [];
        let uninsulatedTableBoxes = [];
        let emptyTableBoxes = [];
        let twinTableBoxes = [];

        let allTabeles = [];

        for (let i = 0; i < this.state.products.length; i++) {
            let product = this.state.products[i];
            if (product.productTypeId === productType3Id &&
                product.type === "Sortibox") {
                if (product.sortiType.includes("unisolierten")) {
                    uninsulatedTableBoxes.push(product);
                } else if (product.sortiType.includes("isolierten")) {
                    insulatedTableBoxes.push(product);
                } else if (product.sortiType.includes("Leer")) {
                    emptyTableBoxes.push(product);
                } else if (product.sortiType.includes("twin")) {
                    twinTableBoxes.push(product);
                }
            }
        }

        if (insulatedTableBoxes.length > 0) {
            let insulatedRows = [];

            for (let i = 0; i < insulatedTableBoxes.length; i++) {
                let versions = [];
                for (let j = 0; j < insulatedTableBoxes[i].versions.length; j++) {
                    let version = insulatedTableBoxes[i].versions[j];
                    let fillAmounts = [];
                    let fillSizes = [];

                    let versionColors = [];
                    let versionItemNums = [];

                    for (let p = 0; p < version.fills.length; p++) {
                        fillAmounts.push(<p>{version.fills[p].amount}</p>);
                        fillSizes.push(<p>{version.fills[p].size}</p>);
                    }
                    for (let p = 0; p < version.variations.length; p++) {
                        versionColors.push(<p>{version.variations[p].color}</p>);
                        versionItemNums.push(
                            <p>
                                {version.variations[p].itemNumber}
                                <Popup content={i18n.t('products.basket_add')} trigger={
                                    <Icon
                                        onClick={() => this.addSortiToBasket(version, p)}
                                        name={"star"}
                                        color={this.isSortiInBasket(version, p) ? "red" : "grey"}
                                        style={{marginLeft: "5px"}}
                                    />
                                }/>
                            </p>);
                    }

                    versions.push(
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <h3>{version.name}</h3>
                            </Grid.Column>
                            <Grid.Column width={2} floated={"right"} verticalAlign={"middle"}>
                                {fillAmounts}
                            </Grid.Column>
                            <Grid.Column width={3} verticalAlign={"middle"}>
                                {fillSizes}
                            </Grid.Column>
                            <Grid.Column width={2} verticalAlign={"middle"}>
                                {versionColors}
                            </Grid.Column>
                            <Grid.Column width={3} verticalAlign={"middle"}>
                                {versionItemNums}
                            </Grid.Column>
                        </Grid.Row>);
                }
                insulatedRows.push(<Grid.Row>
                    <Grid.Column width={4}>
                        <Image
                            src={insulatedTableBoxes[i].link}
                            verticalAlign={"middle"}
                            alt={"insulated table box"}
                        />
                        <p className={"sorti-dimensions"}>
                            {language === "en-UK" ?
                                insulatedTableBoxes[i].descriptionEn
                                : insulatedTableBoxes[i].description}
                        </p>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid divided='vertically'>
                            {versions}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>);
            }

            allTabeles.push(<Grid padded className={"sorti-table"} divided='vertically'>
                <Grid.Row className={"sorti-header-table-row"}>
                    <Grid.Column width={5}>
                        {language === "en-UK" ?
                            insulatedTableBoxes[0].sortiTypeEn.toUpperCase()
                            : insulatedTableBoxes[0].sortiType.toUpperCase()}
                    </Grid.Column>
                    <Grid.Column width={9} floated={"right"}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4} floated={"right"}>{i18n.t('wire_end.amount')}</Grid.Column>
                                <Grid.Column width={3}>{i18n.t('wire_end.size')}</Grid.Column>
                                <Grid.Column width={3}>{i18n.t('wire_end.color_row')}</Grid.Column>
                                <Grid.Column width={4}>{i18n.t('wire_end.part_no')}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                {insulatedRows}
            </Grid>)
        }

        if (twinTableBoxes.length > 0) {
            let twinRows = [];

            for (let i = 0; i < twinTableBoxes.length; i++) {
                let versions = [];
                for (let j = 0; j < twinTableBoxes[i].versions.length; j++) {
                    let version = twinTableBoxes[i].versions[j];
                    let fillAmounts = [];
                    let fillSizes = [];

                    let versionColors = [];
                    let versionItemNums = [];

                    for (let p = 0; p < version.fills.length; p++) {
                        fillAmounts.push(<p>{version.fills[p].amount}</p>);
                        fillSizes.push(<p>{version.fills[p].size}</p>);
                    }
                    for (let p = 0; p < version.variations.length; p++) {
                        versionColors.push(<p>{version.variations[p].color}</p>);
                        versionItemNums.push(
                            <p>
                                {version.variations[p].itemNumber}
                                <Popup content={i18n.t('products.basket_add')} trigger={
                                    <Icon
                                        onClick={() => this.addSortiToBasket(version, p)}
                                        name={"star"}
                                    />
                                }/>
                            </p>);
                    }

                    versions.push(
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <h3>{version.name}</h3>
                            </Grid.Column>
                            <Grid.Column width={2} floated={"right"} verticalAlign={"middle"}>
                                {fillAmounts}
                            </Grid.Column>
                            <Grid.Column width={3} verticalAlign={"middle"}>
                                {fillSizes}
                            </Grid.Column>
                            <Grid.Column width={2} verticalAlign={"middle"}>
                                {versionColors}
                            </Grid.Column>
                            <Grid.Column width={3} verticalAlign={"middle"}>
                                {versionItemNums}
                            </Grid.Column>
                        </Grid.Row>);
                }
                twinRows.push(<Grid.Row>
                    <Grid.Column width={4}>
                        <Image
                            src={twinTableBoxes[i].link}
                            verticalAlign={"middle"}
                            alt={"twin table box"}
                        />
                        <p className={"sorti-dimensions"}>
                            {language === "en-UK" ?
                                twinTableBoxes[i].descriptionEn
                                : twinTableBoxes[i].description}
                        </p>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid divided='vertically'>
                            {versions}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>);
            }

            allTabeles.push(<Grid padded className={"sorti-table"} divided='vertically'>
                <Grid.Row className={"sorti-header-table-row"}>
                    <Grid.Column width={5}>
                        {language === "en-UK" ?
                            twinTableBoxes[0].sortiTypeEn.toUpperCase()
                            : twinTableBoxes[0].sortiType.toUpperCase()}
                    </Grid.Column>
                    <Grid.Column width={9} floated={"right"}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4} floated={"right"}>{i18n.t('wire_end.amount')}</Grid.Column>
                                <Grid.Column width={3}>{i18n.t('wire_end.size')}</Grid.Column>
                                <Grid.Column width={3}>{i18n.t('wire_end.color_row')}</Grid.Column>
                                <Grid.Column width={4}>{i18n.t('wire_end.part_no')}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                {twinRows}
            </Grid>)
        }

        if (uninsulatedTableBoxes.length > 0) {
            let uninsulatedRows = [];

            for (let i = 0; i < uninsulatedTableBoxes.length; i++) {
                let versions = [];
                for (let j = 0; j < uninsulatedTableBoxes[i].versions.length; j++) {
                    let version = uninsulatedTableBoxes[i].versions[j];
                    let fillAmounts = [];
                    let fillSizes = [];

                    let versionItemNums = [];

                    for (let p = 0; p < version.fills.length; p++) {
                        fillAmounts.push(<p>{version.fills[p].amount}</p>);
                        fillSizes.push(<p>{version.fills[p].size}</p>);
                    }
                    for (let p = 0; p < version.variations.length; p++) {
                        versionItemNums.push(
                            <p>
                                {version.variations[p].itemNumber}
                                <Popup content={i18n.t('products.basket_add')} trigger={
                                    <Icon
                                        onClick={() => this.addSortiToBasket(version, p)}
                                        name={"star"}
                                    />
                                }/>
                            </p>);
                    }

                    versions.push(
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <h3>{version.name}</h3>
                            </Grid.Column>
                            <Grid.Column width={2} floated={"right"} verticalAlign={"middle"}>
                                {fillAmounts}
                            </Grid.Column>
                            <Grid.Column width={3} verticalAlign={"middle"}>
                                {fillSizes}
                            </Grid.Column>
                            <Grid.Column width={2} verticalAlign={"middle"}/>
                            <Grid.Column width={3} verticalAlign={"middle"}>
                                {versionItemNums}
                            </Grid.Column>
                        </Grid.Row>);
                }
                uninsulatedRows.push(<Grid.Row>
                    <Grid.Column width={4}>
                        <Image
                            src={uninsulatedTableBoxes[i].link}
                            verticalAlign={"middle"}
                            alt={"uninsulated table box"}
                        />
                        <p className={"sorti-dimensions"}>
                            {language === "en-UK" ?
                                uninsulatedTableBoxes[i].descriptionEn
                                : uninsulatedTableBoxes[i].description}
                        </p>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid divided='vertically'>
                            {versions}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>);
            }

            allTabeles.push(<Grid padded className={"sorti-table"} divided='vertically'>
                <Grid.Row className={"sorti-header-table-row"}>
                    <Grid.Column width={5}>
                        {language === "en-UK" ?
                            uninsulatedTableBoxes[0].sortiTypeEn.toUpperCase()
                            : uninsulatedTableBoxes[0].sortiType.toUpperCase()}
                    </Grid.Column>
                    <Grid.Column width={9} floated={"right"}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4} floated={"right"}>{i18n.t('wire_end.amount')}</Grid.Column>
                                <Grid.Column width={3}>{i18n.t('wire_end.size')}</Grid.Column>
                                <Grid.Column width={3}/>
                                <Grid.Column width={4}>{i18n.t('wire_end.part_no')}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                {uninsulatedRows}
            </Grid>)

        }
        if (emptyTableBoxes.length > 0) {

            let emptyRows = [];
            for (let i = 0; i < emptyTableBoxes.length; i++) {
                let versions = [];
                for (let j = 0; j < emptyTableBoxes[i].versions.length; j++) {
                    let version = emptyTableBoxes[i].versions[j];

                    let versionItemNums = [];
                    let versionColors = [];

                    for (let p = 0; p < version.variations.length; p++) {
                        versionColors.push(this.getEmptyColor(version.variations[p].color));
                        versionItemNums.push(
                            <p className={"sorti-empy-color-container"}>
                                {version.variations[p].itemNumber}
                                <Popup content={i18n.t('products.basket_add')} trigger={
                                    <Icon
                                        onClick={() => this.addSortiToBasket(version, p)}
                                        name={"star"}
                                    />
                                }/>
                            </p>);
                    }

                    versions.push(
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <h3>{version.name}</h3>
                            </Grid.Column>
                            <Grid.Column width={2} verticalAlign={"middle"} floated={"right"}>
                                {versionColors}
                            </Grid.Column>
                            <Grid.Column width={3} verticalAlign={"middle"}>
                                {versionItemNums}
                            </Grid.Column>
                        </Grid.Row>);
                }
                emptyRows.push(<Grid.Row>
                    <Grid.Column width={4}>
                        <Image
                            style={{paddingBottom: "30px"}}
                            src={emptyTableBoxes[i].link}
                            verticalAlign={"middle"}
                            alt={"empty table box"}
                        />
                        <p className={"sorti-dimensions"}>
                            {language === "en-UK" ?
                                emptyTableBoxes[i].descriptionEn
                                : emptyTableBoxes[i].description}
                        </p>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Grid divided='vertically'>
                            {versions}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>);
            }

            allTabeles.push(<Grid padded className={"sorti-table"} divided='vertically'>
                <Grid.Row className={"sorti-header-table-row"}>
                    <Grid.Column width={5}>
                        {language === "en-UK" ?
                            emptyTableBoxes[0].sortiTypeEn.toUpperCase()
                            : emptyTableBoxes[0].sortiType.toUpperCase()}
                    </Grid.Column>
                    <Grid.Column width={9} floated={"right"}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4} floated={"right"}>{i18n.t('wire_end.color')}</Grid.Column>
                                <Grid.Column width={4}>{i18n.t('wire_end.part_no')}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                {emptyRows}
            </Grid>)
        }

        return allTabeles;
    }

    toGermanFloat(float) {
        return float.toString().replace(".", ",");
    }

    getAllHandTools() {
        let showProducts = [];

        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        let tables = [];

        //filter products
        for (let i = 0; i < this.state.products.length; i++) {
            let product = this.state.products[i];
            if (product.productTypeId === this.state.activeProductType && product.isHandTool) {
                showProducts.push(product);
            }
        }

        //sort products small to big
        showProducts = showProducts.sort(function compare(a, b) {
            let sizeStrValueA = a.name.substring(a.name.lastIndexOf(" "));
            let sizeValueA = parseInt(sizeStrValueA) || 0;
            let sizeStrValueB = b.name.substring(b.name.lastIndexOf(" "));
            let sizeValueB = parseInt(sizeStrValueB) || 0;
            if (sizeValueA < sizeValueB) {
                return -1;
            }
            if (sizeValueB < sizeValueA) {
                return 1;
            }
            return 0;
        });

        //get tex spec stuff
        let tecSpecTypes = this.getLanguageHandSpecs(showProducts, language);

        for (let i = 0; i < showProducts.length; i++) {
            let product = showProducts[i];

            tables.push(<Grid.Column
                computer={4} tablet={5} mobile={8}
                stretched
                verticalAlign={"bottom"} key={i}
                style={{"paddingTop": "5px"}}>
                <div className="ui fluid image"
                     onClick={() => history.push("/product-details/" + encodeURIComponent(product.name.replace("/", "||")))}>
                    <img src={product.link} alt={'Product-Image'} style={{aspectRatio:"1/1", objectFit:"cover"}}
                         onClick={() => history.push("/product-details/" + encodeURIComponent(product.name.replace("/", "||")))}/>
                    <div style={{position: 'absolute', bottom: 0, width: '100%', height: '100%'}}
                         className={"image-overlay"}/>
                </div>
                {this.getTypeDescriptions(tecSpecTypes, product, language)}
                <Button className={"glw-button hand-tool-button"}
                        fluid
                        onClick={() => history.push("/product-details/" + encodeURIComponent(product.name.replace("/", "||")))}>
                    <Link
                        className={"hand-tools-link"}
                        to={"/product-details/" + encodeURIComponent(product.name.replace("/", "||"))}>
                        Details
                    </Link>
                </Button>
            </Grid.Column>)
        }
        return tables;
    }

    getTypeDescriptions(types, product, language) {
        let showTypes = [];

        showTypes.push(<p>{language === "en-UK" ? product.nameEn : product.name}</p>);
        let showSpecs = toJS(product.handToolSpecs);
        if (showSpecs.length > 1) {
            showSpecs = showSpecs.sort(function (a, b) {
                if (a.description.match(/^\d/) && b.description.match(/^\d/)) {
                    return 0
                }
                if (a.description.match(/^\d/)) {
                    return -1;
                }
                return 1;
            });
        }

        for (let i = 0; i < showSpecs.length; i++) {
            if (showSpecs[i].language === language) {
                showTypes.push(<p>{showSpecs[i].description}</p>)
            }
            /*  let handToolDec = this.getHandToolDecs(types[i], product.handToolSpecs, language);
              if (handToolDec === ".") {
                  showTypes.push(<p className={"empty-handtool-spec"}>{handToolDec}</p>)
              } else {
                  showTypes.push(<p>{handToolDec}</p>)
              }*/
        }

        return showTypes;
    }

    getEmptyColor(color) {
        switch (color) {
            case "AT":
                return <div className={"sorti-empy-color-container"}>
                    <div className={"sorti-empty-color"}
                         style={{background: "#0f0f10"}}>{color}</div>
                </div>;
            case "BU":
                return <div className={"sorti-empy-color-container"}>
                    <div className={"sorti-empty-color"}
                         style={{background: "blue"}}>{color}</div>
                </div>;
            case "DGY":
                return <div className={"sorti-empy-color-container"}>
                    <div className={"sorti-empty-color"}
                         style={{background: "grey"}}>{color}</div>
                </div>;
            case "GY":
                return <div className={"sorti-empy-color-container"}>
                    <div className={"sorti-empty-color"}
                         style={{background: "lightgrey"}}>{color}</div>
                </div>;
            case "YE":
                return <div className={"sorti-empy-color-container"}>
                    <div className={"sorti-empty-color"}
                         style={{background: "yellow"}}>{color}</div>
                </div>;
            case "RD":
                return <div className={"sorti-empy-color-container"}>
                    <div className={"sorti-empty-color"}
                         style={{background: "red"}}>{color}</div>
                </div>;
            case "OG":
                return <div className={"sorti-empy-color-container"}>
                    <div className={"sorti-empty-color"}
                         style={{background: "orange"}}>{color}</div>
                </div>;
            case "GN":
                return <div className={"sorti-empy-color-container"}>
                    <div className={"sorti-empty-color"}
                         style={{background: "green"}}>{color}</div>
                </div>;
            default:
                return <p>{color}</p>;
        }
    }

    getLanguageHandSpecs(products, language) {
        let types = [];
        for (let j = 0; j < products.length; j++) {
            let product = products[j];
            for (let i = 0; i < product.handToolSpecs.length; i++) {
                if (!types.includes(product.handToolSpecs[i].type) && product.handToolSpecs[i].language === language)
                    types.push(product.handToolSpecs[i].type)
            }
        }

        return types;
    }

    isOptionSelected(product) {

        if (!product.hasOwnProperty("length")) {
            return false;
        }

        if (this.chosenColorValue.length !== 0) {
            let testValue = product.colorId;
            //for filtering both blues are the same
            if (testValue === 20) {
                testValue = 5;
            }
            if (product.colorId === null || testValue.toString() !== this.chosenColorValue) {
                return false;
            }
        }

        if (this.chosenLengthValue > 0) {
            if (product.length !== this.chosenLengthValue) {
                return false;
            }
        }

        if (this.chosenCrossSectionValue > 0) {
            if (product.crossSection !== this.chosenCrossSectionValue) {
                return false;
            }
        }

        //Check sleeve types
        if (this.sleeveType >= 0) {
            return sleeveValues[this.sleeveType].aliases.some(
                function (v) {
                    return product.type.toLowerCase() === v.toLowerCase();
                });

        } else {
            return true;
        }
    }

    isDiesOptionSelected(product) {
        //Check sleeve types
        console.log("dies Type in is opsion selected: " + this.diesType)
        if (this.diesType >= 0) {
            let nameArray = product.name.split(" ");
            if (nameArray.length > 0) {
                let lastItem = nameArray[nameArray.length - 1];
                if (lastItem.startsWith("TWIN") && nameArray.length >= 2) {
                    lastItem = nameArray[nameArray.length - 2];
                }
                if (this.diesType === 1 && product.name === "Crimpgesenk EC T100QI") {
                    return true;
                }
                if (lastItem.startsWith(diesValues[this.diesType].startsWith)) {
                    return true;
                }
            }
            return false;
        } else {
            return true;
        }
    }

    getSleevesOptions(language) {
        //extract options when wire end sleeves are selected
        if (this.state.products.length > 0) {

            let sortedAfterLength = this.state.products.sort(function (a, b) {
                if (a.sleeveType === SORTI_TYPE || b.sleeveType === SORTI_TYPE) {
                    return 0;
                }
                if (a.length < b.length) {
                    return -1;
                }
                if (b.length < a.length) {
                    return 1;
                }
                return 0;
            });

            for (let i = 0; i < sortedAfterLength.length; i++) {
                let product = sortedAfterLength[i];
                if (product.productTypeId === productType3Id) {
                    if (product.hasOwnProperty("color") && product.hasOwnProperty("length")) {
                        this.addLengthOption(product.length);
                        this.addColorOption(language === "en-UK" ? product.colorEn : product.color, product.colorId);
                    }
                }
            }

            let sortedCrossSection = this.state.products.sort(function (a, b) {
                if (a.sleeveType === SORTI_TYPE || b.sleeveType === SORTI_TYPE) {
                    return 0;
                }
                if (a.crossSection < b.crossSection) {
                    return -1;
                }
                if (b.crossSection < a.crossSection) {
                    return 1;
                }
                return 0;
            });

            for (let i = 0; i < sortedCrossSection.length; i++) {
                let product = sortedCrossSection[i];
                if (product.productTypeId === productType3Id) {
                    this.addCrossSectionOption(product.crossSection);
                }
            }
        }

    }

    addCrossSectionOption(value) {
        let isIn = false;
        for (let i = 0; i < this.crossSectionOptions.length; i++) {
            if (this.crossSectionOptions[i].value === value) {
                isIn = true;
                break;
            }
        }
        if (!isIn) {
            this.crossSectionOptions.push({
                text: value,
                value: value
            })
        }
        // return this.crossSectionOptions;
    }

    addLengthOption(value) {
        let isIn = false;
        for (let i = 0; i < this.lengthOptions.length; i++) {
            if (this.lengthOptions[i].value === value) {
                isIn = true;
                break;
            }
        }
        if (!isIn) {
            this.lengthOptions.push({
                text: value,
                value: value
            })
        }
    }

    addColorOption(text, value) {
        if (typeof value === "undefined" || value === null) {
            return;
        }

        let isIn = false;
        for (let i = 0; i < this.colorOptions.length; i++) {
            //value 5 & 20 are the same for filtering
            let testValue = this.colorOptions[i].value === 20 ? 5 : this.colorOptions[i].value;

            if (testValue === value.toString()) {
                isIn = true;
                break;
            }
        }
        if (!isIn) {
            this.colorOptions.push({
                text: text,
                value: value.toString()
            })
        }
    }

    handleInputChange(type, event, data) {
        switch (type) {
            case 'crossSectionOptions':
                this.chosenCrossSectionValue = data.value;
                break;
            case 'lengthOptions':
                this.chosenLengthValue = data.value;
                break;
            case 'colorOptions':
                this.chosenColorValue = data.value;
                break;
        }
        this.setWireEndSearch()
    }

    resetWireEndOptions() {
        this.chosenCrossSectionValue = "";
        this.chosenLengthValue = "";
        this.chosenColorValue = "";
        this.diesGB = false;
        this.diesXL = false;
        this.diesSearch = "";
        this.setState({});
        this.setWireEndSearch()
    }

    handleSleeveTypeChange(value) {
        this.sleeveType = value;
        this.setWireEndSearch()
    }

    handleDiesTypeChange(value) {
        /*  if (value === this.diesType) {
              this.props.history.push({
                  search: ''
              })
          } else {*/
        console.log(value);
        this.props.history.push({
            search: '?diestype=' + value
        });
        //  }
    }


    setWireEndSearch() {
        let search = "";

        if (this.chosenColorValue !== "") {
            search = "color=" + this.chosenColorValue
        }

        if (this.chosenLengthValue > 0) {
            if (search.length > 0) {
                search += "&";
            }
            search += "length=" + this.chosenLengthValue
        }

        if (this.chosenCrossSectionValue > 0) {
            if (search.length > 0) {
                search += "&";
            }
            search += "cross=" + this.chosenCrossSectionValue
        }

        if (this.diesXL) {
            if (search.length > 0) {
                search += "&";
            }
            search += "xl=" + this.diesXL
        }
        if (this.diesGB) {
            if (search.length > 0) {
                search += "&";
            }
            search += "gb=" + this.diesGB
        }
        /*if (this.diesSearch.length > 0) {
            if (search.length > 0) {
                search += "&";
            }
            search += "search=" + this.diesSearch
        }*/


        if (this.sleeveType >= 0) {
            if (search.length > 0) {
                search += "&";
            }
            search += "type=" + this.sleeveType
        }

        if (search.length > 0) {
            search = "?" + search;
        }

        this.props.history.push({
            search: search
        });
        this.setState({})

    }

    getFilteredWireEndSleeveProducts() {
        let filteredProducts = [];

        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        //filter products
        for (let i = 0; i < this.state.products.length; i++) {
            let product = this.state.products[i];
            if (product.productTypeId === this.state.activeProductType &&
                this.state.activeProductType === productType3Id &&
                this.isOptionSelected(product, language) &&
                !product.isHandTool
            ) {
                filteredProducts.push(product);
            }
        }
        return filteredProducts;
    }

    getFilteredLengthOptions() {
        if (this.state.activeProductType !== productType3Id) {
            return [];
        }
        let filteredProducts = this.getFilteredWireEndSleeveProducts();
        filteredProducts = filteredProducts.sort(function (a, b) {
            if (a.length < b.length) {
                return -1;
            }
            if (b.length < a.length) {
                return 1;
            }
            return 0;
        });
        let lengthOptions = [];

        for (let j = 0; j < filteredProducts.length; j++) {
            let product = filteredProducts[j];
            let isIn = false;
            for (let i = 0; i < lengthOptions.length; i++) {
                if (lengthOptions[i].value === product.length) {
                    isIn = true;
                    break;
                }
            }
            if (!isIn) {
                lengthOptions.push({
                    text: product.length,
                    value: product.length
                })
            }
        }

        return lengthOptions;
    }

    getFilteredCrossOptions() {
        if (this.state.activeProductType !== productType3Id) {
            return [];
        }
        let filteredProducts = this.getFilteredWireEndSleeveProducts();
        filteredProducts = filteredProducts.sort(function (a, b) {
            if (a.crossSection < b.crossSection) {
                return -1;
            }
            if (b.crossSection < a.crossSection) {
                return 1;
            }
            return 0;
        });
        let crossOption = [];

        for (let j = 0; j < filteredProducts.length; j++) {
            let product = filteredProducts[j];
            let isIn = false;
            for (let i = 0; i < crossOption.length; i++) {
                if (crossOption[i].value === product.crossSection) {
                    isIn = true;
                    break;
                }
            }
            if (!isIn) {
                crossOption.push({
                    text: product.crossSection,
                    value: product.crossSection
                })
            }
        }

        return crossOption;
    }

    getFilteredColorOptions(language) {
        if (this.state.activeProductType !== productType3Id) {
            return [];
        }
        let filteredProducts = this.getFilteredWireEndSleeveProducts();

        let colorOption = [];

        for (let j = 0; j < filteredProducts.length; j++) {
            let product = filteredProducts[j];
            let isIn = false;
            if (product.colorId === null) {
                continue;
            }


            //value 5 & 20 are the same for filtering
            let testValue = product.colorId === 20 ? 5 : product.colorId;

            for (let i = 0; i < colorOption.length; i++) {
                if (colorOption[i].value === testValue.toString()) {
                    isIn = true;
                    break;
                }
            }
            if (!isIn) {
                colorOption.push({
                    text: language === "en-UK" ? product.colorEn : product.color,
                    value: testValue.toString()
                })
            }
        }
        return colorOption;
    }

    render() {
        let showProducts = this.showHandTools ?
            this.getAllHandTools()
            : (this.state.activeProductType === productType3Id ?
                this.getFilteredWireEndSleeves()
                :
                this.getAllFilteredProducts());

        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        let filteredLengthOptions = this.getFilteredLengthOptions();
        let filteredCrossOptions = this.getFilteredCrossOptions();
        let filteredColorOptions = this.getFilteredColorOptions(language);

        return (
            <Grid>
                <EditHeaderComponent id={"products-page"}/>
                <Grid.Row className={"no-top-padding primary-background-container"}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section>
                                <Link
                                    style={{"color": "white"}}
                                    to={"/"}>
                                    HOME
                                </Link>
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>
                                <Link
                                    style={{"color": "white"}}
                                    to={"/product-overview"}>
                                    {i18n.t('titles.products')}
                                </Link>
                            </Breadcrumb.Section>
                            {this.state.activeProductType === productType8Id || this.state.activeProductType === productType9Id ?
                                <Breadcrumb.Divider><Icon name={'left chevron'} inverted/></Breadcrumb.Divider>
                                : null}
                            {this.state.activeProductType === productType8Id || this.state.activeProductType === productType9Id ?
                                <Breadcrumb.Section>
                                    <Link
                                        style={{"color": "white"}}
                                        to={"/products/" + productType7Id + "/0"}>
                                        {i18n.t('products.accessories').toUpperCase()}
                                    </Link>
                                </Breadcrumb.Section>
                                : null}
                            {this.showHandTools ?
                                <Breadcrumb.Divider><Icon name={'left chevron'} inverted/></Breadcrumb.Divider>
                                : null}
                            {this.showHandTools ?
                                <Breadcrumb.Section>
                                    {i18n.t('products.hand_tools').toUpperCase()}
                                </Breadcrumb.Section>
                                : null}
                        </Responsive>
                        <h1 className={"top-row-text"}>{this.getActiveProductTypeName()}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Responsive as={Grid.Row} maxWidth={767}
                            className={!this.showSubMenu ? "hide-div" : "no-top-padding sub-menu-container"}
                            style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productType3Id + "/0");
                            this.showSubMenu = false;
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productType3Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productType3Id ?
                                "https://www.glw.de/images/Produkte_Aderendhuelse_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Aderendhuelse_passiv.png"}
                            alt={"wireend item"}
                        />
                        <Link
                            to={"/products/" + productType3Id + "/0"}>
                            {i18n.t('products.wire_end_sleeves').toUpperCase()}
                        </Link>
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productType2Id + "/0");
                            this.showSubMenu = false;
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productType2Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productType2Id ?
                                "https://www.glw.de/images/Produkte_Ablaengen_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Ablaengen_passiv.png"}
                            alt={"sub item"}
                        />
                        <Link
                            to={"/products/" + productType2Id + "/0"}>
                            {i18n.t('products.cutting').toUpperCase()}
                        </Link>
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productTypeId + "/0");
                            this.showSubMenu = false;
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productTypeId ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productTypeId ?
                                "https://www.glw.de/images/Produkte_Crimpen_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Crimpen_passiv.png"}
                            alt={"sub item"}
                        />
                        <Link
                            to={"/products/" + productTypeId + "/0"}>
                            {i18n.t('products.crimping').toUpperCase()}
                        </Link>
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productType4Id + "/0");
                            this.showSubMenu = false;
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productType4Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productType4Id ?
                                "https://www.glw.de/images/Produkte_Abisolieren_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Abisolieren_passiv.png"}
                            alt={"sub item"}
                        />
                        <Link
                            to={"/products/" + productType4Id + "/0"}>
                            {i18n.t('products.wire_stripper').toUpperCase()}
                        </Link>
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productType7Id + "/0");
                            this.showSubMenu = false;
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productType7Id ||
                        this.state.activeProductType === productType8Id ||
                        this.state.activeProductType === productType9Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productType7Id ||
                            this.state.activeProductType === productType8Id ||
                            this.state.activeProductType === productType9Id ?
                                "https://www.glw.de/images/Produkte_Zubehoer_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Zubehoer_passiv.png"}
                            alt={"sub item"}
                        />
                        <Link
                            to={"/products/" + productType7Id + "/0"}>
                            {i18n.t('products.accessories').toUpperCase()}
                        </Link>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} maxWidth={767} centered className={"no-top-padding sub-menu-container"}>
                    <Grid.Column width={3} style={{"padding": "15px"}}
                                 textAlign={"center"}>
                        <Icon name={this.showSubMenu ? "angle double up" : "angle double down"}
                              size={"big"}
                              onClick={() => this.showSubMenu = !this.showSubMenu}/>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} minWidth={768}
                            className={"no-top-padding sub-menu-container"}
                            style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productType3Id + "/0")
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productType3Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productType3Id ?
                                "https://www.glw.de/images/Produkte_Aderendhuelse_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Aderendhuelse_passiv.png"}
                            alt={"sub item"}
                        />
                        <Link
                            to={"/products/" + productType3Id + "/0"}>
                            {i18n.t('products.wire_end_sleeves').toUpperCase()}
                        </Link>
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productType2Id + "/0")
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productType2Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productType2Id ?
                                "https://www.glw.de/images/Produkte_Ablaengen_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Ablaengen_passiv.png"}
                            alt={"sub item"}
                        />
                        <Link
                            to={"/products/" + productType2Id + "/0"}>
                            {i18n.t('products.cutting').toUpperCase()}
                        </Link>
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productTypeId + "/0")
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productTypeId ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productTypeId ?
                                "https://www.glw.de/images/Produkte_Crimpen_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Crimpen_passiv.png"}
                            alt={"sub item"}
                        />
                        <Link
                            to={"/products/" + productTypeId + "/0"}>
                            {i18n.t('products.crimping').toUpperCase()}
                        </Link>
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productType4Id + "/0")
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productType4Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productType4Id ?
                                "https://www.glw.de/images/Produkte_Abisolieren_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Abisolieren_passiv.png"}
                            alt={"sub item"}
                        />
                        <Link
                            to={"/products/" + productType4Id + "/0"}>
                            {i18n.t('products.wire_stripper').toUpperCase()}
                        </Link>
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={5}
                        onClick={() => {
                            history.push("/products/" + productType7Id + "/0")
                        }}
                        textAlign={"center"}
                        className={this.state.activeProductType === productType7Id ||
                        this.state.activeProductType === productType8Id ||
                        this.state.activeProductType === productType9Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.activeProductType === productType7Id ||
                            this.state.activeProductType === productType8Id ||
                            this.state.activeProductType === productType9Id ?
                                "https://www.glw.de/images/Produkte_Zubehoer_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Zubehoer_passiv.png"}
                            alt={"sub item"}
                        />
                        <Link
                            to={"/products/" + productType7Id + "/0"}>
                            {i18n.t('products.accessories').toUpperCase()}
                        </Link>
                    </Grid.Column>
                </Responsive>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"products-container"}>
                        {this.state.activeProductType === productType3Id && !this.showHandTools ?
                            <Grid padded>
                                <Grid.Row centered>
                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 textAlign={"center"}
                                                 className={this.sleeveType === 0 ? "light-border" : ""}
                                                 onClick={() => {
                                                     this.handleSleeveTypeChange(0);
                                                     this.sleeveType = 0
                                                 }}>
                                        <Image
                                            className={"sub-menu-item-image clicky-thingy"}
                                            src={this.sleeveType === 0 ?
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_isoliert_aktiv.png"
                                                :
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_isoliert_passiv.png"}
                                            size={"small"}
                                            alt={"sub item"}
                                        />
                                        <label
                                            className={this.sleeveType === 0 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                                            {language === "en-UK" ? sleeveValuesEn[0].title.toUpperCase() : sleeveValues[0].title.toUpperCase()}
                                        </label>
                                    </Grid.Column>
                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 textAlign={"center"}
                                                 className={this.sleeveType === 1 ? "light-border" : ""}
                                                 onClick={() => {
                                                     this.handleSleeveTypeChange(1);
                                                     this.sleeveType = 1
                                                 }}>
                                        <Image
                                            className={"sub-menu-item-image clicky-thingy"}
                                            src={this.sleeveType === 1 ?
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_isoliert-twin_aktiv.png"
                                                :
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_isoliert-twin_passiv.png"}
                                            size={"small"}
                                            alt={"sub item"}
                                        />
                                        <label
                                            className={this.sleeveType === 1 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                                            {language === "en-UK" ? sleeveValuesEn[1].title.toUpperCase() : sleeveValues[1].title.toUpperCase()}
                                        </label>
                                    </Grid.Column>

                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 textAlign={"center"}
                                                 className={this.sleeveType === 2 ? "light-border" : ""}
                                                 onClick={() => {
                                                     this.handleSleeveTypeChange(2);
                                                     this.sleeveType = 2
                                                 }}>
                                        <Image
                                            className={"sub-menu-item-image clicky-thingy"}
                                            src={this.sleeveType === 2 ?
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_unisoliert_aktiv.png"
                                                :
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_unsortiert_passiv.png"}
                                            size={"small"}
                                            alt={"sub item"}
                                        />
                                        <label
                                            className={this.sleeveType === 2 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                                            {language === "en-UK" ? sleeveValuesEn[2].title.toUpperCase() : sleeveValues[2].title.toUpperCase()}
                                        </label>
                                    </Grid.Column>

                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 textAlign={"center"}
                                                 className={this.sleeveType === 3 ? "light-border" : ""}
                                                 onClick={() => {
                                                     this.handleSleeveTypeChange(3);
                                                     this.sleeveType = 3
                                                 }}>
                                        <Image
                                            className={"sub-menu-item-image clicky-thingy"}
                                            src={this.sleeveType === 3 ?
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_Bandform_aktiv.png"
                                                :
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_Bandform_passiv.png"}
                                            size={"small"}
                                            alt={"sub item"}
                                        />
                                        <label
                                            className={this.sleeveType === 3 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                                            {language === "en-UK" ? sleeveValuesEn[3].title.toUpperCase() : sleeveValues[3].title.toUpperCase()}
                                        </label>
                                    </Grid.Column>
                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 textAlign={"center"}
                                                 className={this.sleeveType === 4 ? "light-border" : ""}
                                                 onClick={() => {
                                                     this.handleSleeveTypeChange(4);
                                                     this.sleeveType = 4
                                                 }}>
                                        <Image
                                            className={"sub-menu-item-image clicky-thingy"}
                                            src={this.sleeveType === 4 ?
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_Sortibox_aktiv.png"
                                                :
                                                "https://www.glw.de/images/Produkte_Aderendhuelse_Sortibox_passiv.png"}
                                            size={"small"}
                                            alt={"sub item"}
                                        />
                                        <label
                                            className={this.sleeveType === 4 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                                            {language === "en-UK" ? sleeveValuesEn[4].title.toUpperCase() : sleeveValues[4].title.toUpperCase()}
                                        </label>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className={this.sleeveType === 4 ? "hide-div" : "no-top-padding"}>
                                    <Grid.Column>
                                        <Form>
                                            <Form.Group>
                                                <Form.Field width={4}>
                                                    <label>{language === "en-UK" ? CROSS_SECTION_TYPE_EN : CROSS_SECTION_TYPE}</label>
                                                    <Dropdown clearable options={filteredCrossOptions} selection
                                                              value={this.chosenCrossSectionValue}
                                                              className={this.chosenCrossSectionValue.length === 0 ? "" : "delete-icon-padding"}
                                                              onClick={() => this.chosenCrossSectionValue = ""}
                                                              onChange={this.handleInputChange.bind(this, 'crossSectionOptions')}
                                                              icon={this.chosenCrossSectionValue.length === 0 ? "dropdown" : "delete"}/>
                                                </Form.Field>
                                                <Form.Field width={4}>
                                                    <label>{language === "en-UK" ? LENGTH_TYPE_EN : LENGTH_TYPE}</label>
                                                    <Dropdown clearable options={filteredLengthOptions} selection
                                                              value={this.chosenLengthValue}
                                                              className={this.chosenLengthValue.length === 0 ? "" : "delete-icon-padding"}
                                                              onClick={() => this.chosenLengthValue = ""}
                                                              onChange={this.handleInputChange.bind(this, 'lengthOptions')}
                                                              icon={this.chosenLengthValue.length === 0 ? "dropdown" : "delete"}/>
                                                </Form.Field>
                                                <Form.Field width={4}>
                                                    <label>{language === "en-UK" ? COLOR_TYPE_EN : COLOR_TYPE}</label>
                                                    <Dropdown clearable options={filteredColorOptions} selection
                                                              value={this.chosenColorValue}
                                                              className={this.chosenColorValue.length === 0 ? "" : "delete-icon-padding"}
                                                              onClick={() => this.chosenColorValue = ""}
                                                              onChange={this.handleInputChange.bind(this, 'colorOptions')}
                                                              icon={this.chosenColorValue.length === 0 ? "dropdown" : "delete"}/>
                                                </Form.Field>
                                                <Form.Field width={1}/>
                                                <Form.Field width={3}>
                                                    <label style={{color: "white"}}>.</label>
                                                    <Button
                                                        floated='right'
                                                        onClick={() => this.resetWireEndOptions()}
                                                        icon labelPosition='right'>
                                                        {i18n.t('products.reset_options').toUpperCase()}
                                                        <Icon name='undo alternate'/>
                                                    </Button>
                                                </Form.Field>
                                            </Form.Group>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className={this.sleeveType === 4 ? "hide-div" : ""}>
                                    <Grid.Column computer={5} tablet={10} mobile={16}>
                                        <Checkbox
                                            label={i18n.t('products.dies_gb')}
                                            checked={this.diesGB}
                                            onChange={() => {
                                                this.diesGB = !this.diesGB;
                                                this.setWireEndSearch()
                                            }
                                            }/>
                                        <Checkbox
                                            label={i18n.t('products.dies_xl')}
                                            checked={this.diesXL}
                                            onChange={() => {
                                                this.diesXL = !this.diesXL;
                                                this.setWireEndSearch()
                                            }
                                            }/>
                                    </Grid.Column>
                                    <Grid.Column computer={5} tablet={6} mobile={16} className={"search-bar"}>
                                        <Input icon placeholder={i18n.t('search.title')}>
                                            <input
                                                style={{borderRadius: "12.5px"}}
                                                value={this.diesSearch}
                                                onChange={(e) => this.diesSearch = e.target.value}
                                            />
                                            <Icon name='search'/>
                                        </Input>
                                    </Grid.Column>
                                    <Grid.Column width={5} floated={"right"}>
                                        <Button
                                            onClick={() => window.open("https://www.glw.de/UL_Certificate_E485383.pdf", "_blank")}
                                            className={"glw-button"}
                                            floated={"right"}
                                            icon labelPosition='right'>
                                            {i18n.t('products.cla_certificate').toUpperCase()}
                                            <Icon name='download'/>
                                        </Button>
                                        <Button
                                            onClick={() => window.open("https://www.glw.de/downloads/Aderendhuelsen_Prospekt.pdf", "_blank")}
                                            className={"glw-button"}
                                            floated={"right"}
                                            icon labelPosition='right'>
                                            {i18n.t('products.download_adh_info').toUpperCase()}
                                            <Icon name='download'/>
                                        </Button>

                                    </Grid.Column>
                                </Grid.Row>
                                <Divider/>
                            </Grid>
                            :
                            null
                        }
                        {this.state.activeProductType === productType8Id && !this.showHandTools ?
                            <Grid padded>
                                <Grid.Row centered
                                          style={{marginTop: "-1px"}}>
                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 textAlign={"center"}
                                                 className={this.diesType === 0 ? "light-border" : ""}
                                                 onClick={() => {
                                                     this.handleDiesTypeChange(0);
                                                     this.diesType = 0;
                                                 }}>
                                        <Image
                                            className={"gesenk-menu-item-image clicky-thingy"}
                                            src={"https://www.glw.de/images/Produkte_Gesenke2_aktiv.png"
                                            }
                                            size={"small"}
                                        />
                                        <label
                                            className={this.diesType === 0 ? "active-product-type clicky-thingy" : "clicky-thingy"}
                                        >{language === "en-UK" ? diesValuesEn[0].title.toUpperCase() : diesValues[0].title.toUpperCase()}</label>
                                    </Grid.Column>
                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 className={this.diesType === 1 ? "light-border" : ""}
                                                 textAlign={"center"}
                                                 onClick={() => {
                                                     this.handleDiesTypeChange(1);
                                                     this.diesType = 1;
                                                 }}>
                                        <Image
                                            className={"gesenk-menu-item-image clicky-thingy"}
                                            src={"https://www.glw.de/images/Produkte_Gesenke1_aktiv.png"}
                                            size={"small"}
                                            alt={"sub item"}
                                        />
                                        <label
                                            className={this.diesType === 1 ? "active-product-type clicky-thingy" : "clicky-thingy"}
                                        >{language === "en-UK" ? diesValuesEn[1].title.toUpperCase() : diesValues[1].title.toUpperCase()}</label>
                                    </Grid.Column>

                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 className={this.diesType === 2 ? "light-border" : ""}
                                                 textAlign={"center"}
                                                 onClick={() => {
                                                     this.handleDiesTypeChange(2);
                                                     this.diesType = 2;
                                                 }}>
                                        <Image
                                            className={"gesenk-menu-item-image clicky-thingy"}
                                            src={"https://www.glw.de/images/Produkte_Gesenke3_aktiv.png"}
                                            size={"small"}
                                            alt={"sub item"}
                                        />
                                        <label
                                            className={this.diesType === 2 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                                            {language === "en-UK" ? diesValuesEn[2].title.toUpperCase() : diesValues[2].title.toUpperCase()}</label>
                                    </Grid.Column>

                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 className={this.diesType === 3 ? "light-border" : ""}
                                                 textAlign={"center"}
                                                 onClick={() => {
                                                     this.handleDiesTypeChange(3);
                                                     this.diesType = 3;
                                                 }}>
                                        <Image
                                            className={"gesenk-menu-item-image clicky-thingy"}
                                            src={"https://www.glw.de/images/Produkte_Gesenke4_aktiv.png"}
                                            size={"small"}
                                            alt={"sub item"}
                                        />
                                        <label
                                            className={this.diesType === 3 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                                            {language === "en-UK" ? diesValuesEn[3].title.toUpperCase() : diesValues[3].title.toUpperCase()}</label>
                                    </Grid.Column>
                                    <Grid.Column computer={3} tablet={5} mobile={5}
                                                 style={{"paddingBottom": "10px"}}
                                                 className={this.diesType === 4 ? "light-border" : ""}
                                                 textAlign={"center"}
                                                 onClick={() => {
                                                     this.handleDiesTypeChange(4);
                                                     this.diesType = 4;
                                                 }}>
                                        <Image
                                            className={"gesenk-menu-item-image clicky-thingy"}
                                            src={"https://www.glw.de/images/Produkte_Gesenke5_aktiv.png"}
                                            size={"small"}
                                            alt={"sub item"}
                                        />
                                        <label
                                            className={this.diesType === 4 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                                            {language === "en-UK" ? diesValuesEn[4].title.toUpperCase() : diesValues[4].title.toUpperCase()}</label>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            :
                            null
                        }
                        <Grid padded>
                            <Grid.Row centered={!this.showHandTools}>
                                {showProducts}
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}