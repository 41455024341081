import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    getTokenFromLocalStorage,
    getUserFromLocalStorage,
    removeTokenFromStorage,
    setTokenLocalStorage, setUserToLocalStorage
} from "../helper/util";

import history from "../helper/browserHistory";

class UserStore {
    @observable user = null;
    @observable loading = false;
    @observable error = false;

    @computed get userFromServer() {
        if (this.user === null) {
            if (getUserFromLocalStorage() === null) {
                return null;
            }

            return getUserFromLocalStorage();
        }

        return this.user;
    }

    @action authenticateUser(userToAuthenticate) {
        const token = getTokenFromLocalStorage();
        if (userToAuthenticate === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
            this.loading = true;
            return fetch(config.BASE_URL + "repository/authenticate", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify(userToAuthenticate)
            })
                .then(response => {
                    this.loading = false;
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {
                            setTokenLocalStorage(json.token);
                            setUserToLocalStorage(json);
                            this.user = json;
                            history.push("/my-glw");

                        });

                    } else {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.error = true;
                        this.user = null;
                    }
                })
                .catch(
                    error => {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.loading = false;
                        this.error = true;
                        this.user = null;
                        throw error;
                    }
                );
        }
    }
}

const store = new UserStore();

export default store;