import React, {useEffect} from "react";
import EditHTMLComponent from "../moap-components/EditHTMLComponent"
import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import history from "../helper/browserHistory";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import EditEditorComponent from "../moap-components/EditHTMLComponent";
import config from "../../config/main.config"
import JobOffersSubPage from "../components/JobOffersSubPage"

let ContentState = null;
let convertToRaw = null;
let EditorState = null;
let Editor = null;

if (typeof document !== "undefined") {
    // Require scss files
    require('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
    ContentState = require('draft-js').ContentState;
    convertToRaw = require('draft-js').convertToRaw;
    EditorState = require('draft-js').EditorState;
    Editor = require('react-draft-wysiwyg').Editor;
}

const CareerPage = (props) => {
    const {i18n} = props;
    const images = [
        'GLW_JOB-Benefits_24h.jpg',
        'GLW_JOB-Benefits_Extra.jpg',
        'GLW_JOB-Benefits_Fair.jpg',
        'GLW_JOB-Benefits_Feiern.jpg',
        'GLW_JOB-Benefits_Gesundheit.jpg',
        'GLW_JOB-Benefits_Inklusive.jpg',
        'GLW_JOB-Benefits_Karriere.jpg',
        'GLW_JOB-Benefits_Kostet-nix.jpg',
        'GLW_JOB-Benefits_Meins.jpg',
        'GLW_JOB-Benefits_Toll.jpg',
        'GLW_JOB-Benefits_Up-to-date.jpg',
        'GLW_JOB-Benefits_Wow.jpg',
        'GLW_JOB-Benefits_Zentral.jpg',
        'GLW_JOB-Benefits_Zuschuss.jpg',
        'GLW_JOB-Benefits_Beweglich.jpg',
        'GLW_JOB-Benefits_Homeoffice.jpg',
    ]

    useEffect(() => {
        if (i18n.language !== "de-DE") {
            history.push("/")
        }
    }, [i18n.language])

    return (
        <Grid>
            <Grid.Row className={"no-top-padding primary-background-container"}>
                <Grid.Column width={2} only={"computer"}/>
                <Grid.Column computer={14} tablet={16} mobile={16}>
                    <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                        <Breadcrumb.Section
                            onClick={() => history.push("/")}
                            style={{"color": "white"}}>
                            HOME
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider
                            onClick={() => history.push("/")}>
                            <Icon name={'left chevron'} inverted/>
                        </Breadcrumb.Divider>
                        <Breadcrumb.Section>{i18n.t('titles.company')}</Breadcrumb.Section>
                    </Responsive>
                    <h1 className={"top-row-text"}>{i18n.t("titles.career")}</h1>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column width={16} textAlign={"center"}>
                    {/*<EditEditorComponent id={"job-offers-sub-page-text-id"}/>*/}
                    <EditHTMLComponent id={"career-text-1"}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column width={14}>
                    <img src={`${config.BASE_IMAGE_URL}Team_kniend.webp`} alt={"Company-Image"}
                         className={"object-cover w-full"} style={{height: "420px",width:"100%", objectFit: "cover"}}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column width={14}><h1>{i18n.t("company_page.benefits")}</h1>
                    <EditHTMLComponent id={"career-text-2"}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column computer={14} mobile={16} tablet={16}>
                    <div className={"career-grid"}>
                        {images.map((src) =>
                            (<img src={`${config.BASE_IMAGE_URL}benefits/${src}`} alt={src} style={{width: "100%"}}/>)
                        )}
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column width={14}>
                    <p>{i18n.t("misc.apply_text")}</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column width={14}>
                    <JobOffersSubPage/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default withTranslation()(CareerPage);