import React from "react";
import {observer} from "mobx-react";
import {observable, toJS} from "mobx";
import config from "../../config/main.config";
import i18n from "../i18n";
import {withTranslation} from "react-i18next";
import {isAdmin, removeTokenFromStorage, updatePageView} from "../helper/util";
import history from "../helper/browserHistory";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import {Link} from "react-router-dom";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import userStore from "../stores/userStore";

let productType3Id = "a7bf7a85-b595-44a9-a492-4d48d1c395dc";

@withTranslation()
@observer
export default class ProductServicePage extends React.Component {

    @observable
    loading = true;

    @observable
    product = {
        name: "",
        itemNumber: "",
        productType: "",
        productInformations: [],
        serviceVideos: [],
        serviceText: "",
    };

    @observable
    showSubMenu = false;

    @observable
    salesPartner = false;

    @observable
    activeItems = [0];

    componentDidMount() {
        updatePageView(this.props.location.pathname);
        window.scrollTo(0, 0);
        this.fetchData();
        if (userStore.userFromServer != null &&
            userStore.userFromServer.hasOwnProperty("roles") &&
            userStore.userFromServer.roles.includes("SalesPartner")) {
            this.salesPartner = true;
        }
    }

    fetchData() {
        let id = this.props.match.params.id;
        fetch(config.BASE_URL + 'service/' + id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(product => {
                if (product.hasOwnProperty("name")) {
                    this.product = product;
                } else {
                    history.push("/")
                }
                this.loading = false;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getLanguageInfo(language) {
        if (this.product.productTypeId === productType3Id) {
            return <Button
                fluid
                className={"glw-button"}
                onClick={() => this.createInfoPDF(this.product)}>
                {i18n.t('wire_end.info')}
            </Button>
        }
        let languageInfos = [];
        for (let i = 0; i < this.product.productInformations.length; i++) {
            if (language === this.product.productInformations[i].language) {
                languageInfos.push(this.product.productInformations[i])
            }
        }
        if (languageInfos.length === 1) {
            return <Button
                fluid
                className={"glw-button"}
                onClick={() => window.open(languageInfos[0].link)}>
                Downloads
            </Button>
        } else if (languageInfos.length > 1) {
            let showLinks = languageInfos.map((info, index) =>
                <p className={"clicky-thingy"} key={"link" + index}
                   onClick={() => window.open(info.link)}>{info.link.substring(info.link.lastIndexOf("/") + 1)}</p>);
            return <Modal
                trigger={<Button
                    fluid
                    className={"glw-button"}>
                    Downloads</Button>}
                style={{"margin": "auto"}}>
                <Modal.Header>  {i18n.t('products.select_info')}</Modal.Header>
                <Modal.Content>
                    {showLinks}
                </Modal.Content>
            </Modal>
        }
    }

    createInfoPDF(product) {
        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }
        const windowReference = window;

        fetch(config.BASE_URL + 'products/pdf/' + product.id + "/" + language, {
            method: 'GET',
            headers: {
                'Accept': 'application/pdf',
                'Content-Type': 'application/pdf'
            }
        }).then(resp => {
            windowReference.location.href = resp.url;
        });
    }

    toggleAccordionItem(item) {
        if (this.activeItems.includes(item)) {
            this.activeItems.splice(this.activeItems.indexOf(item), 1);
        } else {
            this.activeItems.push(item);
        }
        this.setState({});
    }

    toGermanFloat(float) {
        return float.toString().replace(".", ",");
    }

    getServiceVideos(videos, activeItems, language, t) {
        let accordions = [];
        for (let i = 0; i < videos.length; i++) {
            if (videos[i].salesPartner === 0 || this.salesPartner) {
                accordions.push(<Accordion.Title
                        key={"t" + i}
                        className={this.activeItems.includes(i) ? "opened-accordion-title" : "closed-accordion-title"}
                        onClick={() => this.toggleAccordionItem(i)}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column computer={14} tablet={12} mobile={12}>
                                    {videos[i].salesPartner === 1 ?
                                        [
                                            <p style={{
                                                color: "red",
                                                marginBottom: "0px"
                                            }}>{t("titles.sales_partner_info")}</p>,
                                            <h2 style={{marginTop: "0px"}}>
                                                {language === "en-UK" ? videos[i].titleEn : videos[i].title}
                                            </h2>
                                        ]
                                        :
                                        <h2>
                                            {language === "en-UK" ? videos[i].titleEn : videos[i].title}
                                        </h2>
                                    }
                                </Grid.Column>
                                <Grid.Column
                                    computer={2} tablet={4} mobile={4}
                                    verticalAlign={"middle"}
                                    textAlign={"right"}>
                                    <Icon
                                        size={"big"}
                                        className={"accordion-icon"}
                                        name={activeItems.includes(i) ? 'angle up' : 'angle down'}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Accordion.Title>
                );

                accordions.push(<Accordion.Content
                    key={"c" + i}
                    className={this.activeItems.includes(i) ? "accordion-content" : ""}
                    active={activeItems.includes(i)}>
                    <Grid className={activeItems.includes(i) ? "faq-answer video-text" : "hide-div"}>
                        <Grid.Row>
                            <Grid.Column computer={8} tablet={10} mobile={15}>
                                <iframe data-src={videos[i].link}
                                        src={typeof Cookiebot !== "undefined" ? Cookiebot.consent.marketing ? videos[i].link : null : null}
                                        data-cookieblock-src={videos[i].link}
                                        data-cookieconsent="marketing"
                                        frameBorder='0'
                                        title='video'
                                        style={{"height": "100%", "width": "100%", "zIndex": "3"}}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Accordion.Content>);
            }
        }

        return <Grid.Column computer={8} tablet={14} mobile={16}>
            <Accordion
                defaultActiveIndex={toJS(activeItems)}
                fluid
                exclusive={false}>
            </Accordion>
            {accordions}
        </Grid.Column>
    }


    render() {
        let language = "de-DE";
        let {t, i18n} = this.props;

        if (i18n) {
            language = i18n.language;
        }

        let serviceVideos = this.getServiceVideos(this.product.serviceVideos, this.activeItems, language, t);

        return (
            <Grid>
                <Grid.Row className={"no-top-padding primary-background-container"} style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section>
                                <Link
                                    style={{"color": "white"}}
                                    to={"/"}>
                                    HOME
                                </Link>
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>
                                <Link
                                    style={{"color": "white"}}
                                    to={"/my-glw"}>
                                    {t('titles.my_glw').toUpperCase()}
                                </Link>
                            </Breadcrumb.Section>
                        </Responsive>
                        <h1 style={{"marginBottom": "0px"}} className={"top-row-text"}>
                            {this.product.productTypeId === productType3Id ?
                                t('wire_end.title') + " " +
                                (language === "en-UK" ? this.product.typeEn : this.product.type) + " " +
                                (language === "en-UK" ? this.product.crossSection : this.toGermanFloat(this.product.crossSection)) + " mm² - " +
                                (language === "en-UK" ? this.product.length : this.toGermanFloat(this.product.length)) + " mm"
                                :
                                (language === "en-UK" ? this.product.nameEn : this.product.name)
                            }
                        </h1>
                        <p className={"no-top-padding top-row-text"}>
                            {this.product.productTypeId === productType3Id ?
                                this.product.name
                                :
                                this.product.itemNumber}
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Responsive as={Grid.Row} maxWidth={767}
                            className={!this.showSubMenu ? "hide-div" : "no-top-padding sub-menu-container"}
                            style={{"zIndex": "1"}}>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/my-glw")}
                        textAlign={"center"}
                        className={"sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={"https://www.glw.de/images/Unternehmen_Inside-GLW_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('titles.my_machines').toUpperCase()}
                    </Grid.Column>
                    {isAdmin() || (userStore.userFromServer != null && userStore.userFromServer.hasOwnProperty("roles") && userStore.userFromServer.roles.includes("SalesPartner")) ?
                        [
                            <Grid.Column
                                computer={2} tablet={3} mobile={4}
                                onClick={() => history.push("/machine-market")}
                                textAlign={"center"}
                                className={"sub-menu-item"}>
                                <Image
                                    className={"sub-menu-item-image"}
                                    src={"https://www.glw.de/images/Fairness_passiv.png"}
                                    alt={""}
                                />
                                {i18n.t('titles.machine_market').toUpperCase()}
                            </Grid.Column>,
                            <Grid.Column
                                computer={2} tablet={3} mobile={4}
                                onClick={() => history.push("/service-overview")}
                                textAlign={"center"}
                                className={"sub-menu-item"}>
                                <Image
                                    className={"sub-menu-item-image"}
                                    src={"https://www.glw.de/images/Produkte_Zubehoer_passiv.png"}
                                    alt={""}
                                />
                                {i18n.t('titles.service_overview').toUpperCase()}
                            </Grid.Column>,
                            <Grid.Column
                                computer={2} tablet={3} mobile={4}
                                onClick={() => history.push("/sales-partner-downloads")}
                                textAlign={"center"}
                                className={"sub-menu-item"}>
                                <Image
                                    className={"sub-menu-item-image"}
                                    src={"https://www.glw.de/images/Produkte_Download_passiv.png"}
                                    alt={""}
                                />
                                {i18n.t('titles.sales-download').toUpperCase()}
                            </Grid.Column>,
                            <Grid.Column
                                computer={2} tablet={3} mobile={4}
                                onClick={() => history.push("/delivery_times")}
                                textAlign={"center"}
                                className={"sub-menu-item"}>
                                <Image
                                    className={"sub-menu-item-image"}
                                    src={"https://www.glw.de/images/Kunden_passiv.png"}
                                    alt={""}
                                />
                                {i18n.t('titles.delivery_times').toUpperCase()}
                            </Grid.Column>
                        ]
                        : null
                    }
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/my-profile")}
                        textAlign={"center"}
                        className={"sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={"https://www.glw.de/images/Familienbetrieb_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('titles.my_profile').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => {
                            removeTokenFromStorage();
                            userStore.user = null;
                            this.setState({});
                            history.push("/");
                        }}
                        floated={"right"}
                        textAlign={"center"}
                        className={"sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={"https://www.glw.de/images/Logout.png"}
                            alt={""}
                        />
                        {t('titles.logout').toUpperCase()}
                    </Grid.Column>

                </Responsive>
                <Responsive as={Grid.Row} maxWidth={767} centered className={"no-top-padding sub-menu-container"}>
                    <Grid.Column width={3} style={{"padding": "15px"}}
                                 textAlign={"center"}>
                        <Icon name={this.showSubMenu ? "angle double up" : "angle double down"}
                              size={"big"}
                              onClick={() => this.showSubMenu = !this.showSubMenu}/>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} minWidth={768}
                            className={"no-top-padding sub-menu-container"}
                            style={{"zIndex": "1"}}>
                    <Grid.Column
                        width={isAdmin() || (userStore.userFromServer != null && userStore.userFromServer.hasOwnProperty("roles") && userStore.userFromServer.roles.includes("SalesPartner")) ?
                            1 : 2} only={"computer"}/>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/my-glw")}
                        textAlign={"center"}
                        className={"sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={"https://www.glw.de/images/Unternehmen_Inside-GLW_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('titles.my_machines').toUpperCase()}
                    </Grid.Column>
                    {isAdmin() || (userStore.userFromServer != null && userStore.userFromServer.hasOwnProperty("roles") && userStore.userFromServer.roles.includes("SalesPartner")) ?
                        [
                            <Grid.Column
                                computer={2} tablet={3} mobile={4}
                                onClick={() => history.push("/machine-market")}
                                textAlign={"center"}
                                className={"sub-menu-item"}>
                                <Image
                                    className={"sub-menu-item-image"}
                                    src={"https://www.glw.de/images/Fairness_passiv.png"}
                                    alt={""}
                                />
                                {i18n.t('titles.machine_market').toUpperCase()}
                            </Grid.Column>,
                            <Grid.Column
                                computer={2} tablet={3} mobile={4}
                                onClick={() => history.push("/service-overview")}
                                textAlign={"center"}
                                className={"sub-menu-item"}>
                                <Image
                                    className={"sub-menu-item-image"}
                                    src={"https://www.glw.de/images/Produkte_Zubehoer_passiv.png"}
                                    alt={""}
                                />
                                {i18n.t('titles.service_overview').toUpperCase()}
                            </Grid.Column>,
                            <Grid.Column
                                computer={2} tablet={3} mobile={4}
                                onClick={() => history.push("/sales-partner-downloads")}
                                textAlign={"center"}
                                className={"sub-menu-item"}>
                                <Image
                                    className={"sub-menu-item-image"}
                                    src={"https://www.glw.de/images/Produkte_Download_passiv.png"}
                                    alt={""}
                                />
                                {i18n.t('titles.sales-download').toUpperCase()}
                            </Grid.Column>,
                            <Grid.Column
                                computer={2} tablet={3} mobile={4}
                                onClick={() => history.push("/delivery_times")}
                                textAlign={"center"}
                                className={"sub-menu-item"}>
                                <Image
                                    className={"sub-menu-item-image"}
                                    src={"https://www.glw.de/images/Kunden_passiv.png"}
                                    alt={""}
                                />
                                {i18n.t('titles.delivery_times').toUpperCase()}
                            </Grid.Column>
                        ]
                        : null
                    }
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/my-profile")}
                        textAlign={"center"}
                        className={"sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={"https://www.glw.de/images/Familienbetrieb_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('titles.my_profile').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => {
                            removeTokenFromStorage();
                            userStore.user = null;
                            this.setState({});
                            history.push("/");
                        }}
                        floated={"right"}
                        textAlign={"center"}
                        className={"sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={"https://www.glw.de/images/Logout.png"}
                            alt={""}
                        />
                        {t('titles.logout').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        width={isAdmin() || (userStore.userFromServer != null && userStore.userFromServer.hasOwnProperty("roles") && userStore.userFromServer.roles.includes("SalesPartner")) ?
                            1 : 2} only={"computer"}/>
                </Responsive>

                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"products-container"}>
                        <Grid padded>
                            <Grid.Row centered style={{"paddingTop": "50px"}}>
                                <Grid.Column width={15} style={{"paddingBottom": "10px"}}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: (language === "en-UK" ? this.product.serviceTextEn : this.product.serviceText)}}/>
                                </Grid.Column>
                                <Grid.Column width={3} only={"computer"}>
                                    <Grid.Column>
                                        {this.getLanguageInfo(language)}
                                    </Grid.Column>
                                </Grid.Column>

                            </Grid.Row>

                            <Grid.Row centered style={{"marginBottom": "30px"}}>
                                <Grid.Column width={15}>
                                    {serviceVideos}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered>
                                <Grid.Column width={15}>
                                    <Button
                                        className={"glw-button"}
                                        onClick={() => history.push("/service_request")}>
                                        {t('titles.service_request')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}