import React from "react"
import 'whatwg-fetch';
import {inject, observer} from "mobx-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer";

import NavigationBar from "../components/NavigationBar"
import ContactAction from "../components/ContactAction";

import textStore from "../stores/textStore"
import FooterComponent from "../components/FooterComponent";
import {withTranslation} from "react-i18next";
import {observable} from "mobx";

import history from "../helper/browserHistory";

import {renderRoutes} from "react-router-config";
import allRoutes from "../../../shared/routes";
import ScrollUpButton from "../components/ScrollUpButton";

if (typeof document !== "undefined") {
    // Require scss files
    require('../../stylesheets/_all.scss');
    require("pure-react-carousel/dist/react-carousel.es.css");
}


@inject("stores")
@withTranslation()
@observer
export default class Layout extends React.Component {

    @observable
    performanceCookies = false;

    @observable
    showConsentBanner = false;

    componentDidMount() {
        history.listen(() => {
            this.setState({})
        });

        if (window.location.hash.length > 0) {
            let url = window.location.hash.replace("#", "");
            history.push(url);
        }

    }

    render() {

        return (
            textStore.loading ?
                <Dimmer active inverted>
                    <Loader inverted content="Laden"/>
                </Dimmer>
                :
                <Grid columns={2} style={{maxWidth:"1400px", margin:"auto"}}>
                    <Grid.Row style={{"paddingBottom": "0"}}>
                        <Grid.Column width={16}>
                            <NavigationBar/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} style={{"paddingTop": "0"}}>
                        <Grid.Column>
                            {renderRoutes(allRoutes)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{"paddingBottom": "0"}}>
                        <Grid.Column width={16}>
                            <FooterComponent/>
                        </Grid.Column>
                    </Grid.Row>
                    <ScrollUpButton/>
                    <ContactAction/>
                </Grid>
        );
    }
}