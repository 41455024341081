import React from "react";
import {inject, observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import history from "../helper/browserHistory";
import {
    getServiceDate,
    getServiceState,
    getTokenFromLocalStorage,
    updatePageView
} from "../helper/util";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import EditHTMLComponent from "../moap-components/EditHTMLComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import userStore from "../stores/userStore";
import {observable} from "mobx";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import config from "../../config/main.config";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import MyGLWOverlayComponent from "../components/MyGLWOverlayComponent";
import MyGLWNavigationBar from "../components/MyGLWNavigationBar";

const DEFAULT_SERVICE = {
    id: "",
    machineId: "",
    state: 0,
    serviceDate: new Date(),
    note: "",
    rating: 0,
    userNote: "",
    trackingLink: ""
}

@withTranslation()
@inject("stores")
@observer
export default class ServiceRequestPage extends React.Component {

    @observable
    addMachineModalOpen = false;
    @observable
    successModalOpen = false;
    @observable
    salesPartnerModal = false;

    @observable
    serialNumber = "";
    @observable
    serialNumberError = false;

    @observable
    machines = [];

    @observable
    serviceHistoryModalOpen = false;
    @observable
    addEditServiceModalOpen = false;

    @observable
    confirmServiceModalOpen = false;
    @observable
    serviceMachineId = "";

    @observable
    startModalOpen = false;
    @observable
    notShowAgain = false;

    @observable
    editService = DEFAULT_SERVICE;

    @observable
    serviceMachine = {
        id: "",
        approved: 0,
        productId: "",
        userId: "",
        lastService: new Date(),
        services: [],
        year: 0,
        salesPartnerId: 0,
        salesPartner: null
    };

    @observable
    modalOpen = false;
    @observable
    modalMessage = "";

    @observable
    userFromServer = null;

    @observable
    deleteModalOpen = false;
    @observable
    deleteId = "";

    @observable
    showSubMenu = false;

    componentDidMount() {
        if (userStore.userFromServer == null) {
            history.replace("/login")
        }else{
            this.userFromServer = userStore.userFromServer;
            updatePageView(this.props.location.pathname);
            let user = userStore.userFromServer;
            if (user.roles.length > 0) {
                if (user.roles.includes("SalesPartner")) {
                    this.fetchSalesUser();
                }
            } else {
                this.fetchUser();
            }
        }
        window.scrollTo(0, 0);
    }

    fetchUser() {
        let self = this;
        fetch(config.BASE_URL + 'customers/', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
            },
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.editUser = json;
                    if (json.hasOwnProperty("showModal") && json.showModal === 0) {
                        self.startModalOpen = true;
                    }
                });
            } else if (response.status === 401) {
                history.push("/login");
            }
        }).catch(() => {
        });
    }

    fetchSalesUser() {
        let self = this;
        fetch(config.BASE_URL + 'sales_users/', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
            },
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.editUser = json;
                    if (json.hasOwnProperty("showModal") && json.showModal === 0) {
                        self.startModalOpen = true;
                    }
                });
            } else if (response.status === 401) {
                history.push("/login");
            }
        }).catch(() => {
        });
    }

    editUserStartModal() {
        let self = this;
        let path = 'customers/not_show_modal';
        if (userStore.userFromServer.hasOwnProperty("roles") && userStore.userFromServer.roles.includes("SalesPartner")) {
            path = 'sales_users/not_show_modal';
        }

        fetch(config.BASE_URL + path, {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
        }).then(function (response) {
            response.json().then(json => {
                if (json.hasOwnProperty("success")) {
                    if (json.success === true) {
                        self.fetchUser();
                    }
                } else {
                    self.editUserError = true;
                }
                self.setState({});
            });
        }).catch(() => {
            self.editUserError = true;
        });
    }

    showServices(services, t) {
        return services.map((service, index) => <Table.Row key={"service" + index}>
            <Table.Cell>
                {getServiceDate(service.serviceDate)}
            </Table.Cell>
            <Table.Cell>
                {getServiceState(service.state, t)}
            </Table.Cell>
            <Table.Cell
                style={{textAlign: "end"}}>
                <Button color='green'
                        onClick={() => {
                            service.serviceDate = new Date(service.serviceDate);
                            this.editService = service;
                            this.addEditServiceModalOpen = true;
                        }}>
                    <Icon name='edit'/>
                </Button>
            </Table.Cell>
        </Table.Row>)
    }

    handleServiceEditChange(type, event, data) {
        this.editService[type] = data.value;
    }

    sendServiceRequest() {
        let self = this;
        fetch(config.BASE_URL + 'service_request/user', {
            method: "POST",
            body: JSON.stringify({
                serviceMachineId: this.serviceMachineId,
                userId: this.editUser.id
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasOwnProperty("success") && json) {
                        self.confirmServiceModalOpen = false;
                        self.modalMessage = i18n.t('contact_form.response_success');
                    } else {
                        self.modalMessage = i18n.t('contact_form.response_error');
                    }
                    self.modalOpen = true;
                });
            } else {
                self.modalMessage = i18n.t('contact_form.response_error');
                self.modalOpen = true;
            }
        }).catch(() => {
            self.modalMessage = i18n.t('contact_form.response_error');
            self.modalOpen = true;
        });
    }

    render() {
        const {t} = this.props;

        return (
            <Grid>
                <Modal
                    style={{"margin": "auto"}}
                    open={this.modalOpen}
                    onClose={() => this.modalOpen = false}
                    size='small'>
                    <Modal.Content>
                        <h3>{this.modalMessage}</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={() => this.modalOpen = false}>
                            <Icon name='checkmark'/> OK
                        </Button>
                    </Modal.Actions>
                </Modal>
                <MyGLWOverlayComponent
                    open={this.startModalOpen}
                    onClose={() => this.startModalOpen = false}
                    onChange={() => this.notShowAgain = !this.notShowAgain}
                    checked={this.notShowAgain}
                    onClick={() => {
                        this.startModalOpen = false
                        if (this.notShowAgain) {
                            this.editUserStartModal();
                        }
                    }}
                />
                <Modal
                    open={this.confirmServiceModalOpen}
                    onClose={() => this.confirmServiceModalOpen = false}
                    dimmer={"blurring"}
                    style={{"margin": "auto"}}>
                    <Modal.Header>{t('titles.service_request')}</Modal.Header>
                    <Modal.Content>
                        <p>{t('my_glw.confirm_service')}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green'
                                onClick={() => {
                                    this.sendServiceRequest();
                                }}>
                            <Icon name='checkmark'/> ok
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    dimmer={"blurring"}
                    style={{"margin": "auto"}}
                    open={this.successModalOpen}
                    onClose={() => this.successModalOpen = false}>
                    <Modal.Header>
                        {t('my_glw.add_modal_header')}
                    </Modal.Header>
                    <Modal.Content>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <p>{t('my_glw.success_modal_text')}</p>
                                    <Button type='submit'
                                            className={"glw-button"}
                                            floated='right'
                                            onClick={() => this.successModalOpen = false}>
                                        <Icon name='checkmark'/>
                                        {t('my_glw.ok')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                </Modal>
                <Modal
                    dimmer={"blurring"}
                    style={{"margin": "auto"}}
                    open={this.serviceHistoryModalOpen}
                    onClose={() => this.serviceHistoryModalOpen = false}>
                    <Modal.Header>
                        Services
                    </Modal.Header>
                    <Modal.Content>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Table>
                                        {this.showServices(this.serviceMachine.services, t)}
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            color='green'
                            onClick={() => {
                                this.serviceHistoryModalOpen = false
                            }}>
                            OK
                        </Button>
                    </Modal.Actions>
                </Modal>
                <EditHeaderComponent id={"my-glw-page"}/>
                <EditHTMLComponent id={"my-glw-page-header"}/>
                <Grid.Row className={"no-top-padding primary-background-container"} style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider
                                onClick={() => history.push("/")}>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>{t('titles.my_glw').toUpperCase()}</Breadcrumb.Section>
                        </Responsive>
                        <h1 className={"top-row-text"}>{t('titles.my_glw').toUpperCase()}</h1>
                    </Grid.Column>
                </Grid.Row>
                <MyGLWNavigationBar/>
            </Grid>
        );
    }
}