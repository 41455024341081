import React from "react";
import PropTypes from "prop-types";

import {withTranslation} from "react-i18next";
import sharedConfig from "../../../shared/sharedConfig";
import userStore from "../stores/userStore"
import {Helmet} from 'react-helmet-async';
import {inject, observer} from "mobx-react";
import {observable, toJS} from "mobx";

import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {isAdmin} from "../helper/util";

@inject("stores")
@withTranslation()
@observer
export default class EditHeaderComponent extends React.Component {
    @observable editState = false;

    handleChange = (e, {value}) => this.setState({
        ...this.state,
        language: value,
        htmlText: '',
        description: '',
        title: ''
    });

    constructor(props) {
        super(props);

        this.changeText = this.changeText.bind(this);

        this.state = {
            htmlText: '',
            title: '',
            description: '',
            language: 'de-DE'
        }
    }

    changeText(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            ...this.state,
            [name]: value
        });
    }


    render() {
        let {id, style} = this.props;

        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        let textToShow = {};
        if (typeof this.props.stores.textStore.texts[id] !== "undefined") {
            if (typeof this.props.stores.textStore.texts[id][language] !== "undefined") {
                textToShow = toJS(this.props.stores.textStore.texts[id][language]);
            } else {
                textToShow = {};
            }
        }

        let contentToShow = "";


        let displayType = isAdmin() ? "admin" : "user";

        if (displayType === "admin" && typeof document !== "undefined") {
            let textToEdit = {};

            if (typeof this.props.stores.textStore.texts[id] !== "undefined") {
                if (typeof this.props.stores.textStore.texts[id][this.state.language] !== "undefined") {
                    textToEdit = toJS(this.props.stores.textStore.texts[id][this.state.language]);
                } else {
                    textToEdit = {};
                }
            }

            if (this.state.htmlText !== '') {
                textToEdit.content = this.state.htmlText;
            }

            if (this.state.title !== '') {
                textToEdit.title = this.state.title;
            }

            if (this.state.description !== '') {
                textToEdit.description = this.state.description;
            }


            if (this.editState) {
                if (style === null) {
                    style = {
                        "zIndex": 1,
                        "position": "relative",
                        "backgroundColor": "white",
                        "overflow": "auto"
                    };
                } else {
                    // need to copy this because otherwise I get "not extendable error"
                    let customStyle = Object.assign({}, style);
                    customStyle.zIndex = 1;
                    customStyle.position = "relative";
                    customStyle.backgroundColor = "white";
                    customStyle.overflow = "auto";
                    style = customStyle;
                }

                contentToShow = <div style={style}>
                    <div>
                        <Helmet>
                            <title>{textToEdit.title}</title>
                            <meta name="description" content={textToEdit.description}/>
                            <meta name="title" content={textToEdit.title}/>
                            <meta name="robots" content="index,follow"/>
                        </Helmet>
                    </div>
                    <Form>
                        <Form.Field>
                            <label>Title Tag</label>
                            <Input value={typeof textToEdit.title === "undefined" ? "" : textToEdit.title}
                                   name='title'
                                   onChange={this.changeText}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Description Tag</label>
                            <TextArea
                                value={typeof textToEdit.description === "undefined" ? "" : textToEdit.description}
                                name='description'
                                onChange={this.changeText}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Button.Group>
                                <Button color="green" onClick={() => {

                                    /*if (this.state.htmlText !== '') {
                                        textToEdit.content = this.state.htmlText;
                                    }*/
                                    textToEdit.content = "";
                                    textToEdit.title = this.state.title;
                                    textToEdit.description = this.state.description;

                                    if (typeof this.props.stores.textStore.texts[id] === "undefined"
                                        || typeof this.props.stores.textStore.texts[id][this.state.language] === "undefined") {
                                        this.props.stores.textStore.createText(id, textToEdit, this.state.language).then(isSuccess => {
                                            if (isSuccess) {
                                                this.editState = false;
                                            }
                                        }).catch(() => {

                                        });
                                    } else {
                                        this.props.stores.textStore.updateText(id, textToEdit, this.state.language).then(isSuccess => {
                                            if (isSuccess) {
                                                this.editState = false;
                                            }
                                        }).catch(() => {

                                        });
                                    }
                                }}>
                                    {typeof this.props.stores.textStore.texts[id] === "undefined"
                                    || typeof this.props.stores.textStore.texts[id][this.state.language] === "undefined" ? "Speichern" : "Bearbeiten"}
                                </Button>
                                <Button color="red" onClick={() => this.editState = false}>Abbrechen</Button>
                            </Button.Group>
                        </Form.Field>
                    </Form>

                </div>
            } else {
                const languageRadioButtons = sharedConfig.LANGUAGES.map(language => (
                    <Form.Field key={language.language}>
                        <Radio
                            label={language.label}
                            name='radioGroup'
                            value={language.language}
                            checked={this.state.language === language.language}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                ));

                contentToShow = <Grid.Row centered>
                    <Grid.Column width={15}>
                        <div style={style}>
                            <Form>
                                {languageRadioButtons}
                            </Form>
                            <h1 id={id}>{textToEdit.content}</h1>
                            <p id={"ssr" + id}>Page-title: {textToEdit.title} | Page-tags: {textToEdit.description}</p>
                            <br/>
                            <Button color="orange" icon="edit outline" onClick={() => {
                                this.editState = true
                            }}/>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            }

        } else {
            contentToShow = <div>
                <Helmet>
                    <title>{textToShow.title}</title>
                    <meta name="description" content={textToShow.description}/>
                    <meta name="title" content={textToShow.title}/>
                    <meta name="robots" content="index,follow"/>
                </Helmet>
            </div>;
        }

        return (
            contentToShow
        );
    }
}

EditHeaderComponent.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object
};

EditHeaderComponent.defaultProps = {
    id: Math.random().toString(),
    style: null
};