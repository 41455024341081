import React from "react";
import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import config from "../../config/main.config";


const ValuesSubPage = (props) => {
    const {i18n} = props;
    const images = [
        '1.svg',
        '2.svg',
        '3.svg',
        '4.svg',
        '5.svg',
        '6.svg',
    ]
    return (
        <Grid>
            <Grid.Row style={{paddingBottom: 0}}>
                <Grid.Column width={16}>
                    <h3>{i18n.t('titles.our_values').toUpperCase()}</h3>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column  computer={16} mobile={16} tablet={16}>
                    <div className={"values-grid"}>
                        {images.map((src) =>
                            (<img src={`${config.BASE_IMAGE_URL}company/values/${src}`} alt={src}
                                  style={{width: "100%"}}/>)
                        )}
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default withTranslation()(ValuesSubPage)