import React from "react";
import i18n from "../i18n";
import {inject, observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import {observable} from "mobx";
import config from "../../config/main.config";
import history from "../helper/browserHistory";
import {updatePageView} from "../helper/util";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";

const regexpMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@withTranslation()
@inject("stores")
@observer
export default class ServiceRequestPage extends React.Component {

    @observable
    firm = "";
    @observable
    street = "";
    @observable
    contactPerson = "";
    @observable
    zipCode = "";
    @observable
    serialNumber = "";
    @observable
    email = "";
    @observable
    country = "";
    @observable
    conditionsChecked = false;
    @observable
    firmError = false;
    @observable
    streetError = false;
    @observable
    contactPersonError = false;
    @observable
    zipCodeError = false;
    @observable
    serialNumberError = false;
    @observable
    emailError = false;
    @observable
    countryError = false;
    @observable
    messageError = false;
    @observable
    conditionsCheckedError = false;

    @observable
    modalOpen = false;
    @observable
    modalMessage = "";

    componentDidMount() {
        updatePageView(this.props.location.pathname);
        window.scrollTo(0, 0);
    }

    handleSearchChange(type, event, data) {
        switch (type) {
            case "firm":
                this.firm = data.value;
                break;
            case "street":
                this.street = data.value;
                break;
            case "contactPerson":
                this.contactPerson = data.value;
                break;
            case "zipCode":
                this.zipCode = data.value;
                break;
            case "serialNumber":
                //  if (data.value.match(regexpPhone) || data.value === '') {
                this.serialNumber = data.value;
                // }
                break;
            case "email":
                this.email = data.value;
                break;
            case "country":
                this.country = data.value;
                break;
            case "message":
                this.message = data.value;
                break;
        }
    }

    submitMessage() {
        let canSend = true;
        //first validate data
        if (this.firm.length > 0) {
            this.firmError = false;
        } else {
            this.firmError = true;
            canSend = false;
        }

        if (this.contactPerson.length > 0) {
            this.contactPersonError = false;
        } else {
            this.contactPersonError = true;
            canSend = false;
        }

        if (this.serialNumber.length > 0) {
            this.serialNumberError = false;
        } else {
            this.serialNumberError = true;
            canSend = false;
        }

        if (this.email.length > 0 && this.email.match(regexpMail)) {
            this.emailError = false;
        } else {
            this.emailError = true;
            canSend = false;
        }

        if (this.conditionsChecked) {
            this.conditionsCheckedError = false;
        } else {
            this.conditionsCheckedError = true;
            canSend = false;
        }

        let language = "de-DE";

        if (i18n) {
            language = this.props.i18n.language;
        }


        if (canSend) {
            //Send to server
            let data = {
                firm: this.firm,
                language: language,
                street: this.street,
                contactPerson: this.contactPerson,
                zipCode: this.zipCode,
                serialNumber: this.serialNumber,
                email: this.email,
                country: this.country
            };

            let self = this;

            fetch(config.BASE_URL + 'service_request', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        if (json.hasOwnProperty("success") && json) {
                            self.modalMessage = i18n.t('service_form.response_success');
                        } else {
                            self.modalMessage = i18n.t('service_form.response_error');
                        }
                        self.modalOpen = true;
                    });
                } else {
                    self.modalMessage = i18n.t('service_form.response_error');
                    self.modalOpen = true;
                }
            }).catch(() => {
                self.modalMessage = i18n.t('service_form.response_error');
                self.modalOpen = true;
            });
        }
    }


    render() {

        return (
            <Grid>
                <EditHeaderComponent id={"service-request-page"}/>
                <Modal
                    style={{"margin": "auto"}}
                    open={this.modalOpen}
                    onClose={() => this.modalOpen = false}
                    size='small'>
                    <Modal.Content>
                        <h3>{this.modalMessage}</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => this.modalOpen = false}>
                            <Icon name='delete'/> {i18n.t('my_glw.abort')}
                        </Button>
                        <Button color='green' onClick={() => history.push("/")}>
                            <Icon name='checkmark'/> OK
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Grid.Row className={"no-top-padding primary-background-container"} style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider
                                onClick={() => history.push("/")}>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>{i18n.t('titles.service_request').toUpperCase()}</Breadcrumb.Section>
                        </Responsive>
                        <h1 className={"top-row-text"}>{i18n.t('titles.service_request').toUpperCase()}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"products-container"}>
                        <Grid padded>
                            <Grid.Row>
                                <Grid.Column style={{"paddingTop": "15px"}}>
                                    <h3>{i18n.t('contact_form.service_title')}</h3>
                                    <Form>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.firm')}
                                                placeholder={i18n.t('contact_form.firm') + "*"}
                                                value={this.firm}
                                                error={this.firmError}
                                                onChange={this.handleSearchChange.bind(this, "firm")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.contact_person')}
                                                placeholder={i18n.t('contact_form.contact_person') + "*"}
                                                value={this.contactPerson}
                                                error={this.contactPersonError}
                                                onChange={this.handleSearchChange.bind(this, "contactPerson")}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.street')}
                                                placeholder={i18n.t('contact_form.street')}
                                                value={this.street}
                                                error={this.streetError}
                                                onChange={this.handleSearchChange.bind(this, "street")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.postal_code')}
                                                placeholder={i18n.t('contact_form.postal_code')}
                                                value={this.zipCode}
                                                error={this.zipCodeError}
                                                onChange={this.handleSearchChange.bind(this, "zipCode")}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.country')}
                                                placeholder={i18n.t('contact_form.country')}
                                                value={this.country}
                                                error={this.countryError}
                                                onChange={this.handleSearchChange.bind(this, "country")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.email')}
                                                placeholder={i18n.t('contact_form.email') + "*"}
                                                value={this.email}
                                                error={this.emailError}
                                                onChange={this.handleSearchChange.bind(this, "email")}
                                            />
                                        </Form.Group>
                                        <Form.Field width={8}>
                                            <Form.Input
                                                label={i18n.t('contact_form.serial_number')}
                                                placeholder={i18n.t('contact_form.serial_number') + "*"}
                                                value={this.serialNumber}
                                                error={this.serialNumberError}
                                                onChange={this.handleSearchChange.bind(this, "serialNumber")}
                                            />
                                        </Form.Field>
                                        <Form.Field
                                            error={this.conditionsCheckedError}>
                                            <Checkbox
                                                style={{"marginTop": "10px", "marginRight": "5px"}}
                                                onChange={() => this.conditionsChecked = !this.conditionsChecked}
                                                checked={this.conditionsChecked}
                                            />
                                            {i18n.t('contact_form.terms_conditions')}
                                            <a onClick={() => history.push("/legal")}
                                               className={"clicky-thingy"}>
                                                {i18n.t('contact_form.privacy_link_text')}
                                            </a>
                                        </Form.Field>
                                        <Button type='submit'
                                                className={"glw-button"}
                                                onClick={() => this.submitMessage()}>{i18n.t('contact_form.submit')}
                                        </Button>
                                        <p>* = {i18n.t('contact_form.mandatory')}</p>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}