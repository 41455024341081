/**
 * Created by Matijas on 11.04.2017.
 */

import { isDevelop } from "../js/helper/util";

export default {
    BASE_URL: isDevelop() ? "http://localhost:3000/api/" : "https://www.glw.de/api/",
    BASE_IMAGE_URL: isDevelop() ? "http://localhost:3000/images/" : "https://www.glw.de/images/",
    BASE_VIDEO_URL: isDevelop() ? "http://localhost:3000/videos/" : "https://www.glw.de/videos/",
    PUBLIC_URL: isDevelop() ? "http://localhost:3000/" : "https://www.glw.de/",
    STORAGE_KEY: "4WfG5jv3",
    BASKET_STORAGE_KEY: "CRIPM9y",
    USER_STORAGE_KEY: "cjhSopcv0dsfh55!_w9"
}