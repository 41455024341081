import DownloadPage from "../react/js/pages/DownloadsPage";
import ContactPage from "../react/js/pages/ContactPage";
import FrontPage from "../react/js/pages/FrontPage";
import CompanyPage from "../react/js/pages/CompanyPage";
import CompanyOverviewPage from "../react/js/pages/CompanyOverviewPage";
import ProductsPage from "../react/js/pages/ProductsPage";
import ProductOverviewPage from "../react/js/pages/ProductOverview";
import ProductDetailPage from "../react/js/pages/ProductDetailPage";
import AGBPage from "../react/js/pages/AGBPage";
import CareerPage from "../react/js/pages/CareerPage";
import ImpressumPage from "../react/js/pages/ImpressumPage";
import LegalPage from "../react/js/pages/LegalPage";
import FAQPage from "../react/js/pages/FAQPage";
import ProductServicePage from "../react/js/pages/ProductServicePage";
import { loadContentWithFrontendId, loadProductData, loadProducts } from "../server/helper/ssrHelper";
import Loadable from 'react-loadable';
import LoaderComponent from "../react/js/components/LoaderComponent";
import NotFoundPage from "../react/js/pages/NotFoundPage";
import ServiceRequestPage from "../react/js/pages/ServiceRequestPage";
import MyGLWPage from "../react/js/pages/MyGLWPage";
import RegisterPage from "../react/js/pages/RegisterPage";
import InformationPage from "../react/js/pages/InformationPage";

const ApplyLegalPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ApplyLegalPage' */ "../react/js/pages/ApplyLegalPage"),
    loading: () => LoaderComponent
});

const DeliveryTimesPage = Loadable({
    loader: () => import(/* webpackChunkName: 'DeliveryTimesPage' */ "../react/js/pages/DeliveryTimesPage"),
    loading: () => LoaderComponent
});

const SalesPartnerDownload = Loadable({
    loader: () => import(/* webpackChunkName: 'SalesPartnerDownload' */ "../react/js/pages/SalesPartnerDownload"),
    loading: () => LoaderComponent
});

const ServiceOverviewPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ServiceOverviewPage' */ "../react/js/pages/ServiceOverviewPage"),
    loading: () => LoaderComponent
});

const ResetPasswordPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ResetPasswordPage' */ "../react/js/pages/ResetPasswordPage"),
    loading: () => LoaderComponent
});

const PasswordForgotPage = Loadable({
    loader: () => import(/* webpackChunkName: 'PasswordForgotPage' */ "../react/js/pages/PasswordForgotPage"),
    loading: () => LoaderComponent
});

const SetPasswordPage = Loadable({
    loader: () => import(/* webpackChunkName: 'SetPasswordPage' */ "../react/js/pages/SetPasswordPage"),
    loading: () => LoaderComponent
});

const CustomerManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'CustomerManagementPage' */ "../react/js/pages/CustomerManagementPage"),
    loading: () => LoaderComponent
});

const CustomerMachinesManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'CustomerMachinesManagementPage' */ "../react/js/pages/CustomerMachinesManagementPage"),
    loading: () => LoaderComponent
});

//import Login from "../react/js/pages/Login";
const Login = Loadable({
    loader: () => import(/* webpackChunkName: 'Login' */ "../react/js/pages/Login"),
    loading: () => LoaderComponent
});
//import WireEndEditPage from "../react/js/pages/WireEndEditPage";
const WireEndEditPage = Loadable({
    loader: () => import(/* webpackChunkName: 'WireEndEdit' */ "../react/js/pages/WireEndEditPage"),
    loading: () => LoaderComponent
});
//import SortiboxEditPage from "../react/js/pages/SortiboxEditPage";
const SortiboxEditPage = Loadable({
    loader: () => import(/* webpackChunkName: 'SortiboxEdit' */ "../react/js/pages/SortiboxEditPage"),
    loading: () => LoaderComponent
});
//import ProductEditPage from "../react/js/pages/ProductEdit";
const ProductEditPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ProductEdit' */ "../react/js/pages/ProductEditPage"),
    loading: () => LoaderComponent
});
//import ProductServiceManagementPage from "../react/js/pages/ProductServiceManagementPage";
const ProductServiceManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ProductServiceManagement' */ "../react/js/pages/ProductServiceManagementPage"),
    loading: () => LoaderComponent
});
//import ProductServiceEditPage from "../react/js/pages/ProductServiceEdit";
const ProductServiceEditPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ProductServiceEdit' */ "../react/js/pages/ProductServiceEditPage"),
    loading: () => LoaderComponent
});
//import ProductManagementPage from "../react/js/pages/ProductManagement";
const ProductManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ProductManagement' */ "../react/js/pages/ProductManagementPage"),
    loading: () => LoaderComponent
});

const NewsLetterUnsubscribePage = Loadable({
    loader: () => import(/* webpackChunkName: 'NewsLetterUnsubscribePage' */ "../react/js/pages/NewsLetterUnsubscribePage"),
    loading: () => LoaderComponent
});

const SalesPartnerManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'SalesPartnerManagementPage' */ "../react/js/pages/SalesPartnerManagementPage"),
    loading: () => LoaderComponent
});

const MyProfilePage = Loadable({
    loader: () => import(/* webpackChunkName: 'MyProfilePage' */ "../react/js/pages/MyProfilePage"),
    loading: () => LoaderComponent
});

const MachineMarketPage = Loadable({
    loader: () => import(/* webpackChunkName: 'MachineMarketPage' */ "../react/js/pages/MachineMarketPage"),
    loading: () => LoaderComponent
});

const MachineMarketManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'MachineMarketManagementPage' */ "../react/js/pages/MachineMarketManagementPage"),
    loading: () => LoaderComponent
});

const MachineMarketEditPage = Loadable({
    loader: () => import(/* webpackChunkName: 'MachineMarketEditPage' */ "../react/js/pages/MachineMarketEditPage"),
    loading: () => LoaderComponent
});

const ApplyPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ApplyPage' */ "../react/js/pages/ApplyPage"),
    loading: () => LoaderComponent
});

const ReturnProductInfoPage = Loadable({
    loader: () => import(/* webpackChunkName: 'ReturnProductInfoPage' */ "../react/js/pages/ReturnProductInfoPage"),
    loading: () => LoaderComponent
});

const sharedRoutes = [
    {
        path: "/",
        exact: true,
        component: FrontPage,
        loadData: (data, baseUrl) => {
            return loadContentWithFrontendId(baseUrl, "front-page");
        }
    },
    {
        path: "/404",
        exact: true,
        component: NotFoundPage
    },
    {
        path: "/admin-login",
        exact: true,
        component: Login
    },
    {
        path: "/login",
        exact: true,
        component: Login
    },
    {
        path: "/register",
        exact: true,
        component: RegisterPage,
        loadData: (data, baseUrl) => {
            return loadContentWithFrontendId(baseUrl, "register-page");
        }
    },
    {
        path: "/reset-password/:id",
        exact: true,
        component: ResetPasswordPage
    },
    {
        path: "/password-forgot",
        exact: true,
        component: PasswordForgotPage
    },
    {
        path: "/set-password/:id",
        exact: true,
        component: SetPasswordPage
    },
    {
        path: "/customer-management",
        exact: true,
        component: CustomerManagementPage
    },
    {
        path: "/customer-machine-management",
        exact: true,
        component: CustomerMachinesManagementPage
    },
    {
        path: "/my-glw",
        exact: true,
        component: MyGLWPage,
        loadData: (data, baseUrl) => {
            return loadContentWithFrontendId(baseUrl, "my-glw-page");
        }
    },
    {
        path: "/my-profile",
        exact: true,
        component: MyProfilePage,

    },
    {
        path: "/machine-market",
        exact: true,
        component: MachineMarketPage,
    },
    {
        path: "/machine-market-management",
        exact: true,
        component: MachineMarketManagementPage,
    },
    {
        path: "/company/:id",
        component: CompanyPage
    },
    {
        path: "/company-overview",
        exact: true,
        component: CompanyOverviewPage,
        loadData: (data, baseUrl) => {
            return loadContentWithFrontendId(baseUrl, "company-overview-page");
        }
    },
    {
        path: "/contact",
        exact: true,
        component: ContactPage,
        loadData: (data, baseUrl) => {
            return loadContentWithFrontendId(baseUrl, "contact-page");
        }
    },
    {
        path: "/informationspflicht-bewerbung",
        exact: true,
        component: ApplyLegalPage
    },
    {
        path: "/service_request",
        exact: true,
        component: ServiceRequestPage,
        loadData: (data, baseUrl) => {
            return loadContentWithFrontendId(baseUrl, "service-request-page");
        }
    },
    {
        path: "/products/:id/:handTool",
        component: ProductsPage,
        loadData: (data, baseUrl) => {
            return loadProducts(baseUrl);
        }
    },
    {
        path: "/product-overview",
        exact: true,
        component: ProductOverviewPage
    },
    {
        path: "/product-details/:id",
        component: ProductDetailPage,
        loadData: (data, baseUrl) => {
            return loadProductData(data, baseUrl);
        }
    },
    {
        path: "/agb",
        exact: true,
        component: AGBPage
    },
    {
        path: "/career",
        exact: true,
        component: CareerPage
    },
    {
        path: "/downloads",
        exact: true,
        component: DownloadPage
    },
    {
        path: "/impressum",
        exact: true,
        component: ImpressumPage
    },
    {
        path: "/legal",
        exact: true,
        component: LegalPage,
        loadData: (data, baseUrl) => {
            return loadContentWithFrontendId(baseUrl, "legal-page-text-id");
        }
    },
    {
        path: "/faq",
        exact: true,
        component: FAQPage,
        loadData: (data, baseUrl) => {
            return loadContentWithFrontendId(baseUrl, "faq-page");
        }
    },
    {
        path: "/product-service/:id",
        component: ProductServicePage
    },
    {
        path: "/return_product_info",
        exact: true,
        component: ReturnProductInfoPage
    },
    {
        path: "/product-service-management",
        exact: true,
        component: ProductServiceManagementPage
    },
    {
        path: "/product-service-edit/:id",
        component: ProductServiceEditPage
    },
    {
        path: "/product-management",
        exact: true,
        component: ProductManagementPage
    },
    {
        path: "/product-edit/:id",
        exact: true,
        component: ProductEditPage
    },
    {
        path: "/machine-edit/:id",
        exact: true,
        component: MachineMarketEditPage
    },
    {
        path: "/wire-end-edit/:id",
        component: WireEndEditPage
    },
    {
        path: "/sortibox-edit/:id",
        component: SortiboxEditPage
    },
    {
        path: "/unsubscribe",
        exact: true,
        component: NewsLetterUnsubscribePage
    },
    {
        path: "/sales-partner-management",
        exact: true,
        component: SalesPartnerManagementPage
    },
    {
        path: "/service-overview",
        exact: true,
        component: ServiceOverviewPage
    },
    {
        path: "/sales-partner-downloads",
        exact: true,
        component: SalesPartnerDownload
    },
    {
        path: "/delivery_times",
        exact: true,
        component: DeliveryTimesPage
    },
    {
        path: "/information",
        exact: true,
        component: InformationPage
    },
    {
        path: "/apply/:id",
        exact: true,
        component: ApplyPage
    }
];

export default sharedRoutes;