import React from "react";
import {observer} from "mobx-react";
import PropTypes from "prop-types";
import {ButtonBack, ButtonNext, CarouselProvider, Dot, Slide, Slider} from "pure-react-carousel";
import ReactImageMagnify from "react-image-magnify";
import {observable} from "mobx";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import i18n from "../i18n";

let isSafari = true;

if (typeof document !== "undefined") {
    isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
}

@observer
export default class ProductImageGalleryComponent extends React.Component {

    @observable
    currentPosition = 0;

    constructor(props) {
        super(props);
        this.currentPosition = this.props.initialPosition;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.currentPosition !== this.props.initialPosition)
            this.currentPosition = this.props.initialPosition;
    }

    getGalleryItems(images, videos) {

        let items = [];

        for (let i = 0; i < images.length; i++) {
            items.push(
                <Slide index={i} style={{"zIndex": "2"}}>
                    <ReactImageMagnify {...{
                        imageStyle: {aspectRatio: "1/1", objectFit: "cover"},
                        smallImage: {
                            isFluidWidth: true,
                            src: images[i].link,
                        },
                        largeImage: {
                            src: images[i].link,
                            width: 1200,
                            height: 1200
                        },
                        enlargedImagePosition: "over"
                    }} />
                </Slide>
            )
        }
        for (let i = 0; i < videos.length; i++) {
            items.push(
                <Slide index={i + images.length} style={{"zIndex": "3"}}>
                    <iframe data-src={videos[i].link}
                            src={typeof Cookiebot !== "undefined" ? Cookiebot.consent.marketing ? videos[i].link : null : null}
                            data-cookieblock-src={videos[i].link}
                            data-cookieconsent="marketing"
                            frameBorder='0'
                            allowFullScreen={!isSafari}
                            title='video'
                            style={{"height": "100%", "width": "100%", "zIndex": "3"}}
                    />
                    <div className={"cookieconsent-optout-marketing"}
                         style={{"height": "100%", "width": "100%", "zIndex": "3"}}
                    >
                        <div style={{"position": "absolute", "top": "50%"}}>
                            <div style={{paddingLeft: 15, paddingRight: 15}}>
                                {i18n.t('products.accept_cookies')}
                                <a href="javascript:Cookiebot.renew()">{i18n.t('products.accept_cookies_button')}</a>
                            </div>
                        </div>
                    </div>

                </Slide>
            )
        }

        return items;
    }

    getGalleryDots(images, videos) {
        let items = [];

        for (let i = 0; i < images.length; i++) {
            items.push(
                <Dot index={i} slide={i} className={"preview-dot"}
                     onClick={() => this.setGalleryPositionDot(i)}
                     style={{"backgroundImage": "url(" + images[i].link + ")"}}>
                    <p className={"empty-handtool-spec"}>.</p>
                </Dot>
            )
        }

        for (let i = 0; i < videos.length; i++) {
            items.push(
                <Dot index={i + images.length} slide={i + images.length}
                     onClick={() => this.setGalleryPositionDot(i + images.length)}
                     className={"preview-dot"}>
                    <Icon circular inverted color={"grey"} name={"play"}/>
                </Dot>
            )
        }

        return items;
    }

    setGalleryPositionDot(position) {
        this.currentPosition = position;
        this.setSearch(this.currentPosition);
    }

    setGalleryPosition(type) {
        let allPositions = this.props.images.length + this.props.videos.length - 1;

        if (type === "next") {
            if (this.currentPosition !== allPositions) {
                this.currentPosition = this.currentPosition + 1;
            } else {
                this.currentPosition = 0;
            }
        } else {
            if (this.currentPosition > 0) {
                this.currentPosition = this.currentPosition - 1;
            } else {
                this.currentPosition = allPositions;
            }
        }
        this.setSearch(this.currentPosition);

    }

    setSearch(position) {
        let search = "";
        let searchPosition = "" + position;

        let isStart = false;
        if (position >= this.props.images.length) {
            //then it is videos
            isStart = this.props.videos[position - this.props.images.length].start
        } else {
            isStart = this.props.images[position].start
        }

        if (isStart) {
            searchPosition = "start";
        }

        if (position !== 0 || isStart) {
            search = "pos=" + searchPosition
        }

        if (search.length > 0) {
            search = "?" + search;
        }

        this.props.history.push({
            search: search
        });
    }

    render() {
        let {images, videos} = this.props;

        const showStuff = this.getGalleryItems(images, videos);
        const showDots = this.getGalleryDots(images, videos);

        return (
            <CarouselProvider
                naturalSlideWidth={1}
                naturalSlideHeight={1}
                totalSlides={images.length + videos.length}
                currentSlide={this.currentPosition}
                infinite>
                <Slider>
                    {showStuff}
                </Slider>
                {(images.length + videos.length) > 1 ?
                    <ButtonBack
                        onClick={() => this.setGalleryPosition("back")}
                        className={"gallery-back-button"}>
                        <Icon name={'angle left'} size={"large"}/>
                    </ButtonBack>
                    :
                    null}
                {(images.length + videos.length) > 1 ?
                    <ButtonNext
                        onClick={() => this.setGalleryPosition("next")}
                        className={"gallery-next-button"}>
                        <Icon name={'angle right'} size={"large"}/>
                    </ButtonNext>
                    :
                    null}
                {(images.length + videos.length) > 1 ?
                    <div style={{"width": "100%", "textAlign": "center", "marginTop": "5px"}}>
                        {showDots}
                    </div>
                    :
                    null}

            </CarouselProvider>
        );
    }
}

ProductImageGalleryComponent.propTypes =
    {
        videos: PropTypes.array,
        images: PropTypes.array,
        initialPosition: PropTypes.number,
    };

ProductImageGalleryComponent.defaultProps =
    {
        videos: [],
        images: [],
        initialPosition: 0,
    };