import React from "react";
import i18n from "../i18n";
import {observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import history from "../helper/browserHistory";
import {updatePageView} from "../helper/util";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";

let companyTypeId = "9cd71820-7b59-44c5-9a71-27daa4d60570";
let companyTypeId1 = "76e14037-643c-4521-b61e-58267344bd0c";
let companyType2Id = "9e9e8a8e-93cf-4897-b91d-b41a2c8f45ab";
//let companyType3Id = "b9f2b13c-e660-4704-bee8-c04135afe8bd";
let companyType4Id = "7d458f37-7c7d-41c2-a860-e9185b87f4f5";

@withTranslation()
@observer
export default class CompanyOverviewPage extends React.Component {

    componentDidMount() {
        updatePageView(this.props.location.pathname);
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <Grid>
                <EditHeaderComponent id={"company-overview-page"}/>
                <Grid.Row className={"no-top-padding primary-background-container"}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider
                                onClick={() => history.push("/")}>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>{i18n.t('titles.company')}</Breadcrumb.Section>
                        </Responsive>
                        <h1 className={"top-row-text"}>{i18n.t('titles.company')}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                        <Grid>
                            <Grid.Row centered>
                                <Grid.Column
                                    computer={4} tablet={5} mobile={5}
                                    onClick={() => history.push("/company/" + companyTypeId)}
                                    textAlign={"center"}
                                    className={"active-sub-menu-item"}>
                                    <Image
                                        className={"sub-menu-item-image"}
                                        src={"https://www.glw.de/images/Unternehmen_Gruendsaetze_passiv.png"}
                                        alt={""}
                                    />
                                    {i18n.t('company_page.principle').toUpperCase()}
                                </Grid.Column>
                                <Grid.Column
                                    computer={4} tablet={5} mobile={5}
                                    onClick={() => history.push("/company/" + companyTypeId1)}
                                    textAlign={"center"}
                                    className={"active-sub-menu-item"}>
                                    <Image
                                        className={"sub-menu-item-image"}
                                        src={"https://www.glw.de/images/Legal_passiv.png"}
                                        alt={""}
                                    />
                                    {i18n.t('company_page.values').toUpperCase()}
                                </Grid.Column>
                                <Grid.Column
                                    computer={4} tablet={5} mobile={5}
                                    onClick={() => history.push("/company/" + companyType2Id)}
                                    textAlign={"center"}
                                    className={"active-sub-menu-item"}>
                                    <Image
                                        className={"sub-menu-item-image"}
                                        src={"https://www.glw.de/images/Unternehmen_Ansprechpartner_passiv.png"}
                                        alt={""}
                                    />
                                    {i18n.t('company_page.contact_persons').toUpperCase()}
                                </Grid.Column>
                                <Grid.Column
                                    computer={4} tablet={5} mobile={5}
                                    onClick={() => history.push("/company/" + companyType4Id)}
                                    textAlign={"center"}
                                    className={"active-sub-menu-item"}>
                                    <Image
                                        className={"sub-menu-item-image"}
                                        src={"https://www.glw.de/images/Unternehmen_Sponsoring_passiv.png"}
                                        alt={""}
                                    />
                                    {i18n.t('company_page.sponsoring').toUpperCase()}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}