import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Form, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";
import config from "../../config/main.config";

let salutations = [
	{ key: "herr", value: "Herr", text: "Herr", textEN: "Mr" },
	{ key: "frau", value: "Frau", text: "Frau", textEN: "Mrs" },
	{ key: "divers", value: "Divers", text: "Divers", textEN: "Mx" },
];
const regexpMail =
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NewsletterModal = (props) => {
	const { i18n } = props;
	const [showNewsletterModal, setShowNewsletterModal] = useState(false);
	const [formData, setFormData] = useState({
		email: "",
		firstName: "",
		lastName: "",
		salutation: "",
	});
	const [formDataError, setFormDataError] = useState({ email: false });
	const [isSuccess, setIsSuccess] = useState(false);
	const [recaptcha, setRecaptcha] = useState(false);

	useEffect(() => {
		if (i18n.language.includes("en")) {
			salutations = [
				{ key: "herr", value: "Herr", text: "Mr" },
				{ key: "frau", value: "Frau", text: "Mrs" },
				{
					key: "divers",
					value: "Divers",
					text: "Mx",
				},
			];
		}
	},[i18n.language]);

	function submitNewsletter() {
		if (!recaptcha) {
			return;
		}
		if (!!formData.email && formData.email.match(regexpMail)) {
			setFormDataError({ email: false });
		} else {
			setFormDataError({
				email: true,
			});
			return;
		}

		fetch(`${config.BASE_URL}newsletter/subscribe`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		}).then((res) => {
			if (res.ok) {
				setIsSuccess(true);
				setTimeout((_) => {
					setShowNewsletterModal(false);
					setIsSuccess(false);
				}, 5000);
			}
		});
	}

	return (
		<div style={{ marginTop: "1rem" }}>
			<Button
				className="glw-button"
				onClick={(_) => setShowNewsletterModal(true)}
			>
				{i18n.t("titles.newsletter")}
			</Button>
			<Modal
				open={showNewsletterModal}
				onClose={(_) => setShowNewsletterModal(false)}
			>
				<Modal.Header>{i18n.t("titles.newsletter_action")}</Modal.Header>
				<Modal.Content>
					{isSuccess ? (
						<div>
							<svg
								style={{ width: "100%" }}
								xmlns="http://www.w3.org/2000/svg"
								className="icon icon-tabler icon-tabler-check"
								width="50"
								height="50"
								viewBox="0 0 25 25"
								strokeWidth="2"
								stroke="green"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M5 12l5 5l10 -10" />
							</svg>
							<p style={{ textAlign: "center" }}>
								{i18n.t("misc.newsletter_success")}
							</p>
						</div>
					) : (
						<Form
							target="_blank"
							onSubmit={(e) => {
								e.preventDefault();
								submitNewsletter();
							}}
						>
							<Form.Group>
								<Form.Select
									value={formData.salutation}
									options={salutations}
									placeholder={i18n.t("apply_form.salutation")}
									onChange={(e, data) => {
										setFormData({
											...formData,
											salutation: data.value,
										});
									}}
									width={5}
									label={i18n.t("apply_form.salutation")}
								/>
								<Form.Input
									value={formData.firstName}
									placeholder={i18n.t("apply_form.first_name")}
									onChange={(e) =>
										setFormData({
											...formData,
											firstName: e.currentTarget.value,
										})
									}
									width={5}
									label={i18n.t("apply_form.first_name")}
								/>
								<Form.Input
									value={formData.lastName}
									placeholder={i18n.t("apply_form.last_name")}
									onChange={(e) =>
										setFormData({
											...formData,
											lastName: e.currentTarget.value,
										})
									}
									width={6}
									label={i18n.t("apply_form.last_name")}
								/>
							</Form.Group>
							<Form.Input
								value={formData.email}
								placeholder={i18n.t("contact_form.email")}
								name="email"
								onChange={(e) =>
									setFormData({
										...formData,
										email: e.currentTarget.value,
									})
								}
								type="email"
								error={formDataError.email}
								required
								label={i18n.t("contact_form.email")}
							/>
							<p>
								{i18n.t("contact_form.terms_conditions")}
								<Link
									onClick={(_) => setShowNewsletterModal(false)}
									to={"/legal#newsletter"}
									className={"clicky-thingy"}
								>
									{i18n.t("contact_form.privacy_link_text")}
								</Link>
							</p>

							<ReCAPTCHA
								onChange={() => setRecaptcha(true)}
								sitekey={"6Lfhcd0SAAAAAOBEHmAVEHJeRnrH8T7wPvvNzEPD"}
								style={{ marginBottom: "1rem" }}
							/>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Button
									color="red"
									onClick={(_) => setShowNewsletterModal(false)}
								>
									{isSuccess ? i18n.t("misc.close") : i18n.t("my_glw.abort")}
								</Button>
								<Button type="submit" color="green" disabled={!recaptcha}>
									{i18n.t("titles.newsletter")}
								</Button>
							</div>
						</Form>
					)}
				</Modal.Content>
			</Modal>
		</div>
	);
};

export default withTranslation()(NewsletterModal);
