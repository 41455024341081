import {observer} from "mobx-react";
import React from "react";
import i18n from "../i18n";
import {observable} from "mobx";
import EditTextComponent from "../moap-components/EditTextComponent";
import {withTranslation} from "react-i18next";
import EditEditorComponent from "../moap-components/EditHTMLComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import config from "../../config/main.config"
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

const textStyle = {
    "fontSize": "16px"
};

@withTranslation()
@observer
export default class PrincipleSubPage extends React.Component {

    @observable
    principleType = 0;

    @observable
    isSafari = false;

    handlePrincipleTypeChange(value) {
        this.principleType = value;
    }

    componentDidMount() {
        if (typeof document !== "undefined") {
            this.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
        }
    }

    switchPrincipleType(principleType) {
        switch (principleType) {
            case 0:
                return (<EditTextComponent id={"principle-family_business-text"} style={textStyle}/>);
            case 1:
                return (<EditTextComponent id={"principle-customers-text"} style={textStyle}/>);
            case 2:
                return (<EditTextComponent id={"principle-employee-text"} style={textStyle}/>);
            case 3:
                return (<div>
                    <EditEditorComponent id={"codex-text"} style={textStyle}/>
                    <a className={"ui button"} style={{marginTop:"1rem"}} download={i18n.language === "de-DE" ? "GLW-Verhaltenskodex.pdf" : "GLW-Code-of-Conduct.pdf"}
                       href={i18n.language === "de-DE" ? `${config.PUBLIC_URL}pdf/Verhaltenskodex_GLW_DE.pdf` : `${config.PUBLIC_URL}pdf/Verhaltenskodex_GLW_EN.pdf`}>{i18n.t("misc.download_code_of_conduct")}</a>
                </div>);
            case 4:
                return (<EditEditorComponent id={"principle-conflict-text"} style={textStyle}/>);
        }
    }


    render() {

        return (
            <Grid>
                <Grid.Row centered>
                    <Grid.Column computer={3} tablet={5} mobile={6}
                                 textAlign={"center"}
                                 style={{"paddingBottom": "10px"}}
                                 className={this.principleType === 0 ? "light-border" : ""}
                                 onClick={() => this.handlePrincipleTypeChange(0)}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.principleType === 0 ?
                                "https://www.glw.de/images/Familienbetrieb_aktiv.png"
                                :
                                "https://www.glw.de/images/Familienbetrieb_passiv.png"}
                            alt={""}
                        />
                        <label
                            className={this.principleType === 0 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                            {i18n.t('principle_subpage.family_business').toUpperCase()}
                        </label>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={5} mobile={6}
                                 textAlign={"center"}
                                 style={{"paddingBottom": "10px"}}
                                 className={this.principleType === 1 ? "light-border" : ""}
                                 onClick={() => this.handlePrincipleTypeChange(1)}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.principleType === 1 ?
                                "https://www.glw.de/images/Kunden_aktiv.png"
                                :
                                "https://www.glw.de/images/Kunden_passiv.png"}
                            alt={""}
                        />
                        <label
                            className={this.principleType === 1 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                            {i18n.t('principle_subpage.customers').toUpperCase()}
                        </label>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={5} mobile={6}
                                 textAlign={"center"}
                                 style={{"paddingBottom": "10px"}}
                                 className={this.principleType === 2 ? "light-border" : ""}
                                 onClick={() => this.handlePrincipleTypeChange(2)}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.principleType === 2 ?
                                "https://www.glw.de/images/Unternehmen_Ansprechpartner_aktiv.png"
                                :
                                "https://www.glw.de/images/Unternehmen_Ansprechpartner_passiv.png"}
                            alt={""}
                        />
                        <label
                            className={this.principleType === 2 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                            {i18n.t('principle_subpage.employee').toUpperCase()}
                        </label>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={5} mobile={6}
                                 textAlign={"center"}
                                 style={{"paddingBottom": "10px"}}
                                 className={this.principleType === 3 ? "light-border" : ""}
                                 onClick={() => this.handlePrincipleTypeChange(3)}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.principleType === 3 ?
                                "https://www.glw.de/images/Fairness_aktiv.png"
                                :
                                "https://www.glw.de/images/Fairness_passiv.png"}
                            alt={""}
                        />
                        <label
                            className={this.principleType === 3 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                            {i18n.t('principle_subpage.code_of_conduct').toUpperCase()}
                        </label>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={5} mobile={6}
                                 textAlign={"center"}
                                 style={{"paddingBottom": "10px"}}
                                 className={this.principleType === 4 ? "light-border" : ""}
                                 onClick={() => this.handlePrincipleTypeChange(4)}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.principleType === 4 ?
                                "https://www.glw.de/images/Konfliktmineralien_aktiv.png"
                                :
                                "https://www.glw.de/images/Konfliktmineralien_passiv.png"}
                            alt={""}
                        />
                        <label
                            className={this.principleType === 4 ? "active-product-type clicky-thingy" : "clicky-thingy"}>
                            {i18n.t('principle_subpage.conflict_minerals').toUpperCase()}
                        </label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={12} tablet={14} mobile={16} textAlign={"center"}
                                 style={{paddingTop: "25px", paddingBottom: "25px"}}>
                        {this.switchPrincipleType(this.principleType)}
                    </Grid.Column>
                </Grid.Row>
                <Divider/>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Inside GLW</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={8} tablet={10} mobile={16} style={{minHeight: "280px"}}>
                        <iframe
                            data-src='https://www.youtube-nocookie.com/embed/sXOjN2NZz7w'
                            src={typeof Cookiebot !== "undefined" ? Cookiebot.consent.marketing ? "https://www.youtube-nocookie.com/embed/sXOjN2NZz7w" : null : null}
                            data-cookieconsent="marketing"
                            data-cookieblock-src="https://www.youtube-nocookie.com/embed/sXOjN2NZz7w"
                            allowFullScreen={!this.isSafari}
                            title='video'
                            style={{"height": "100%", "width": "100%", "zIndex": "3"}}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}