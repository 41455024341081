import React from "react";
import i18n from "../i18n";
import {inject, observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import {observable} from "mobx";
import config from "../../config/main.config";
import history from "../helper/browserHistory";
import {regexpMail, updatePageView} from "../helper/util";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";

@withTranslation()
@inject("stores")
@observer
export default class ContactPage extends React.Component {

    @observable
    firm = "";
    @observable
    street = "";
    @observable
    contactPerson = "";
    @observable
    zipCode = "";
    @observable
    phone = "";
    @observable
    email = "";
    @observable
    country = "";
    @observable
    message = "";
    @observable
    conditionsChecked = false;
    @observable
    subscribeNewsletter = false;
    @observable
    firmError = false;
    @observable
    streetError = false;
    @observable
    contactPersonError = false;
    @observable
    zipCodeError = false;
    @observable
    phoneError = false;
    @observable
    emailError = false;
    @observable
    countryError = false;
    @observable
    messageError = false;
    @observable
    conditionsCheckedError = false;

    @observable
    modalOpen = false;
    @observable
    modalMessage = "";

    componentDidMount() {
        updatePageView(this.props.location.pathname);
        window.scrollTo(0, 0);
    }

    handleSearchChange(type, event, data) {
        switch (type) {
            case "firm":
                this.firm = data.value;
                break;
            case "street":
                this.street = data.value;
                break;
            case "contactPerson":
                this.contactPerson = data.value;
                break;
            case "zipCode":
                this.zipCode = data.value;
                break;
            case "phone":
                //  if (data.value.match(regexpPhone) || data.value === '') {
                this.phone = data.value;
                // }
                break;
            case "email":
                this.email = data.value;
                break;
            case "country":
                this.country = data.value;
                break;
            case "message":
                this.message = data.value;
                break;
        }
    }

    setBasketToMessage() {
        let language = "de-DE";

        if (i18n) {
            language = this.props.i18n.language;
        }

        let basketItems = this.props.stores.basketStore.basket;
        if (basketItems.length > 0 && this.message.length > 0) {
            this.message += "\n";
        }
        for (let i = 0; i < basketItems.length; i++) {
            this.message += (language === "en-UK" ? basketItems[i].nameEn : basketItems[i].name) + " (" + basketItems[i].itemNumber + ") \n";
        }

    }

    submitMessage() {
        let canSend = true;
        //first validate data
        if (this.firm.length > 0) {
            this.firmError = false;
        } else {
            this.firmError = true;
            canSend = false;
        }

        if (this.contactPerson.length > 0) {
            this.contactPersonError = false;
        } else {
            this.contactPersonError = true;
            canSend = false;
        }

        /* if (this.phone.length > 0) {
             this.phoneError = false;
         } else {
             this.phoneError = true;
             canSend = false;
         }*/

        if (this.email.length > 0 && this.email.match(regexpMail)) {
            this.emailError = false;
        } else {
            this.emailError = true;
            canSend = false;
        }

        /*if (this.conditionsChecked) {
            this.conditionsCheckedError = false;
        } else {
            this.conditionsCheckedError = true;
            canSend = false;
        }*/

        let language = "de-DE";

        if (i18n) {
            language = this.props.i18n.language;
        }


        if (canSend) {
            //Send to server
            let data = {
                firm: this.firm,
                language: language,
                street: this.street,
                contactPerson: this.contactPerson,
                zipCode: this.zipCode,
                phone: this.phone,
                email: this.email,
                country: this.country,
                message: this.message,
                subscribeNewsletter: this.subscribeNewsletter
            };

            let self = this;

            fetch(config.BASE_URL + 'contact', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        if (json.hasOwnProperty("success") && json) {
                            self.modalMessage = i18n.t('contact_form.response_success');
                        } else {
                            self.modalMessage = i18n.t('contact_form.response_error');
                        }
                        self.modalOpen = true;
                    });
                } else {
                    self.modalMessage = i18n.t('contact_form.response_error');
                    self.modalOpen = true;
                }
            }).catch(() => {
                self.modalMessage = i18n.t('contact_form.response_error');
                self.modalOpen = true;
            });
        }
    }


    getCountryOptions(){
        const countries = [
            {name: "Afghanistan", EN: "Afghanistan"},
            {name: "Ägypten", EN: "Egypt"},
            {name: "Albanien", EN: "Albania"},
            {name: "Algerien", EN: "Algeria"},
            {name: "Andorra", EN: "Andorra"},
            {name: "Angola", EN: "Angola"},
            {name: "Antigua und Barbuda", EN: "Antigua and Barbuda"},
            {name: "Äquatorialguinea", EN: "Equatorial Guinea"},
            {name: "Argentinien", EN: "Argentina"},
            {name: "Armenien", EN: "Armenia"},
            {name: "Aserbaidschan", EN: "Azerbaijan"},
            {name: "Äthiopien", EN: "Ethiopia"},
            {name: "Australien", EN: "Australia"},
            {name: "Bahamas", EN: "Bahamas"},
            {name: "Bahrain", EN: "Bahrain"},
            {name: "Bangladesch", EN: "Bangladesh"},
            {name: "Barbados", EN: "Barbados"},
            {name: "Belarus", EN: "Belarus"},
            {name: "Belgien", EN: "Belgium"},
            {name: "Belize", EN: "Belize"},
            {name: "Benin", EN: "Benin"},
            {name: "Bhutan", EN: "Bhutan"},
            {name: "Bolivien", EN: "Bolivia"},
            {name: "Bosnien und Herzegowina", EN: "Bosnia and Herzegovina"},
            {name: "Botswana", EN: "Botswana"},
            {name: "Brasilien", EN: "Brazil"},
            {name: "Brunei", EN: "Brunei"},
            {name: "Bulgarien", EN: "Bulgaria"},
            {name: "Burkina Faso", EN: "Burkina Faso"},
            {name: "Burundi", EN: "Burundi"},
            {name: "Chile", EN: "Chile"},
            {name: "China, Volksrepublik", EN: "China, People's Republic"},
            {name: "Costa Rica", EN: "Costa Rica"},
            {name: "Dänemark", EN: "Denmark"},
            {name: "Deutschland", EN: "Germany"},
            {name: "Dominica", EN: "Dominica"},
            {name: "Dominikanische Republik", EN: "Dominican Republic"},
            {name: "Dschibuti", EN: "Djibouti"},
            {name: "Ecuador", EN: "Ecuador"},
            {name: "Elfenbeinküste", EN: "Ivory Coast"},
            {name: "El Salvador", EN: "El Salvador"},
            {name: "Eritrea", EN: "Eritrea"},
            {name: "Estland", EN: "Estonia"},
            {name: "Eswatini", EN: "Eswatini"},
            {name: "Fidschi", EN: "Fiji"},
            {name: "Finnland", EN: "Finland"},
            {name: "Frankreich", EN: "France"},
            {name: "Gabun", EN: "Gabon"},
            {name: "Gambia", EN: "Gambia"},
            {name: "Georgien", EN: "Georgia"},
            {name: "Ghana", EN: "Ghana"},
            {name: "Grenada", EN: "Grenada"},
            {name: "Griechenland", EN: "Greece"},
            {name: "Guatemala", EN: "Guatemala"},
            {name: "Guinea", EN: "Guinea"},
            {name: "Guinea-Bissau", EN: "Guinea-Bissau"},
            {name: "Guyana", EN: "Guyana"},
            {name: "Haiti", EN: "Haiti"},
            {name: "Honduras", EN: "Honduras"},
            {name: "Indien", EN: "India"},
            {name: "Indonesien", EN: "Indonesia"},
            {name: "Irak", EN: "Iraq"},
            {name: "Iran", EN: "Iran"},
            {name: "Irland", EN: "Ireland"},
            {name: "Island", EN: "Iceland"},
            {name: "Israel", EN: "Israel"},
            {name: "Italien", EN: "Italy"},
            {name: "Jamaika", EN: "Jamaica"},
            {name: "Japan", EN: "Japan"},
            {name: "Jemen", EN: "Yemen"},
            {name: "Jordanien", EN: "Jordan"},
            {name: "Kambodscha", EN: "Cambodia"},
            {name: "Kamerun", EN: "Cameroon"},
            {name: "Kanada", EN: "Canada"},
            {name: "Kap Verde", EN: "Cape Verde"},
            {name: "Kasachstan", EN: "Kazakhstan"},
            {name: "Katar", EN: "Qatar"},
            {name: "Kenia", EN: "Kenya"},
            {name: "Kirgisistan", EN: "Kyrgyzstan"},
            {name: "Kiribati", EN: "Kiribati"},
            {name: "Kolumbien", EN: "Colombia"},
            {name: "Komoren", EN: "Comoros"},
            {name: "Kongo, Demokratische Republik", EN: "Congo, Democratic Republic"},
            {name: "Kongo, Republik", EN: "Congo, Republic"},
            {name: "Korea, Nord (Nordkorea)", EN: "Korea, North (North Korea)"},
            {name: "Korea, Süd (Südkorea)", EN: "Korea, South (South Korea)"},
            {name: "Kroatien", EN: "Croatia"},
            {name: "Kuba", EN: "Cuba"},
            {name: "Kuwait", EN: "Kuwait"},
            {name: "Laos", EN: "Laos"},
            {name: "Lesotho", EN: "Lesotho"},
            {name: "Lettland", EN: "Latvia"},
            {name: "Libanon", EN: "Lebanon"},
            {name: "Liberia", EN: "Liberia"},
            {name: "Libyen", EN: "Libya"},
            {name: "Liechtenstein", EN: "Liechtenstein"},
            {name: "Litauen", EN: "Lithuania"},
            {name: "Luxemburg", EN: "Luxembourg"},
            {name: "Madagaskar", EN: "Madagascar"},
            {name: "Malawi", EN: "Malawi"},
            {name: "Malaysia", EN: "Malaysia"},
            {name: "Malediven", EN: "Maldives"},
            {name: "Mali", EN: "Mali"},
            {name: "Malta", EN: "Malta"},
            {name: "Marokko", EN: "Morocco"},
            {name: "Marshallinseln", EN: "Marshall Islands"},
            {name: "Mauretanien", EN: "Mauritania"},
            {name: "Mauritius", EN: "Mauritius"},
            {name: "Mexiko", EN: "Mexico"},
            {name: "Mikronesien", EN: "Micronesia"},
            {name: "Moldau", EN: "Moldova"},
            {name: "Monaco", EN: "Monaco"},
            {name: "Mongolei", EN: "Mongolia"},
            {name: "Montenegro", EN: "Montenegro"},
            {name: "Mosambik", EN: "Mozambique"},
            {name: "Myanmar", EN: "Myanmar"},
            {name: "Namibia", EN: "Namibia"},
            {name: "Nauru", EN: "Nauru"},
            {name: "Nepal", EN: "Nepal"},
            {name: "Neuseeland", EN: "New Zealand"},
            {name: "Nicaragua", EN: "Nicaragua"},
            {name: "Niederlande", EN: "Netherlands"},
            {name: "Niger", EN: "Niger"},
            {name: "Nigeria", EN: "Nigeria"},
            {name: "Nordmazedonien", EN: "North Macedonia"},
            {name: "Norwegen", EN: "Norway"},
            {name: "Oman", EN: "Oman"},
            {name: "Österreich", EN: "Austria"},
            {name: "Osttimor", EN: "East Timor"},
            {name: "Pakistan", EN: "Pakistan"},
            {name: "Palau", EN: "Palau"},
            {name: "Panama", EN: "Panama"},
            {name: "Papua-Neuguinea", EN: "Papua New Guinea"},
            {name: "Paraguay", EN: "Paraguay"},
            {name: "Peru", EN: "Peru"},
            {name: "Philippinen", EN: "Philippines"},
            {name: "Polen", EN: "Poland"},
            {name: "Portugal", EN: "Portugal"},
            {name: "Ruanda", EN: "Rwanda"},
            {name: "Rumänien", EN: "Romania"},
            {name: "Russland", EN: "Russia"},
            {name: "Salomonen", EN: "Solomon Islands"},
            {name: "Sambia", EN: "Zambia"},
            {name: "Samoa", EN: "Samoa"},
            {name: "San Marino", EN: "San Marino"},
            {name: "São Tomé und Príncipe", EN: "São Tomé and Príncipe"},
            {name: "Saudi-Arabien", EN: "Saudi Arabia"},
            {name: "Schweden", EN: "Sweden"},
            {name: "Schweiz", EN: "Switzerland"},
            {name: "Senegal", EN: "Senegal"},
            {name: "Serbien", EN: "Serbia"},
            {name: "Seychellen", EN: "Seychelles"},
            {name: "Sierra Leone", EN: "Sierra Leone"},
            {name: "Simbabwe", EN: "Zimbabwe"},
            {name: "Singapur", EN: "Singapore"},
            {name: "Slowakei", EN: "Slovakia"},
            {name: "Slowenien", EN: "Slovenia"},
            {name: "Somalia", EN: "Somalia"},
            {name: "Spanien", EN: "Spain"},
            {name: "Sri Lanka", EN: "Sri Lanka"},
            {name: "St. Kitts und Nevis", EN: "St. Kitts and Nevis"},
            {name: "St. Lucia", EN: "St. Lucia"},
            {name: "St. Vincent und die Grenadinen", EN: "St. Vincent and the Grenadines"},
            {name: "Südafrika", EN: "South Africa"},
            {name: "Sudan", EN: "Sudan"},
            {name: "Südsudan", EN: "South Sudan"},
            {name: "Suriname", EN: "Suriname"},
            {name: "Syrien", EN: "Syria"},
            {name: "Tadschikistan", EN: "Tajikistan"},
            {name: "Tansania", EN: "Tanzania"},
            {name: "Thailand", EN: "Thailand"},
            {name: "Togo", EN: "Togo"},
            {name: "Tonga", EN: "Tonga"},
            {name: "Trinidad und Tobago", EN: "Trinidad and Tobago"},
            {name: "Tschad", EN: "Chad"},
            {name: "Tschechien", EN: "Czech Republic"},
            {name: "Tunesien", EN: "Tunisia"},
            {name: "Türkei", EN: "Turkey"},
            {name: "Turkmenistan", EN: "Turkmenistan"},
            {name: "Tuvalu", EN: "Tuvalu"},
            {name: "Uganda", EN: "Uganda"},
            {name: "Ukraine", EN: "Ukraine"},
            {name: "Ungarn", EN: "Hungary"},
            {name: "Uruguay", EN: "Uruguay"},
            {name: "Usbekistan", EN: "Uzbekistan"},
            {name: "Vanuatu", EN: "Vanuatu"},
            {name: "Venezuela", EN: "Venezuela"},
            {name: "Vereinigte Arabische Emirate", EN: "United Arab Emirates"},
            {name: "Vereinigte Staaten", EN: "United States"},
            {name: "Vereinigtes Königreich", EN: "United Kingdom"},
            {name: "Vietnam", EN: "Vietnam"},
            {name: "Zentralafrikanische Republik", EN: "Central African Republic"},
            {name: "Zypern", EN: "Cyprus"}
        ];

        return countries.map((country, index) => {
            return {
                key: index,
                value: country.name,
                text: i18n.language.startsWith("de") ? country.name : country.EN
            };
        });
    }

    render() {


        return (
            <Grid>
                <EditHeaderComponent id={"contact-page"}/>
                <Modal
                    style={{"margin": "auto"}}
                    open={this.modalOpen}
                    onClose={() => this.modalOpen = false}
                    size='small'>
                    <Modal.Content>
                        <h3>{this.modalMessage}</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={() => history.push("/")}>
                            <Icon name='checkmark'/> OK
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Grid.Row className={"no-top-padding primary-background-container"} style={{"zIndex": "2"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider
                                onClick={() => history.push("/")}>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            <Breadcrumb.Section>{i18n.t('titles.contact').toUpperCase()}</Breadcrumb.Section>
                        </Responsive>
                        <h1 className={"top-row-text"}>{i18n.t('titles.contact_form').toUpperCase()}</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"products-container"}>
                        <Grid padded>
                            <Grid.Row>
                                <Grid.Column style={{"paddingTop": "15px"}}>
                                    {i18n.t('contact_form.title').split('\n').map(line => <h3>{line}</h3>)}
                                    <Form>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.firm')}
                                                placeholder={i18n.t('contact_form.firm') + "*"}
                                                value={this.firm}
                                                error={this.firmError}
                                                onChange={this.handleSearchChange.bind(this, "firm")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.contact_person')}
                                                placeholder={i18n.t('contact_form.contact_person') + "*"}
                                                value={this.contactPerson}
                                                error={this.contactPersonError}
                                                onChange={this.handleSearchChange.bind(this, "contactPerson")}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.street')}
                                                placeholder={i18n.t('contact_form.street')}
                                                value={this.street}
                                                error={this.streetError}
                                                onChange={this.handleSearchChange.bind(this, "street")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.postal_code')}
                                                placeholder={i18n.t('contact_form.postal_code')}
                                                value={this.zipCode}
                                                error={this.zipCodeError}
                                                onChange={this.handleSearchChange.bind(this, "zipCode")}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.phone')}
                                                placeholder={i18n.t('contact_form.phone')}
                                                value={this.phone}
                                                error={this.phoneError}
                                                onChange={this.handleSearchChange.bind(this, "phone")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.email')}
                                                placeholder={i18n.t('contact_form.email') + "*"}
                                                value={this.email}
                                                error={this.emailError}
                                                onChange={this.handleSearchChange.bind(this, "email")}
                                            />
                                        </Form.Group>
                                        <Form.Field width={8}>
                                            <Form.Select
                                                label={i18n.t('contact_form.country')}
                                                placeholder={i18n.t('contact_form.country')}
                                                value={this.country}
                                                error={this.countryError}
                                                options={this.getCountryOptions()}
                                                onChange={this.handleSearchChange.bind(this, "country")}/>
                                        </Form.Field>
                                        <Form.TextArea
                                            label={i18n.t('contact_form.message')}
                                            value={this.message}
                                            error={this.messageError}
                                            onChange={this.handleSearchChange.bind(this, "message")}
                                        />
                                        <Form.Checkbox label={i18n.t("contact_form.newsletter")}
                                                       checked={this.subscribeNewsletter}
                                                       onChange={_ => this.subscribeNewsletter = !this.subscribeNewsletter}/>
                                        <Form.Field
                                            error={this.conditionsCheckedError}>
                                            {/*
                                            <Checkbox
                                                style={{"marginTop": "10px", "marginRight": "5px"}}
                                                onChange={() => this.conditionsChecked = !this.conditionsChecked}
                                                checked={this.conditionsChecked}
                                            />
                                        */}
                                            {i18n.t('contact_form.terms_conditions')}
                                            <a onClick={() => history.push("/legal")}
                                               className={"clicky-thingy"}>
                                                {i18n.t('contact_form.privacy_link_text')}
                                            </a>
                                        </Form.Field>
                                        <Button type='submit'
                                                className={"glw-button"}
                                                onClick={() => this.submitMessage()}>{i18n.t('contact_form.submit')}
                                        </Button>
                                        <p>{i18n.t("apply_form.data_privacy")}
                                        <a  onClick={() => history.push("/information")}
                                               className={"clicky-thingy"}>{i18n.t("contact_form.privacy_link_title")}</a>
                                        {i18n.t("apply_form.data_privacy_2")}
                                        <a  onClick={() => history.push("/legal")}
                                               className={"clicky-thingy"}>{i18n.t("contact_form.privacy_link_text")}</a>
                                        </p>
                                        <p>* = {i18n.t('contact_form.mandatory')}</p>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}