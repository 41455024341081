const fetch = require('node-fetch');

module.exports = {
    loadTexts,
    loadProductData,
    loadContentWithFrontendId,
    loadProducts
}

function loadTexts(baseURL) {
    return fetch(baseURL + "/api/texts", {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 400) {
            return response.json();
        } else {
            return {
                error: "400"
            }
        }

    }).catch(error => {
        return {
            error: "400"
        }
    });
}

function loadProductData(data, baseUrl) {
    const {id} = data.params;

    if (typeof id !== 'undefined') {
        return fetch(baseUrl + "products/detail/" + id, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            return response.json().then(result => {
                return {
                    dataForPage: result
                }
            }).catch((error) => {
                return {
                    error: "400"
                }
            });
        }).catch(
            error => {
                return {
                    statusCode: 400
                }
            }
        );
    } else {
        return {
            statusCode: 400
        }
    }
}

function loadContentWithFrontendId(baseURL, frontendId) {
    return fetch(baseURL + "texts/" + frontendId, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'pragma': 'no-cache',
            'cache-control': 'no-cache',
            'Access-Control-Allow-Origin': '*'
        }
    }).then((response) => {
        if (response.status >= 200 && response.status < 400) {
            return response.json().then(result => {
                return {
                    texts: result
                }
            }).catch((error) => {
                return {
                    error: "400"
                }
            });
        } else {
            return {
                error: "400"
            }
        }

    }).catch(error => {
        return {
            error: "400"
        }
    });
}

function loadProducts(baseURL) {
    return fetch(baseURL + "products", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json().then(result => {
                    return {
                        dataForPage: result
                    }
                }).catch((error) => {
                    return {
                        error: "400"
                    }
                });
            } else {
                return {
                    error: "400"
                }
            }
        })
        .catch(
            error => {
                console.log("!!!!!!!!!!!!!!! FETCH ERROR")
                return {
                    error: "400"
                }
            }
        );

}