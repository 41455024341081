import React from "react";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import config from "../../config/main.config";
import i18n from "../i18n";
import history from "../helper/browserHistory";
import {withTranslation} from "react-i18next";
import ProductImageGalleryComponent from "../components/ProductImageGalleryComponent";
import ReactImageMagnify from "react-image-magnify";
import {diesValues, diesValuesEn, showBlobFile, updatePageView} from "../helper/util";
import {Helmet} from 'react-helmet-async';
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import List from "semantic-ui-react/dist/commonjs/elements/List";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";
import CardDownloadsComponent from "../components/CardDownloadsComponent";
import parse from "html-react-parser";

const LogoIcon = "https://www.glw.de/images/logo-icon.png";

let productTypeId = "0f6866f7-e3a9-4b6b-af8c-bfabb9b088cb";
let productType2Id = "63362656-e5af-4860-a9ec-e1898f82b42b";
let productType3Id = "a7bf7a85-b595-44a9-a492-4d48d1c395dc";
let productType4Id = "1bbf1dfb-74df-4927-b3bd-f746535db5f8";
let productType5Id = "91e84278-8002-428b-95f5-c4515fc744e2";
let productType6Id = "f39e81ae-1f6d-4d82-b135-1f24b3d18c15";
let productType7Id = "0ea1c24c-c667-4340-8873-c65bf5d0e77d";
let productType8Id = "d209fd49-0402-4b9e-bcc1-c7622b04b1a5";
let productType9Id = "571364ab-792a-4926-b206-b38944e281dd";


@inject("stores")
@withTranslation()
@observer
export default class ProductDetailPage extends React.Component {

    @observable
    loading = true;

    @observable
    showSubMenu = false;

    @observable
    galleryPosition = 0;

    @observable
    diesType = -1;

    @observable
    activeIndex = -1;

    constructor(props) {
        super(props);
        if (props.staticContext && props.staticContext.hasOwnProperty("name")) {
            this.state = {
                product: props.staticContext
            };
        } else {
            this.state = {
                product: {
                    name: "",
                    itemNumber: "",
                    subtitle: "",
                    productType: "",
                    link: "",
                    productDescription: "",
                    productDescriptionEn: "",
                    technicalSpecs: [],
                    productSpecs: [],
                    productScope: [],
                    productEquipment: [],
                    videoLinks: [],
                    imageLinks: [],
                    productInformations: [],
                    versions: [],
                    madeIn: 0
                }
            };
        }
    }


    componentDidMount() {
        updatePageView(this.props.location.pathname);
        let id = this.props.match.params.id;
        let name = ""
        if (this.props.location.search) {
            name = this.props.location.search;
        }
        this.fetchData(id, name);
        window.scrollTo(0, 0);

        history.listen(location => {
            if (location.pathname.includes("product-details")) {
                let id = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
                this.fetchData(id);
            }
        });
    }


    getDiesType(product) {
        let diesType = -1;
        let nameArray = product.name.split(" ");
        if (nameArray.length > 0) {
            let lastItem = nameArray[nameArray.length - 1];
            if (lastItem.startsWith("TWIN") && nameArray.length >= 2) {
                lastItem = nameArray[nameArray.length - 2];
            }
            for (let i = 0; i < diesValues.length; i++) {
                if (lastItem.startsWith(diesValues[i].startsWith)) {
                    diesType = i;
                }
            }
        }

        return diesType;
    }

    getGalleryPosition() {
        //check search for saved filter
        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        let position = params.get('pos');
        if (position === "start") {
            //get start position
            let tmpPos = 0;
            for (let i = 0; i < this.state.product.imageLinks.length; i++) {
                if (this.state.product.imageLinks[i].start) {
                    tmpPos = i;
                }
            }
            for (let i = 0; i < this.state.product.videoLinks.length; i++) {
                if (this.state.product.videoLinks[i].start) {
                    tmpPos = i + this.state.product.imageLinks.length;
                }
            }
            this.galleryPosition = tmpPos;
        } else if (/^\d+$/.test(position)) {
            this.galleryPosition = parseInt(position);
        } else {
            this.galleryPosition = 0;
        }

    }

    fetchData(id, name = "") {
        // window.scrollTo(0, 0);
        fetch(config.BASE_URL + 'products/detail/' + id + name, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(product => {
                if ((product.hasOwnProperty("published") && !product.published)) {
                    history.replace("/404")
                }
                if (product.hasOwnProperty("name")) {
                    this.diesType = this.getDiesType(product);
                    this.setState({
                        ...this.state,
                        product: product
                    });
                    this.getGalleryPosition();
                }
                this.loading = false;

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getLanguageTecSpecs(language) {
        if (this.state.product.productTypeId === productType3Id) {
            let langTecSpecs = [
                <Grid.Row key={"ader9"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        {i18n.t('wire_end.cross_section')}:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {language === "en-UK" ? this.state.product.crossSection : this.toGermanFloat(this.state.product.crossSection)}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row key={"ader10"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        AWG:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {this.state.product.awg}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row key={"ader8"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        {i18n.t('wire_end.length')}:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {language === "en-UK" ? this.state.product.length : this.toGermanFloat(this.state.product.length)}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row key={"ader7"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        {i18n.t('wire_end.color')}:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {language === "en-UK" ? this.state.product.colorEn : this.state.product.color}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row key={"ader1"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        {i18n.t('wire_end.material')}:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {this.state.product.material}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row key={"ader2"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        {i18n.t('wire_end.surface')}:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {language === "en-UK" ? this.state.product.surfaceEn : this.state.product.surface}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row key={"ader4"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        {i18n.t('wire_end.min_thick')}:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {this.state.product.minThick}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row key={"ader3"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        {i18n.t('wire_end.max_temp')}:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {this.state.product.maxTemp}
                    </Grid.Column>
                </Grid.Row>,
                <Grid.Row key={"ader3"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        {i18n.t('wire_end.ul_tools')}:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {this.state.product.ul_tools !== "" && <ul style={{padding: 0}}>
                            {this.state.product.ul_tools.split(", ").map((ul_tool) => <li>{parse(ul_tool)}</li>)}
                        </ul>}
                    </Grid.Column>
                </Grid.Row>
            ]

            if (this.state.product.cert) {
                langTecSpecs.push(<Grid.Row key={"ader5"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        {i18n.t('wire_end.cert')}:
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        <a
                            onClick={() => window.open("https://iq2.ulprospector.com/session/new?redirect=http%3a%2f%2fiq.ulprospector.com%2fen%2fsearch%2flegacy%3furl%3dshowpage.html%25253Fname%25253DZMLF.E485383%252526ccnshorttitle%25253DBare%2band%2bCovered%2bFerrules%252526objid%25253D1086155863%252526cfgid%25253D1073741824%252526version%25253Dversionless%252526parent_id%25253D1084141507%252526sequence%25253D1")}
                            className={"clicky-thingy"}>
                            UL
                        </a>
                    </Grid.Column>
                </Grid.Row>)
            }

            if (this.state.product.reach) {
                langTecSpecs.push(<Grid.Row key={"ader6"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        <span>REACH/RoHS:</span>
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {i18n.t('wire_end.approved')}
                    </Grid.Column>
                </Grid.Row>)
            }
            if (this.state.product.din.length > 0) {
                langTecSpecs.push(<Grid.Row key={"ader6"} className={"technical-spec-row"}>
                    <Grid.Column width={8} className={"technical-spec-row-column ferrule-col"}>
                        <span>DIN:</span>
                    </Grid.Column>
                    <Grid.Column width={8} className={"ferrule-col"}>
                        {this.state.product.din}
                    </Grid.Column>
                </Grid.Row>)
            }


            return langTecSpecs;
        } else {
            let langTecSpecs = [];
            if (this.state.product.hasOwnProperty("technicalSpecs")) {
                for (let i = 0; i < this.state.product.technicalSpecs.length; i++) {
                    let spec = this.state.product.technicalSpecs[i];
                    if (language === spec.language) {
                        langTecSpecs.push(
                            <Grid.Row key={i} className={"technical-spec-row"}>
                                <Grid.Column width={8} className={"technical-spec-row-column"}>
                                    {spec.type}:
                                </Grid.Column>
                                <Grid.Column width={8}
                                             style={{"white-space": "pre-wrap"}}>
                                    {spec.description}
                                </Grid.Column>
                            </Grid.Row>
                        )
                    }
                }
            }

            return langTecSpecs;
        }

    }

    getLanguageProdSpecs(language) {
        let langTecSpecs = [];

        if (this.state.product.productTypeId === productType3Id) {
            if (this.state.product.subtitle.length !== 0) {
                langTecSpecs.push(
                    <List.Item className={"flex-list-item"}>
                        <Image avatar src={LogoIcon} verticalAlign='middle'
                               alt={""}
                               style={{"height": "20px", "width": "20px"}}/>
                        <List.Content>
                            <span>{language === "en-UK" ? this.state.product.subtitleEn : this.state.product.subtitle}</span>
                        </List.Content>
                    </List.Item>
                )
            }
            return langTecSpecs;
        }

        if (this.state.product.hasOwnProperty("productSpecs")) {
            for (let i = 0; i < this.state.product.productSpecs.length; i++) {
                let spec = this.state.product.productSpecs[i];
                if (language === spec.language) {
                    langTecSpecs.push(
                        <List.Item className={"flex-list-item"} key={i}>
                            <Image avatar src={LogoIcon} verticalAlign='middle'
                                   alt={""}
                                   style={{"height": "20px", "width": "20px"}}/>
                            <List.Content>
                                <span>{spec.description}</span>
                            </List.Content>
                        </List.Item>
                    )
                }
            }
        }

        //check if there are linked Locators in Gesenke
        if (this.state.product.productTypeId === productType8Id) {
            let locators = [];

            if (this.state.product.hasOwnProperty("productEquipment")) {
                for (let i = 0; i < this.state.product.productEquipment.length; i++) {
                    let spec = this.state.product.productEquipment[i];
                    if (spec.hasOwnProperty("typeId") && spec.typeId === productType9Id) {
                        locators.push(<span
                            key={"gel" + i}
                            style={{"text-decoration": "underline", cursor: "pointer", paddingLeft: "15px"}}
                            onClick={() => history.push("/product-details/" + encodeURIComponent(spec.title.replace("/", "||")))}>
                            {language === "en-UK" ? spec.titleEn : spec.title} <br/>
                        </span>);
                    }
                }
                if (locators.length > 0) {
                    langTecSpecs.push(
                        <List.Item className={"flex-list-item"} key={"loc-link"}>
                            <Image avatar src={LogoIcon} verticalAlign='middle'
                                   alt={""}
                                   style={{"height": "20px", "width": "20px"}}/>
                            <List.Content>
                                <span>{i18n.t('products.fitting_locators')}: <br/> {locators}</span>
                            </List.Content>
                        </List.Item>
                    )
                }
            }
        }

        //check if there are linked Gesenke in Locators

        if (this.state.product.productTypeId === productType9Id) {
            let dies = [];
            if (this.state.product.hasOwnProperty("productEquipment")) {
                for (let i = 0; i < this.state.product.productEquipment.length; i++) {
                    let spec = this.state.product.productEquipment[i];
                    if (spec.hasOwnProperty("typeId") && spec.typeId === productType8Id) {
                        dies.push(<span
                            key={"ala" + i}
                            style={{"text-decoration": "underline", cursor: "pointer", paddingLeft: "15px"}}
                            onClick={() => history.push("/product-details/" + encodeURIComponent(spec.title.replace("/", "||")))}>
                               {language === "en-UK" ? spec.titleEn : spec.title} <br/>
                           </span>);
                    }
                }
                if (dies.length > 0) {
                    langTecSpecs.push(
                        <List.Item className={"flex-list-item"} key={"loc-link"}>
                            <Image avatar src={LogoIcon} verticalAlign='middle'
                                   alt={""}
                                   style={{"height": "20px", "width": "20px"}}/>
                            <List.Content>
                                <span>{i18n.t('products.fitting_dies')}:  <br/>{dies}</span>
                            </List.Content>
                        </List.Item>
                    )
                }
            }
        }

        return langTecSpecs;
    }

    getLanguageProductType() {
        let typeId = this.state.product.productTypeId;
        switch (typeId) {
            case productTypeId:
                return i18n.t('products.crimping').toUpperCase();
            case productType2Id:
                return i18n.t('products.cutting').toUpperCase();
            case productType3Id:
                return i18n.t('products.wire_end_sleeves').toUpperCase();
            case productType4Id:
                return i18n.t('products.wire_stripper').toUpperCase();
            case productType5Id:
                return i18n.t('products.contract_rail').toUpperCase();
            case productType6Id:
                return i18n.t('products.wiring_ducts').toUpperCase();
            case productType7Id:
                return i18n.t('products.accessories').toUpperCase();
            case productType8Id:
                return i18n.t('products.crimping_dies').toUpperCase();
            case productType9Id:
                return i18n.t('products.locator').toUpperCase();
        }
    }

    getInsulatedImage(colorId) {
        let src = "https://www.glw.de/images/wire_end_sleeves/insulated/Weiss.jpg";

        switch (colorId) {
            case  1:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Braun.jpg";
                break;
            case 2:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Grau.jpg";
                break;
            case 3:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Violett.jpg";
                break;
            case  4:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Gelb.jpg";
                break;
            case 5:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Hellblau.jpg";
                break;
            case 6:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Tuerkis.jpg";
                break;
            case 7:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Pink.jpg";
                break;
            case 8:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Weiss.jpg";
                break;
            case 9:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Orange.jpg";
                break;
            case 10:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Schwarz.jpg";
                break;
            case 11:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Rot.jpg";
                break;
            case 12:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Gruen.jpg";
                break;
            case 13:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Elfenbein.jpg";
                break;
            case 14:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Beige.jpg";
                break;
            case 15:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Olive.jpg";
                break;
            case 16:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Dunkelrot.jpg";
                break;
            case 18:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Hellgelb.jpg";
                break;
            case 19:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Blau.jpg";
                break;
            case 20:
                src = "https://www.glw.de/images/wire_end_sleeves/insulated/Mittelblau.jpg";
                break;
        }

        return src;
    }

    getTWINImage(colorId) {
        let src = "https://www.glw.de/images/wire_end_sleeves/twin/Weiss.jpg";

        switch (colorId) {
            case  1:
                src = "https://www.glw.de/images/wire_end_sleeves/twin/Braun.jpg";
                break;
            case  13:
                src = "https://www.glw.de/images/wire_end_sleeves/twin/Elfenbein.jpg";
                break;
            case  4:
                src = "https://www.glw.de/images/wire_end_sleeves/twin/Gelb.jpg";
                break;
            case 2:
                src = "https://www.glw.de/images/wire_end_sleeves/twin/Grau.jpg";
                break;
            case 12:
                src = "https://www.glw.de/images/wire_end_sleeves/twin/Gruen.jpg";
                break;
            case 9:
                src = "https://www.glw.de/images/wire_end_sleeves/twin/Orange.jpg";
                break;
            case 11:
                src = "https://www.glw.de/images/wire_end_sleeves/twin/Rot.jpg";
                break;
            case 10:
                src = "https://www.glw.de/images/wire_end_sleeves/twin/Schwarz.jpg";
                break;
            case 8:
                src = "https://www.glw.de/images/wire_end_sleeves/twin/Weiss.jpg";
                break;
        }

        return src;
    }

    getBandImage(colorId) {
        let src = "https://www.glw.de/images/wire_end_sleeves/band/Weiss.jpg";
        switch (colorId) {
            case 2:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Grau.jpg";
                break;
            case  4:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Gelb.jpg";
                break;
            case 5:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Hellblau.jpg";
                break;
            case 6:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Tuerkis.jpg";
                break;
            case 8:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Weiss.jpg";
                break;
            case 9:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Orange.jpg";
                break;
            case 10:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Schwarz.jpg";
                break;
            case 11:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Rot.jpg";
                break;
            case 18:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Hellgelb.jpg";
                break;
            case 19:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Blau.jpg";
                break;
            case 20:
                src = "https://www.glw.de/images/wire_end_sleeves/band/Mittelblau.jpg";
                break;
        }

        return src;
    }

    getImageComponent() {
        if (this.state.product.productTypeId === productType3Id) {
            let image = "";
            let crossStyle = {};
            let lengthStyle = {};
            switch (this.state.product.typeId) {
                case 1:
                    //Insuladed
                    image = this.getInsulatedImage(this.state.product.colorId);
                    crossStyle = {position: 'absolute', "left": "67%", "top": "43%"};
                    lengthStyle = {position: 'absolute', "left": "38%", "top": "32%", transform: "rotate(-23deg)"};
                    break;
                case 2:
                    //TWIN
                    image = this.getTWINImage(this.state.product.colorId);
                    crossStyle = {position: 'absolute', "left": "73%", "top": "43%"};
                    lengthStyle = {position: 'absolute', "left": "43%", "top": "30%", transform: "rotate(-23deg)"};
                    break;
                case 3:
                    //Uninsuladed
                    image = "https://www.glw.de/images/wire_end_sleeves/uninsulated/Blanke.jpg";
                    crossStyle = {position: 'absolute', "left": "75%", "top": "43%"};
                    lengthStyle = {position: 'absolute', "left": "37%", "top": "37%", transform: "rotate(-35deg)"};
                    break;
                case 4:
                    //Band
                    image = this.getBandImage(this.state.product.colorId);
                    crossStyle = {position: 'absolute', "left": "63%", "top": "27%"};
                    lengthStyle = {position: 'absolute', "left": "30%", "top": "15%", transform: "rotate(-35deg)"};
                    break;
                default:
                    image = "https://www.glw.de/images/wire_end_sleeves/uninsulated/Blanke.jpg";
                    crossStyle = {display: 'none'};
                    lengthStyle = {display: 'none'};
                    break;
            }
            return <div className="ui fluid bordered image">
                <img src={image}/>
                <div style={{position: 'absolute', bottom: 0, width: '100%', height: '100%'}}
                >
                    <span style={crossStyle}>{this.state.product.crossSection} mm²</span>
                    <span style={lengthStyle}>{this.state.product.length} mm</span>
                </div>
            </div>


        }
        if ((this.state.product.videoLinks.length === 0 && this.state.product.imageLinks.length === 0)) {
            // || (/*@cc_on!@*/false || !!document.documentMode)
            return <ReactImageMagnify  {...{
                smallImage: {
                    isFluidWidth: true,
                    src: this.state.product.link,
                },
                largeImage: {
                    src: this.state.product.link,
                    width: 1200,
                    height: 1200
                },
                enlargedImagePosition: "over"
            }}  />

        } else {
            return <ProductImageGalleryComponent images={this.state.product.imageLinks}
                                                 videos={this.state.product.videoLinks}
                                                 initialPosition={this.galleryPosition}
                                                 history={this.props.history}/>
        }
    }

    getDrawing(typeId) {
        switch (typeId) {
            case 1:
                //Insuladed
                return <Image size="medium" centered
                              alt={""}
                              src={"https://www.glw.de/images/wire_end_sleeves/drawings/isoliert.jpg"}/>;
            case 2:
                //TWIN
                return <Image size="medium" centered
                              alt={""}
                              src={"https://www.glw.de/images/wire_end_sleeves/drawings/twin.jpg"}/>;
            case 3:
                //Uninsuladed
                return <Image size="medium"
                              centered
                              alt={""}
                              src={"https://www.glw.de/images/wire_end_sleeves/drawings/unisoliert.jpg"}/>;
            case 4:
                //Band
                return <Image size="medium"
                              centered
                              alt={""}
                              src={"https://www.glw.de/images/wire_end_sleeves/drawings/band.jpg"}/>;
            default:
                return null;
        }
    }

    getLanguageInfo(language) {
        if (this.state.product.productTypeId === productType3Id) {
            return <Button
                fluid
                style={{marginTop: "1rem"}}
                className={"glw-button"}
                onClick={() => this.createInfoPDF(this.state.product)}>
                {i18n.t('wire_end.info')}
            </Button>
        }
        let languageInfos = this.state.product.productInformations;
        if (languageInfos.length > 0) {
            let showLinks = [];
            for (let i = 0; i < languageInfos.length; i++) {
                showLinks.push(<Accordion.Title
                        active={this.activeIndex === i}
                        index={i}
                        key={"Acc" + i}
                        onClick={() => this.activeIndex === i ? this.activeIndex = -1 : this.activeIndex = i}>
                        <h2>
                            <Icon name='dropdown'/>
                            {language === "de-DE" ? languageInfos[i].name : languageInfos[i].nameEn}
                        </h2>
                    </Accordion.Title>
                );

                let catLinks = languageInfos[i].infos.map((info) =>
                    <CardDownloadsComponent
                        key={"AccD" + i}
                        title={language === "de-DE" ? info.name : (info.nameEn.length > 0 ? info.nameEn : info.name)}
                        link={info.link}
                    />);

                showLinks.push(
                    <Accordion.Content active={this.activeIndex === i} key={"AccCon" + i}>
                        <Grid>
                            {catLinks}
                        </Grid>
                    </Accordion.Content>
                )
            }

            return <Modal
                trigger={<Button
                    fluid
                    style={{marginTop: "1rem"}}
                    className={"glw-button"}>
                    Downloads</Button>}
                style={{"margin": "auto"}}>
                <Modal.Header>  {i18n.t('products.select_info')}</Modal.Header>
                <Modal.Content>
                    <Accordion>
                        {showLinks}
                    </Accordion>
                </Modal.Content>
            </Modal>
        }
    }

    async createInfoPDF(product) {
        let language = "de-DE";
        let {i18n} = this.props;

        if (i18n) {
            language = this.props.i18n.language;
        }

        fetch(config.BASE_URL + 'products/pdf/' + product.id + "/" + language, {
            method: 'GET',
            headers: {
                'Accept': 'application/pdf',
                'Content-Type': 'application/pdf'
            }
        }).then(response => {
            response.blob().then((blob) => {
                let name = i18n.t('wire_end.title') + "_" +
                    (language === "en-UK" ? this.state.product.typeEn : this.state.product.type) + "_" +
                    (language === "en-UK" ? this.state.product.crossSection : this.toGermanFloat(this.state.product.crossSection)) + "_mm²_-_" +
                    (language === "en-UK" ? this.state.product.length : this.toGermanFloat(this.state.product.length)) + "_mm";
                showBlobFile(blob, name);
            });
        });
    }
    getLanguageVersionTables(language) {
        let handVersions = [];
        let tableVersions = [];

        let tables = [];

        if (!this.state.product.hasOwnProperty("versions")) {
            return [];
        }

        for (let i = 0; i < this.state.product.versions.length; i++) {
            if (language === this.state.product.versions[i].language) {
                let version = <Table.Row>
                    <Table.Cell>{this.state.product.versions[i].versionName}</Table.Cell>
                    <Table.Cell>{this.state.product.versions[i].itemNumber}</Table.Cell>
                    <Table.Cell>{this.state.product.versions[i].shape}</Table.Cell>
                    <Table.Cell>{this.state.product.versions[i].crimpArea}</Table.Cell>
                </Table.Row>;


                if (this.state.product.versions[i].hand) {
                    handVersions.push(version)
                } else {
                    tableVersions.push(version)
                }
            }
        }

        if (handVersions.length > 0) {
            tables.push(<h3>{i18n.t('versions.hand_version')}</h3>);
            tables.push(<Table columns={4}>
                <Table.Header>
                    <Table.Row className={"glw-header-table-row"}>
                        <Table.HeaderCell>{i18n.t('versions.version')}</Table.HeaderCell>
                        <Table.HeaderCell>{i18n.t('products.item_number')}</Table.HeaderCell>
                        <Table.HeaderCell>{i18n.t('products.crimp_shape')}</Table.HeaderCell>
                        <Table.HeaderCell>{i18n.t('versions.area')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {handVersions}
                </Table.Body>
            </Table>);
        }

        if (tableVersions.length > 0) {
            tables.push(<h3>{i18n.t('versions.table_version')}</h3>);
            tables.push(<Table columns={4}>
                <Table.Header>
                    <Table.Row className={"glw-header-table-row"}>
                        <Table.HeaderCell>{i18n.t('versions.version')}</Table.HeaderCell>
                        <Table.HeaderCell>{i18n.t('products.item_number')}</Table.HeaderCell>
                        <Table.HeaderCell>{i18n.t('products.crimp_shape')}</Table.HeaderCell>
                        <Table.HeaderCell>{i18n.t('versions.area')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {tableVersions}
                </Table.Body>
            </Table>);
        }

        return tables;
    }

    getDimensionData() {
        let measurements = [
            {
                title: "l1",
                value: this.state.product.l1
            }
        ];
        if (this.state.product.l2 !== null) {
            measurements.push({
                title: "l2",
                value: this.state.product.l2
            })
        }
        measurements.push({
            title: "d1",
            value: this.state.product.d1
        });

        if (this.state.product.s1 !== null) {
            measurements.push({
                title: "s1",
                value: this.state.product.s1
            })
        }
        measurements.push({
            title: "d2",
            value: this.state.product.d2
        });
        if (this.state.product.s2 !== null) {
            measurements.push({
                title: "s2",
                value: this.state.product.s2
            })
        }
        if (this.state.product.d3 !== null) {
            measurements.push({
                title: "d3",
                value: this.state.product.d3
            })
        }
        if (this.state.product.r !== null) {
            measurements.push({
                title: "r",
                value: this.state.product.r
            })
        }

        let measureHeaders = [];
        for (let i = 0; i < measurements.length; i++) {
            measureHeaders.push(<Table.HeaderCell>{measurements[i].title}</Table.HeaderCell>);
        }

        let tableHeader = <Table.Header>
            <Table.Row className={"glw-header-table-row"} textAlign={"center"} style={{"padding": "0"}}>
                <Table.HeaderCell
                    colSpan={measurements.length.toString()}>  {i18n.t('wire_end.measurements')} (mm)</Table.HeaderCell>
            </Table.Row>
            <Table.Row style={{"padding": "0"}}>
                {measureHeaders}
            </Table.Row>
        </Table.Header>;

        let measureCells = [];
        for (let i = 0; i < measurements.length; i++) {
            measureCells.push(<Table.Cell>{measurements[i].value}</Table.Cell>);
        }

        return <Table unstackable style={{"padding": "0"}}>
            {tableHeader}
            <Table.Body>
                {measureCells}
            </Table.Body>
        </Table>
    }

    getPackagingTable() {

        let packaging = [];

        if (this.state.product.singleBag.length !== 0) {
            packaging.push({
                title: this.state.product.vpeSingleBag,
                value: this.state.product.singleBag
            })
        }
        if (this.state.product.miniBag.length !== 0) {
            packaging.push({
                title: this.state.product.vpeMiniBag,
                value: this.state.product.miniBag
            })
        }
        if (this.state.product.itemNumber.length !== 0) {
            packaging.push({
                title: this.state.product.vpeBulk,
                value: this.state.product.itemNumber
            })
        }

        let tableHeader = <Table.Header>
            <Table.Row className={"glw-header-table-row"} style={{"padding": "0"}}>
                <Table.HeaderCell
                    colSpan={'2'}>{i18n.t('wire_end.packaging')}</Table.HeaderCell>
            </Table.Row>
        </Table.Header>;

        let measureCells = [];
        for (let i = 0; i < packaging.length; i++) {
            measureCells.push(
                <Table.Row>
                    <Table.Cell>{packaging[i].title} {i18n.t('wire_end.pieces')}</Table.Cell>
                    <Table.Cell>{i18n.t('wire_end.part_no')}: {packaging[i].value}</Table.Cell>
                </Table.Row>
            );
        }

        return <Table style={{"padding": "0", "marginBottom": "10px"}} unstackable>
            {tableHeader}
            <Table.Body>
                {measureCells}
            </Table.Body>
        </Table>
    }

    getBasketItemNumber(product) {
        let itemNumber = product.itemNumber;
        if (product.miniBag !== undefined) {
            itemNumber = product.itemNumber + ", " + product.miniBag + ", " + product.singleBag;
        }
        return itemNumber;
    }

    getBasketItem(product) {
        return {
            id: product.id,
            name: product.name,
            itemNumber: this.getBasketItemNumber(product)
        }
    }

    toGermanFloat(float) {
        return float.toString().replace(".", ",");
    }

    isInBasket(basket) {
        let itemNumber = this.getBasketItemNumber(this.state.product);
        for (let i = 0; i < basket.length; i++) {
            if (basket[i].itemNumber === itemNumber) {
                return true;
            }
        }
        return false;
    }

    render() {
        let {basketStore} = this.props.stores;

        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        let tableItems = this.getLanguageTecSpecs(language);

        let productSpecs = this.getLanguageProdSpecs(language);

        let productEquipment = [];

        if (this.state.product.hasOwnProperty("productEquipment")) {
            productEquipment = this.state.product.productEquipment.map((spec, index) =>
                <Grid.Column computer={8} tablet={16} mobile={16} key={index} style={{"paddingBottom": "20px"}}
                             onClick={() => {
                                 if (spec.id.startsWith("cat:")) {
                                     history.push("/products/" + spec.id.replace("cat:", "") + "/0")
                                 } else {
                                     history.push("/product-details/" + encodeURIComponent(spec.title.replace("/", "||")))
                                 }
                             }}
                             className={"clicky-thingy"}>
                    <Image src={LogoIcon} verticalAlign='middle'
                           alt={""}
                           style={{"height": "20px", "width": "20px", "marginRight": "15px"}}/>
                    <span style={{"text-decoration": "underline"}}>
                {language === "en-UK" ? spec.titleEn : spec.title}
                </span>
                </Grid.Column>
            );
        }

        let showVersionTables = this.getLanguageVersionTables(language);

        return (
            <Grid itemScope itemType={"http://schema.org/Product"}>
                <Helmet>
                    <title>{this.state.product.productTypeId === productType3Id ?
                        i18n.t('wire_end.title') + " " +
                        (language === "en-UK" ? this.state.product.typeEn : this.state.product.type) + " " +
                        (language === "en-UK" ? this.state.product.crossSection : this.toGermanFloat(this.state.product.crossSection)) + " mm² - " +
                        (language === "en-UK" ? this.state.product.length : this.toGermanFloat(this.state.product.length)) + " mm"
                        :
                        (language === "en-UK" ? this.state.product.nameEn : this.state.product.name)}
                    </title>
                    <meta name="description"
                          content={this.state.product.productTypeId === productType3Id ?
                              this.state.product.name
                              :
                              this.state.product.itemNumber}/>
                    <meta name="robots" content="index,follow"/>

                    <meta property="og:site_name" content="GLW GmbH"/>
                    <meta property="og:title" content={
                        this.state.product.productTypeId === productType3Id ?
                            this.state.product.name
                            :
                            this.state.product.itemNumber
                    }/>
                    <meta property="og:description" content={
                        this.state.product.productTypeId === productType3Id ?
                            i18n.t('wire_end.title') + " " +
                            (language === "en-UK" ? this.state.product.typeEn : this.state.product.type) + " " +
                            (language === "en-UK" ? this.state.product.crossSection : this.toGermanFloat(this.state.product.crossSection)) + " mm² - " +
                            (language === "en-UK" ? this.state.product.length : this.toGermanFloat(this.state.product.length)) + " mm"
                            :
                            (language === "en-UK" ? this.state.product.nameEn : this.state.product.name)}/>
                    <meta property="og:image" itemProp="image" content={this.getImageComponent()}/>
                    <meta property="og:type" content="website"/>
                </Helmet>
                <span itemProp="brand" content="GLW GmbH"/>
                <span itemProp="name"
                      content={this.state.product.productTypeId === productType3Id ?
                          this.state.product.name
                          :
                          this.state.product.itemNumber}/>
                <Grid.Row className={"no-top-padding primary-background-container"}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Responsive as={Breadcrumb} minWidth={787} className={"top-row-text"}>
                            <Breadcrumb.Section
                                onClick={() => history.push("/")}
                                style={{"color": "white"}}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider
                                onClick={() => history.push("/")}>
                                <Icon name={'left chevron'} inverted/>
                            </Breadcrumb.Divider>
                            {this.state.product.productTypeId === productType8Id ?
                                <Breadcrumb.Section
                                    onClick={() => {
                                        history.push("/products/" + productType7Id + "/0")
                                    }}
                                    style={{"color": "white"}}>
                                    {i18n.t('products.accessories').toUpperCase()}
                                </Breadcrumb.Section>
                                : null}
                            {this.state.product.productTypeId === productType8Id ?
                                <Breadcrumb.Divider><Icon name={'left chevron'} inverted/></Breadcrumb.Divider>
                                : null}
                            <Breadcrumb.Section
                                onClick={() => history.push("/products/" + this.state.product.productTypeId + "/0")}
                                style={{"color": "white"}}>
                                {this.getLanguageProductType()}
                            </Breadcrumb.Section>
                            {this.state.product.isHandTool ?
                                [
                                    <Breadcrumb.Divider
                                        onClick={() => history.push("/products/" + this.state.product.productTypeId + "/1")}>
                                        <Icon name={'left chevron'} inverted/>
                                    </Breadcrumb.Divider>,
                                    <Breadcrumb.Section
                                        onClick={() => history.push("/products/" + this.state.product.productTypeId + "/1")}
                                        style={{"color": "white"}}>
                                        {i18n.t('products.hand_tools').toUpperCase()}
                                    </Breadcrumb.Section>
                                ]
                                : null}
                            {this.diesType !== -1 ?
                                [
                                    <Breadcrumb.Divider
                                        onClick={() => history.push("/products/" + productType8Id + "/0?diestype=" + this.diesType)}>
                                        <Icon name={'left chevron'} inverted/>
                                    </Breadcrumb.Divider>,
                                    <Breadcrumb.Section
                                        onClick={() => history.push("/products/" + productType8Id + "/0?diestype=" + this.diesType)}
                                        style={{"color": "white"}}>
                                        {i18n.language === "de-DE" ? diesValues[this.diesType].title.toUpperCase() : diesValuesEn[this.diesType].title.to}
                                    </Breadcrumb.Section>
                                ]
                                : null}
                        </Responsive>
                        <h1 style={{"marginBottom": "0px"}} className={"top-row-text"}>
                            {this.state.product.productTypeId === productType3Id ?
                                i18n.t('wire_end.title') + " " +
                                (language === "en-UK" ? this.state.product.typeEn : this.state.product.type) + " " +
                                (language === "en-UK" ? this.state.product.crossSection : this.toGermanFloat(this.state.product.crossSection)) + " mm² - " +
                                (language === "en-UK" ? this.state.product.length : this.toGermanFloat(this.state.product.length)) + " mm"
                                :
                                (language === "en-UK" ? this.state.product.nameEn : this.state.product.name)
                            }
                        </h1>
                        <p className={"no-top-padding top-row-text"}>
                            {this.state.product.productTypeId === productType3Id ?
                                this.state.product.name
                                :
                                <span>
                                    {this.state.product.subtitle ? this.state.product.subtitle : ""}
                                    <br/>
                                    {this.state.product.itemNumber}
                                </span>
                            }
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Responsive
                    as={Grid.Row} maxWidth={767}
                    className={!this.showSubMenu ? "hide-div" : "no-top-padding sub-menu-container"}
                    style={{"zIndex": "1"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productType3Id + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productType3Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productType3Id ?
                                "https://www.glw.de/images/Produkte_Aderendhuelse_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Aderendhuelse_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.wire_end_sleeves').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productType2Id + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productType2Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productType2Id ?
                                "https://www.glw.de/images/Produkte_Ablaengen_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Ablaengen_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.cutting').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productTypeId + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productTypeId ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productTypeId ?
                                "https://www.glw.de/images/Produkte_Crimpen_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Crimpen_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.crimping').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productType4Id + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productType4Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productType4Id ?
                                "https://www.glw.de/images/Produkte_Abisolieren_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Abisolieren_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.wire_stripper').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productType7Id + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productType7Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productType7Id ?
                                "https://www.glw.de/images/Produkte_Zubehoer_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Zubehoer_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.accessories').toUpperCase()}
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} maxWidth={767} centered className={"no-top-padding sub-menu-container"}>
                    <Grid.Column width={3} style={{"padding": "15px"}}
                                 textAlign={"center"}>
                        <Icon name={this.showSubMenu ? "angle double up" : "angle double down"}
                              size={"big"}
                              onClick={() => this.showSubMenu = !this.showSubMenu}/>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} minWidth={768}
                            className={"no-top-padding sub-menu-container"}
                            style={{"zIndex": "1"}}>
                    <Grid.Column width={2} only={"computer"}/>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productType3Id + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productType3Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productType3Id ?
                                "https://www.glw.de/images/Produkte_Aderendhuelse_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Aderendhuelse_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.wire_end_sleeves').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productType2Id + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productType2Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productType2Id ?
                                "https://www.glw.de/images/Produkte_Ablaengen_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Ablaengen_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.cutting').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productTypeId + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productTypeId ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productTypeId ?
                                "https://www.glw.de/images/Produkte_Crimpen_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Crimpen_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.crimping').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productType4Id + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productType4Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productType4Id ?
                                "https://www.glw.de/images/Produkte_Abisolieren_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Abisolieren_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.wire_stripper').toUpperCase()}
                    </Grid.Column>
                    <Grid.Column
                        computer={2} tablet={3} mobile={4}
                        onClick={() => history.push("/products/" + productType7Id + "/0")}
                        textAlign={"center"}
                        className={this.state.product.productTypeId === productType7Id ? "active-sub-menu-item" : "sub-menu-item"}>
                        <Image
                            className={"sub-menu-item-image"}
                            src={this.state.product.productTypeId === productType7Id ?
                                "https://www.glw.de/images/Produkte_Zubehoer_aktiv.png"
                                :
                                "https://www.glw.de/images/Produkte_Zubehoer_passiv.png"}
                            alt={""}
                        />
                        {i18n.t('products.accessories').toUpperCase()}
                    </Grid.Column>
                </Responsive>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} className={"products-container"}>
                        <Grid padded>
                            <Grid.Row centered style={{"paddingTop": "50px"}}>
                                <Grid.Column computer={6} tablet={16} mobile={16} style={{"paddingBottom": "10px"}}>
                                    {this.getImageComponent()}
                                </Grid.Column>
                                <Grid.Column computer={6} tablet={16} mobile={16}>
                                    {this.state.product.productDescription ?
                                        <div>
                                            <h2 class="technical-specs-header">{i18n.t("products.product_description")}:</h2>
                                            <p style={{padding: "1rem"}}>{language === "de-DE" ? this.state.product.productDescription : this.state.product.productDescriptionEn}</p>
                                        </div> : null}
                                    {productSpecs.length > 0 ?
                                        <List>
                                            {productSpecs}
                                        </List>
                                        :
                                        null
                                    }
                                    {tableItems.length > 0 ?
                                        <Grid.Row>
                                            <Grid.Column computer={8} tablet={16} mobile={16}>
                                                <div className={"technical-specs"}>
                                                    <h2 className={"technical-specs-header"}> {i18n.t('titles.tec_data')}:</h2>
                                                    <Grid className={"technical-specs-grid"} divided='vertically'>
                                                        {tableItems}
                                                    </Grid>
                                                </div>

                                            </Grid.Column>
                                        </Grid.Row>
                                        :
                                        null}
                                    {this.state.product.productTypeId === productType3Id ?
                                        <Grid.Row>
                                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                                {this.getPackagingTable()}
                                            </Grid.Column>
                                        </Grid.Row>
                                        : null
                                    }
                                    {this.state.product.itemNumber === "004577" ?
                                        <Grid.Row verticalAlign={"bottom"}>
                                            <Grid.Column computer={4} tablet={4} mobile={8}>
                                                <Image
                                                    style={{width: "50%"}}
                                                    src={"https://www.glw.de/images/ec_I0560.jpg"}/>
                                                <p>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: i18n.t("products.ec_I0560")}}/>
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        : null}
                                    {this.state.product.itemNumber === "005016" ?
                                        <Grid.Row>
                                            <Grid.Column computer={4} tablet={4} mobile={8}>
                                                <Image
                                                    style={{width: "50%"}}
                                                    src={"https://www.glw.de/images/ec_I0560AS.png"}/>
                                                <p>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: i18n.t("products.ec_I0560AS")}}/>
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        : null}
                                    {this.state.product.itemNumber === "004997" ?
                                        <Grid.Row>
                                            <Grid.Column computer={4} tablet={4} mobile={8}>
                                                <Image
                                                    style={{width: "50%"}}
                                                    src={"https://www.glw.de/images/ec_I0560.jpg"}/>
                                                <p>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: i18n.t("products.ec_I0115")}}/>
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        : null}
                                </Grid.Column>
                                <Grid.Column width={3} only={"computer"}>
                                    {this.state.product.productTypeId === productType3Id && this.state.product.cert ?
                                        <Grid style={{"paddingTop": "10px"}}>
                                            <Grid.Row columns={2}>
                                                <Grid.Column width={6}/>
                                                <Grid.Column width={5}
                                                             style={{"paddingLeft": "5px"}}>
                                                    <Image
                                                        fluid
                                                        className={"clicky-thingy"}
                                                        onClick={() => window.open("https://www.glw.de/UL_Certificate_E485383.pdf")}
                                                        src={"https://www.glw.de/images/Mark_E504606_EN.png"}
                                                        alt={""}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        :
                                        null
                                    }
                                    {this.getLanguageInfo(language)}
                                    {this.state.product.id === "a8877133-433e-4eb6-95f4-6d931d344066" || this.state.product.id === "5694042a-357a-4aae-9bd2-5e30cd0b1595" ?
                                        <div style={{marginTop: "40%"}}>
                                            <p
                                                style={{marginBottom: "0px"}}>
                                                <b>{i18n.t("products.fitting_locator")}</b>
                                            </p>
                                            <Grid>
                                                <Grid.Row
                                                    style={{paddingTop: "0px"}}>
                                                    <Grid.Column
                                                        floated={"left"}
                                                        width={8}
                                                        className={"show-product"}
                                                        onClick={() => {
                                                            history.push("/products/d209fd49-0402-4b9e-bcc1-c7622b04b1a5/0?diestype=0");
                                                            this.setState({})
                                                        }}>
                                                        <div className="ui fluid image">
                                                            <img src={"https://www.glw.de/images/Start_Gesenke.png"}/>
                                                            <div style={{
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                width: '100%',
                                                                height: '100%'
                                                            }}
                                                                 className={"image-overlay"}>
                                                                <p> {i18n.t("products.dies")}</p>
                                                            </div>
                                                        </div>
                                                    </Grid.Column>
                                                    <Grid.Column
                                                        floated={"left"}
                                                        width={8}
                                                        className={"show-product"}
                                                        onClick={() => {
                                                            history.push("/products/571364ab-792a-4926-b206-b38944e281dd/0")
                                                        }}>
                                                        <div className="ui fluid image">
                                                            <img src={"https://www.glw.de/images/Locator.png"}/>
                                                            <div style={{
                                                                position: 'absolute',
                                                                bottom: 0,
                                                                width: '100%',
                                                                height: '100%'
                                                            }}
                                                                 className={"image-overlay"}>
                                                                <p>
                                                                    {i18n.t("products.locator")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                                        : null}

                                    <div style={{
                                        "position": "absolute",
                                        "width": "100%",
                                        "padding-left": "1rem",
                                        "padding-right": "1rem",
                                        "margin-left": "-1rem",
                                        "bottom": "0"
                                    }}>
                                        {this.state.product.productTypeId === productType3Id ?
                                            <Image
                                                size={"tiny"}
                                                floated={"right"}
                                                src={"https://www.glw.de/images/Made_in_Germany_Huelse.jpg"}
                                                verticalAlign={"bottom"}
                                                alt={""}
                                            />
                                            :
                                            null
                                        }
                                        {this.state.product.madeIn === 1 ?
                                            <Image
                                                size={"tiny"}
                                                floated={"right"}
                                                src={"https://www.glw.de/images/Made_in_Germany_Zange.jpg"}
                                                verticalAlign={"bottom"}
                                                alt={""}
                                            />
                                            :
                                            null
                                        }
                                        {this.state.product.madeIn === 2 ?
                                            <Image
                                                size={"tiny"}
                                                floated={"right"}
                                                src={"https://www.glw.de/images/Made_in_Sweden_Zange.jpg"}
                                                verticalAlign={"bottom"}
                                                alt={""}
                                            />
                                            :
                                            null
                                        }
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={16} only={"tablet"}>
                                    {this.state.product.id === "a8877133-433e-4eb6-95f4-6d931d344066" || this.state.product.id === "5694042a-357a-4aae-9bd2-5e30cd0b1595" ?
                                        [<Button
                                            onClick={() => history.push("/products/d209fd49-0402-4b9e-bcc1-c7622b04b1a5/0?diestype=0")}
                                            className={"glw-button"}>
                                            {i18n.t("products.crimping")}
                                        </Button>,
                                            <Button
                                                onClick={() => history.push("/products/571364ab-792a-4926-b206-b38944e281dd/0")}
                                                className={"glw-button"}>
                                                {i18n.t("products.locator")}
                                            </Button>
                                        ]
                                        : null}

                                    {this.state.product.productTypeId === productType3Id ?
                                        <Image
                                            size={"tiny"}
                                            floated={"right"}
                                            src={"https://www.glw.de/images/Made_in_Germany_Huelse.jpg"}
                                            alt={""}
                                        />
                                        :
                                        null
                                    }
                                    {this.state.product.productTypeId === productType3Id && this.state.product.cert ?
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={2}>
                                                    <Image
                                                        fluid
                                                        className={"clicky-thingy"}
                                                        onClick={() => window.open("https://iq2.ulprospector.com/session/new?redirect=http%3a%2f%2fiq.ulprospector.com%2fen%2fsearch%2flegacy%3furl%3dshowpage.html%25253Fname%25253DZMLF.E485383%252526ccnshorttitle%25253DBare%2band%2bCovered%2bFerrules%252526objid%25253D1086155863%252526cfgid%25253D1073741824%252526version%25253Dversionless%252526parent_id%25253D1084141507%252526sequence%25253D1")}
                                                        src={"https://www.glw.de/images/Mark_E504606_EN.png"}
                                                        alt={""}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        :
                                        null
                                    }
                                </Grid.Column>
                                <Grid.Column width={16} only={"mobile"}>
                                    {this.state.product.id === "a8877133-433e-4eb6-95f4-6d931d344066" || this.state.product.id === "5694042a-357a-4aae-9bd2-5e30cd0b1595" ?
                                        [
                                            <Button
                                                onClick={() => history.push("/products/d209fd49-0402-4b9e-bcc1-c7622b04b1a5/0?diestype=0")}
                                                className={"glw-button"}>
                                                {i18n.t("products.crimping")}
                                            </Button>,
                                            <Button
                                                onClick={() => history.push("/products/571364ab-792a-4926-b206-b38944e281dd/0")}
                                                className={"glw-button"}>
                                                {i18n.t("products.locator")}
                                            </Button>
                                        ]
                                        : null}
                                    {this.state.product.productTypeId === productType3Id ?
                                        <Image
                                            size={"small"}
                                            floated={"right"}
                                            src={"https://www.glw.de/images/Made_in_Germany_Huelse.jpg"}
                                            alt={""}
                                        />
                                        :
                                        null
                                    }
                                    {this.state.product.productTypeId === productType3Id && this.state.product.cert ?
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={4}>
                                                    <Image
                                                        fluid
                                                        className={"clicky-thingy"}
                                                        onClick={() => window.open("https://iq2.ulprospector.com/session/new?redirect=http%3a%2f%2fiq.ulprospector.com%2fen%2fsearch%2flegacy%3furl%3dshowpage.html%25253Fname%25253DZMLF.E485383%252526ccnshorttitle%25253DBare%2band%2bCovered%2bFerrules%252526objid%25253D1086155863%252526cfgid%25253D1073741824%252526version%25253Dversionless%252526parent_id%25253D1084141507%252526sequence%25253D1")}
                                                        src={"https://www.glw.de/images/Mark_E504606_EN.png"}
                                                        alt={""}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        :
                                        null
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            {this.state.product.productTypeId === productType3Id ?
                                <Divider/> : null
                            }
                            {this.state.product.productTypeId === productType3Id ?
                                <Grid.Row>
                                    <Grid.Column computer={8} tablet={8} mobile={16} verticalAlign={"middle"}>
                                        <Grid padded>
                                            {this.getDimensionData()}
                                        </Grid>
                                    </Grid.Column>
                                    <Grid.Column computer={8} tablet={8} mobile={16}>
                                        {this.getDrawing(this.state.product.typeId)}
                                    </Grid.Column>
                                </Grid.Row>
                                : null
                            }
                            {this.state.product.hasOwnProperty("versions") && this.state.product.versions.length > 0 ?
                                <Divider/> : null
                            } {this.state.product.hasOwnProperty("versions") && this.state.product.versions.length > 0 ?
                            <Grid.Row>
                                <Grid.Column>
                                    {showVersionTables}
                                </Grid.Column>
                            </Grid.Row>
                            : null
                        }
                            <Divider/>
                            <Grid.Row centered style={{"marginBottom": "30px"}}>
                                <Grid.Column computer={12} tablet={16} mobile={16}>
                                    <div className={"technical-specs"}>
                                        {productEquipment.length > 0 ?
                                            <h2 className={"technical-specs-header"}>{i18n.t('products.suitable')}</h2>
                                            :
                                            null
                                        }
                                        <Grid>
                                            <Grid.Row>
                                                {productEquipment}
                                                {this.state.product.id === "a8877133-433e-4eb6-95f4-6d931d344066" || this.state.product.id === "5694042a-357a-4aae-9bd2-5e30cd0b1595" ?
                                                    [
                                                        <Grid.Column computer={8} tablet={16} mobile={16}
                                                                     style={{"paddingBottom": "20px"}}
                                                                     onClick={() => {
                                                                         history.push("/products/d209fd49-0402-4b9e-bcc1-c7622b04b1a5/0?diestype=0");
                                                                     }}
                                                                     className={"clicky-thingy"}>
                                                            <Image src={LogoIcon} verticalAlign='middle'
                                                                   alt={""}
                                                                   style={{
                                                                       "height": "20px",
                                                                       "width": "20px",
                                                                       "marginRight": "15px"
                                                                   }}/>
                                                            <span style={{"text-decoration": "underline"}}>
                                                        {i18n.t("products.dies")}
                                                    </span>
                                                        </Grid.Column>,
                                                        <Grid.Column computer={8} tablet={16} mobile={16}
                                                                     style={{"paddingBottom": "20px"}}
                                                                     onClick={() => {
                                                                         history.push("/products/571364ab-792a-4926-b206-b38944e281dd/0")
                                                                     }}
                                                                     className={"clicky-thingy"}>
                                                            <Image src={LogoIcon} verticalAlign='middle'
                                                                   alt={""}
                                                                   style={{
                                                                       "height": "20px",
                                                                       "width": "20px",
                                                                       "marginRight": "15px"
                                                                   }}/>
                                                            <span style={{"text-decoration": "underline"}}>
                                                        {i18n.t("products.locator")}
                                                    </span>
                                                        </Grid.Column>] : null}
                                            </Grid.Row>
                                        </Grid>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={3} only={"computer"}
                                             style={{"padding": "0"}}>
                                    <Button
                                        fluid
                                        className={"glw-button"}
                                        onClick={() => history.push("/contact")}>
                                        {i18n.t('titles.contact_lower')}
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={16} only={"tablet"}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={5} floated={"right"}
                                                         style={{"marginBottom": "10px"}}>
                                                {this.getLanguageInfo(language)}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={16} only={"tablet"}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={5} floated={"right"}>
                                                <Button
                                                    fluid
                                                    className={"glw-button"}
                                                    onClick={() => history.push("/contact")}>
                                                    {i18n.t('titles.contact_lower')}
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={16} only={"mobile"}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={10} floated={"right"}
                                                         style={{"marginBottom": "10px"}}>
                                                {this.getLanguageInfo(language)}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={16} only={"mobile"}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={10} floated={"right"}>
                                                <Button
                                                    fluid
                                                    className={"glw-button"}
                                                    onClick={() => history.push("/contact")}>
                                                    {i18n.t('titles.contact_lower')}
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
