import {withTranslation} from "react-i18next";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {isAdmin, removeTokenFromStorage} from "../helper/util";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import React, {useState} from "react";
import i18n from "../i18n";


function isAdminOrSalesPartner() {
    return isAdmin() || (userStore.userFromServer != null && userStore.userFromServer.hasOwnProperty("roles") && userStore.userFromServer.roles.includes("SalesPartner"))
}


const LogoutCol = () => {
    return (
        <Grid.Column
            computer={2} tablet={3} mobile={4}
            onClick={() => {
                removeTokenFromStorage();
                userStore.user = null;
                history.push("/");
            }}
            floated={isAdminOrSalesPartner() ?
                "" : "right"}
            textAlign={"center"}
            className={"sub-menu-item"}>
            <Image
                className={"sub-menu-item-image"}
                src={"https://www.glw.de/images/Logout.png"}
                alt={""}
            />
            {i18n.t('titles.logout').toUpperCase()}
        </Grid.Column>
    )
}


const NavigationCol = () => {

    return isAdminOrSalesPartner() ? (
        [
            <Grid.Column
                computer={2} tablet={3} mobile={4}
                onClick={() => history.push("/sales-partner-downloads")}
                textAlign={"center"}
                className={"sub-menu-item"}>
                <Image
                    className={"sub-menu-item-image"}
                    src={"https://www.glw.de/images/Produkte_Download_passiv.png"}
                    alt={""}
                />
                {i18n.t('titles.sales-download').toUpperCase()}
            </Grid.Column>,
            <Grid.Column
                computer={2} tablet={3} mobile={4}
                onClick={() => history.push("/delivery_times")}
                textAlign={"center"}
                className={"sub-menu-item"}>
                <Image
                    className={"sub-menu-item-image"}
                    src={"https://www.glw.de/images/Kunden_passiv.png"}
                    alt={""}
                />
                {i18n.t('titles.delivery_times').toUpperCase()}
            </Grid.Column>,
            <Grid.Column
                computer={2} tablet={3} mobile={4}
                onClick={() => history.push("/service-overview")}
                textAlign={"center"}
                className={"sub-menu-item"}>
                <Image
                    className={"sub-menu-item-image"}
                    src={"https://www.glw.de/images/Produkte_Zubehoer_passiv.png"}
                    alt={""}
                />
                {i18n.t('titles.service_overview').toUpperCase()}
            </Grid.Column>,
            <Grid.Column
                computer={2} tablet={3} mobile={4}
                onClick={() => history.push("/machine-market")}
                textAlign={"center"}
                className={"sub-menu-item"}>
                <Image
                    className={"sub-menu-item-image"}
                    src={"https://www.glw.de/images/Fairness_passiv.png"}
                    alt={""}
                />
                {i18n.t('titles.machine_market').toUpperCase()}
            </Grid.Column>,
        ]
    ) : null
}

const MyGLWNavigationBar = () => {

    const [showSubMenu, setShowSubMenu] = useState(false);


    return (
        [
            <Responsive as={Grid.Row} maxWidth={767}
                        className={!showSubMenu ? "hide-div" : "no-top-padding sub-menu-container"}
                        style={{"zIndex": "1"}}>
                <NavigationCol/>
                <Grid.Column
                    computer={2} tablet={3} mobile={4}
                    onClick={() => history.push("/my-profile")}
                    textAlign={"center"}
                    className={"sub-menu-item"}>
                    <Image
                        className={"sub-menu-item-image"}
                        src={"https://www.glw.de/images/Familienbetrieb_passiv.png"}
                        alt={""}
                    />
                    {i18n.t('titles.my_profile').toUpperCase()}
                </Grid.Column>
                <LogoutCol/>

            </Responsive>,
            <Responsive as={Grid.Row} maxWidth={767} centered className={"no-top-padding sub-menu-container"}>
                <Grid.Column width={3} style={{"padding": "15px"}}
                             textAlign={"center"}>
                    <Icon name={showSubMenu ? "angle double up" : "angle double down"}
                          size={"big"}
                          onClick={() => setShowSubMenu(!showSubMenu)}/>
                </Grid.Column>
            </Responsive>,
            <Responsive as={Grid.Row} minWidth={768}
                        className={"no-top-padding sub-menu-container"}
                        style={{"zIndex": "1"}}>
                <Grid.Column
                    width={2} only={"computer"}/>
                <NavigationCol/>
                <Grid.Column
                    computer={2} tablet={3} mobile={4}
                    onClick={() => history.push("/my-profile")}
                    textAlign={"center"}
                    className={"sub-menu-item"}>
                    <Image
                        className={"sub-menu-item-image"}
                        src={"https://www.glw.de/images/Familienbetrieb_passiv.png"}
                        alt={""}
                    />
                    {i18n.t('titles.my_profile').toUpperCase()}
                </Grid.Column>
                <LogoutCol/>
                <Grid.Column
                    width={2} only={"computer"}/>
            </Responsive>
        ]
    )
}

export default withTranslation()(MyGLWNavigationBar)