import {observer} from "mobx-react";
import React from "react";
import i18n from "../i18n";
import {withTranslation} from "react-i18next";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

@withTranslation()
@observer
export default class ContactPersonSubPage extends React.Component {

    render() {

        const titleRowStyle = {"paddingBottom": "0px"};
        const imageStyle = {"paddingBottom": "15px"};

        return (
            <Grid>
                <Grid.Row style={titleRowStyle}>
                    <Grid.Column width={16}>
                        <h3>{i18n.t('contactperson_subpage.management').toUpperCase()}</h3>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>

                    <Grid.Column computer={4} tablet={8} mobile={8}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Geier_Florian.jpg"}
                            alt={"Florian Geier"}
                        />
                        <label>{"Florian Geier"}</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={8}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Weiland_Leonie.jpg"}
                            alt={"Leonie Schelling"}/>
                        <label>{"Leonie Schelling"}</label>
                    </Grid.Column>

                    <Grid.Column computer={4} tablet={8} mobile={8}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Weiland_Felix.jpg"}
                            alt={"Felix Weinland"}/>
                        <label>{"Felix Weiland"}</label>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={titleRowStyle}>
                    <Grid.Column computer={4} tablet={8} mobile={8}>
                        <h3>{i18n.t('contactperson_subpage.sales_department').toUpperCase()}</h3>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={4} tablet={8} mobile={8} style={imageStyle}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Lellinger_Raphael.jpg"}
                            alt={"Raphael Lellinger"}
                        />
                        <label>{"Raphael Lellinger"}</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={8} style={imageStyle}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Schmid_Michaela.jpg"}
                            alt={"Michaela Schmid"}
                        />
                        <label>{"Michaela Schmid"}</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={8} style={imageStyle}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Nothhelfer_Petra.jpg"}
                            alt={"Petra Nothhelfer"}
                        />
                        <label>{"Petra Nothhelfer"}</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={8} style={imageStyle}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Jessica_Vogler.jpg"}
                            alt={"Jessica Vogler"}
                        />
                        <label>{"Jessica Vogler"}</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={8} style={imageStyle}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Betty_Tacacs.jpg"}
                            alt={"Betty Tacacs"}
                        />
                        <label>{"Betty Tacacs"}</label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={titleRowStyle}>
                    <Grid.Column computer={4} tablet={8} mobile={8}>
                        <h3>{i18n.t('contactperson_subpage.service').toUpperCase()}</h3>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={4} tablet={8} mobile={8} style={imageStyle}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Kiechle_Alexander.jpg"}
                            alt={"Alexander Kiechle"}
                        />
                        <label>{"Alexander Kiechle"}</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={8} style={imageStyle}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Wagner_Dennis.jpg"}
                            alt={"Dennis Wagner"}
                        />
                        <label>{"Dennis Wagner"}</label>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={8} style={imageStyle}>
                        <Image
                            fluid
                            src={"https://www.glw.de/images/company/Marco_Welte.jpg"}
                            alt={"Marco Welte"}
                        />
                        <label>{"Marco Welte"}</label>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}